
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_PROVINCE_ALL,
    GET_DISTRICT_WITH_PROVINCE,
    GET_WARDS_WITH_DISTRICT,
    INSERT_UPDATE_PROVINCE,
    DELETE_PROVINCE,
    INSERT_UPDATE_DISTRICT,
    DELETE_DISTRICT,

    INSERT_UPDATE_WARDS,
    DELETE_WARDS
   } from '../../config/constainUrl';
import { 
    GetDistrictWithProvinceRequest,
    GetprovinceAllSuccess,
    GetDistrictWithProvinceSuccess,
     GetByIdGroupSalaryRankRequest,
     GetWardsWithDistrictSuccess,
     GetprovinceAllRequest,
     GetWardsWithDistrictRequest} from '../../Action/category';
import { MESSAGE_SUCCESS_DELETE } from '../../config/constaintNotify';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';

function* GetProvince(action){
    try{ 

        const response = yield call(apiCall,"GET",`${GET_PROVINCE_ALL}?name=${action.payload}`); 
        if(response.code===200)
             yield put(GetprovinceAllSuccess(response));  
    }
    catch(e){
      
    }
}

function* GetDistrictWithProvince(action){
    
    try{ 
        
        const response = yield call(apiCall,"GET",`${GET_DISTRICT_WITH_PROVINCE}?provinceId=${action.payload.id}&name=${action.payload.name}`); 
        if(response.code===200)
        {
            yield put(GetDistrictWithProvinceSuccess(response));  
            if(response.data.length === 0){
             
                yield put(GetDistrictWithProvinceSuccess (response))
            }
        }
           
    }   
    catch(e){
      
    }
}

function* InsertOfUpdateProvince(action){
    try{ 
        const response = yield call(apiCall,"POST",INSERT_UPDATE_PROVINCE,action.payload); 
        if(response.code===200)
        {
            toast.success(response.message, { theme: "colored" })
            yield put(GetprovinceAllRequest());  
        }
        else  toast.error(response.message, { theme: "red" })    
    }
    catch(e){
      
    }
}

function* DeleteProvince(action){
    
    try{ 
        const response = yield call(apiCall,"DELETE",`${DELETE_PROVINCE}/${action.payload}`); 
        if(response.code===200){
            toast.success(MESSAGE_SUCCESS_DELETE, { theme: "colored" })
            yield put(GetprovinceAllRequest());  
        }
        else  toast.error(response.message, { theme: "red" })   
    }
    catch(e){
     
    }
}

function* GetWardsWithDistrct(action){
    
    try{ 
        const response = yield call(apiCall,"GET",`${GET_WARDS_WITH_DISTRICT}?districtId=${action.payload.districtId}&name=${action.payload.name}`); 
        if(response.code===200)
             yield put(GetWardsWithDistrictSuccess (response));  
    }
    catch(e){
      
    }
}

function* InsertOfUpdateDistrict(action){
    try{ 
        const response = yield call(apiCall,"POST",INSERT_UPDATE_DISTRICT,action.payload); 
        if(response.code===200)
        {
            
            toast.success(response.message, { theme: "colored" })
            yield put(GetDistrictWithProvinceRequest(  {id: action.payload.TinhId,name: null } ));  
        }
        else  toast.error(response.message, { theme: "red" }) 
    }
    catch(e){
    
    }
}

function* DeleteDistrict(action){
    try{ 
        
        const response = yield call(apiCall,"DELETE",`${DELETE_DISTRICT}/${action.payload.Id}`); 
        if(response.code===200){ 
            toast.success(response.message, { theme: "colored" })
            yield put(GetDistrictWithProvinceRequest({id: action.payload.TinhId, name:null}));  
        }
        else  toast.error(response.message, { theme: "red" })
    }
    catch(e){
      
    }
}


function* InsertOfUpdateWards(action){
    try{ 
        
        const response = yield call(apiCall,"POST",INSERT_UPDATE_WARDS,action.payload); 
        if(response.code===200)
        {
            toast.success(response.message, { theme: "colored" })
            yield put(GetWardsWithDistrictRequest( {districtId:action.payload.HuyenId, name:null }));  
        }
        else  toast.error(response.message, { theme: "red" })    
    }
    catch(e){
      
    }
}

function* DeleteWards(action){
    
    try{ 
        const response = yield call(apiCall,"DELETE",`${DELETE_WARDS}/${action.payload.Id}`); 
        if(response.code===200){

            toast.success(response.message, { theme: "colored" })
            yield put(GetWardsWithDistrictRequest( {districtId:action.payload.HuyenId, name:null }  ));  
        }
        else  toast.error(response.message, { theme: "red" })
           
    }
    catch(e){
   
    }
}

export default function* Administrativeunit(){
    yield takeEvery(Type.GET_PROVINCE_All_REQUEST, GetProvince);
    yield takeEvery(Type.GET_DISTRICT_WITH_PROVINCE_REQUEST, GetDistrictWithProvince);
    yield takeEvery(Type.GET_WARDS_WITH_DISTRICT_REQUEST, GetWardsWithDistrct);
    yield takeEvery(Type.INSERT_UPDATE_PROVINCE, InsertOfUpdateProvince);
    yield takeEvery(Type.DELETE_PROVINCE, DeleteProvince);
    yield takeEvery(Type.INSERT_UPDATE_DISTRICT, InsertOfUpdateDistrict);
    yield takeEvery(Type.DELETE_DISTRICT, DeleteDistrict);
    yield takeEvery(Type.INSERT_UPDATE_WARDS, InsertOfUpdateWards);
    yield takeEvery(Type.DELETE_WARDS, DeleteWards);
}