import * as Types from "../../config/constaints";

const initialState = {
  personalAchievements: [],
  groupAchievements: [],
  isError: false,
  messageError: null
};

export default function Achievement (state=initialState, action) {
  switch(action.type) {    
    case Types.GET_ACHIEVEMENT_BY_ORG_SUCCESS:
      if (action.payload.code === 200) {
        return {
          ...state, 
          personalAchievements: action.payload.data.personalAchievements,
          groupAchievements: action.payload.data.groupAchievements
        };
      }
      break;
    default: return {...state}
  }
}