
import * as Types from "../../config/constaints";

export const initialState = {
  isShowPrint: false,
  isShowCancel: false,
  isShowSave: false,
  isShowTransfer: false,
  isShowRollback: false,
  isShowApprove: false,
  isShowCompleted: false,
  isShowFinish: false,
  isShowSupport: false,
  isShow: false,
  isShowAddIndependence: false,
  isShowAddSeries: false,
  isCreateApplicationChildren: false,
  isShowUpdateProfile: false,
  isDelete: false,
  isStageSelf: false,
  isShowCancelRequest: false
};

const Buttons = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_ALL_BUTTONS:
      return {
        ...state,
        ...action.payload
      }
    case Types.SET_IS_SHOW_CREATE_APPLICATION_CHILDREN:
      return {
        ...state,
        isShowCreateApplicationChildren: action.payload
      }
    case Types.SET_IS_SHOW_APPROVE_ON_CHANGE_STAGE:
      return {
        ...state,
        isShowApprove: action.payload
      }
    default:
      break;
  }
  return state
}
export default Buttons
