
import * as Types from "../../config/constaints";


const initialState = {
  data: [],
  total: 0,
  isError: false,
  messageError: null
  };

  export default function AppDMS (state =initialState,action ){
    switch (action.type) { 
        case Types.GET_APPLICATION_DMS_SUCCESS: { 
            return {...state, data: action.payload.data.datas, total:action.payload.data.total }
      }
        default:
          break;
    }
    return state;
  }

