import * as Types from '../config/constaints'


export const insertAssignment = (data) => {
    return {
        type: Types.INSERT_ASSIGNMENT,
        payload: data
    }
}

export const GetInboxAssignmentRequest = (data) => {
    return {
        type: Types.GET_INBOX_ASSIGN_REQUEST,
        payload: data
    }
}

export const GetInboxAssignmentSuccess = (data) => {
    return {
        type: Types.GET_INBOX_ASSIGN_SUCCESS,
        payload: data
    }
}


export const InboxIsFollowAssignSuccess = (data) => {
    return {
        type: Types.INBOX_ASSIGN_ISFOLLOW_SUCCESS,
        payload: data
    }
}

