
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import { GET_PRODUCT,INSERT_OR_UPDATE_USER_CC_DEFAULT ,GET_USER_CC_DEFAULT} from '../../config/constainUrl';
import { MESSAGE_ERROR ,MESSAGE_SUCCESS_UPDATE}  from '../../config/constaintNotify';
import { GetProductSuccess, GetUserCCdefaultSuccess } from "../../Action/userCcDefault";
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';
function* getproduct(action) {
    try {
        const response = yield call(apiCall, "GET",GET_PRODUCT); 
        if (response.code === 200) {
            yield put(GetProductSuccess(response));  
        }
    }
    catch (e) {
        toast.error(e, { theme: "red" });
    }
}
function* insertOrUpdate(action) {
    
    try {
        const response = yield call(apiCall, "POST",INSERT_OR_UPDATE_USER_CC_DEFAULT,action.payload); 
        if (response.code === 200)  toast.success(MESSAGE_SUCCESS_UPDATE, { theme: "colored" }) 
        else toast.error(MESSAGE_ERROR, { theme: "red" });

    }
    catch (e) {
        toast.error(e, { theme: "red" });
    }
}

function* getUserCcDefault(action) {
    
    try {
        const response = yield call(apiCall, "GET",`${GET_USER_CC_DEFAULT}/${action.payload}`); 
        if (response.code === 200)  yield  put(GetUserCCdefaultSuccess(response));
        else toast.error(MESSAGE_ERROR, { theme: "red" });
    }
    catch (e) {
        toast.error(e, { theme: "red" });
    }
}


export default function* UserCcDefault() {
    yield takeEvery(Type.GET_PRODUCT_REQUEST, getproduct);
    yield takeEvery(Type.INSERT_UPDATE_USERCCDEFAULT, insertOrUpdate);
    yield takeEvery(Type.GET_USERCC_DEFAULT_REQUEST, getUserCcDefault);
    
    
}