import React, { Fragment, useEffect } from "react";
import Logo from "../../Layout/Img/logo.PNG";
import { Col, Button, Form } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { userReSetPassWordWithUserRequest } from '../../Action/user';
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthenticationService } from '../../Service/Account/authenticationService'
import { SETTING } from "../../config/constaints"
import  bgLogin from "../../assets/utils/images/originals/bg-login3.png"
import { toast,ToastContainer } from 'react-toastify';
import { getValueCrypt } from "../../utils/crypt";

export default function ChangePassword() {

  const auth = JSON.parse(getValueCrypt("USER_INFO"));
  const { register,watch, handleSubmit,setValue,getValues , formState: { errors } } = useForm( { defaultValues: {
      userName:auth ? auth.username : '',
      passwordOld:'',
      passwordNew:'',
      passwordConfirm:''
    }
  });
const dispatch = useDispatch();
const onSubmit = (data) => {
  if (data.passwordOld === '123') {
    data.passwordOld = 'P@ssword9'
  }
  if(data.userName ===''){
    toast.error("Vui lòng nhập tài khoản", { theme: "red" });
    return;
  }
  if(data.passwordNew!==data.passwordConfirm){
    toast.error("Password mới không trùng khớp với Confirm Password , Anh chị vui lòng kiểm tra lại", { theme: "red" });
    return;
  }
  if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/).test(data.passwordNew)){
    toast.error("Ít nhất một chữ cái viết hoa, Ít nhất một chữ thường, Ít nhất một chữ số, Ít nhất một biểu tượng đặc biệt nên có nhiều hơn 4 ký tự", { theme: "red" });
    return;
 }
 dispatch(userReSetPassWordWithUserRequest(data));
};

  return (
    <Fragment>
    <div className="h-100 bg-animation" style={{backgroundImage: `url(${bgLogin})`, backgroundSize: 'cover'}}>
      <div className="d-flex h-100 justify-content-center align-items-center">
        <div className="">
          <div className="">
            <div className="card">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="card-body">
                  <h3 className="text-center"> Thay đổi mật khẩu</h3> 
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group fill">
                        <label><b> Tài khoản:</b></label>
                        <input type="text"
                          id="userName" disabled ={ auth ? true: false }
                          {...register('userName', { required: true, maxLength: 50 })}
                          className="form-control"
                          placeholder="Nhập tài khoản người dùng" />
                      </div>
                      <div className="form-group fill mb-4">
                      <label><b>Nhập mật khẩu cũ:</b></label>
                        <input type="password"
                          id="password"
                          autoComplete="off"
                          {...register('passwordOld', { required: true })}
                          className="form-control"
                          placeholder="Nhập mật khẩu cũ" />
                      </div>
                      <div className="form-group fill mb-4">
                      <label><b> Nhập mật khẩu mới:</b></label>
                        <input type="password"
                          id="password"
                          autoComplete="off"
                          {...register('passwordNew', { required: true })}
                          className="form-control"
                          placeholder="Nhập mật khẩu mới" />
                      </div>
                      <div className="form-group fill mb-4">
                      <label><b>Nhập lại mật khẩu mới:</b></label>
                        <input type="password"
                          id="password"
                          autoComplete="off"
                          {...register('passwordConfirm', { required: true, maxLength: 50 })}
                          className="form-control"
                          placeholder="Nhập lại mật khẩu mới" />
                      </div>
                      <div className="text-center">
                          <button type="submit" className="btn btn-primary mb-4">Lưu</button>
                      </div>
                      <div className="form-group fill mb-4">
                        <i className="mb-2 text-muted">1. Ít nhất một chữ cái viết hoa <br/>2. Ít nhất một chữ thường<br/> 3. Ít nhất một chữ số<br/> 4. Ít nhất một ký tự đặc biệt nên có nhiều hơn 4 ký tự</i>
                      </div>
                   </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToastContainer
              position="top-right"
              autoClose={13000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
  </Fragment>
  );

}
