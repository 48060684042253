
import * as Types from "../../config/constaints";

const  initialState= {
  data:  {
    inbox: 0,
    discuss: 0,
    appFlow: 0,
    noti_comment: 0,
    noti_remindWork: 0
  },
  isError: false,
  messageError: null
  };

  export default function TotalHandling (state =initialState,action ){
    switch (action.type) { 
      case Types.TOTAL_HANDLING_SUCCESS:{ 
        if(action.payload.code !== 200)
          return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
        else {
          let mapData ={...state.data};
          mapData.inbox= action.payload.data.inbox;
          mapData.discuss= action.payload.data.discuss;
          mapData.appFlow= action.payload.data.appFlow;
          return  {...state,
            data:  mapData,
            };
        }
   }
    case Types.TOTAL_HANDLING_UPDATE:{ // Cập nhật Data Json của Form
      
        let mapData ={...state.data};
        if(action.payload.type==="INBOX")
            mapData.inbox = action.payload.value
        if(action.payload.type==="DISCUSS")
            mapData.discuss = action.payload.value 
        if(action.payload.type==="FOLLOW")
            mapData.appFlow = action.payload.value
        if(action.payload.type==="NOTI_COMMENT")
          mapData.noti_comment = action.payload.value
        if(action.payload.type==="NOTI_REMINDWORK")
            mapData.noti_remindWork = action.payload.value     
        return {...state,data :mapData}
    }
        default:
          break;
    }
    return state;
  }

