import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index'; 
import { addCommnet } from "../../Service/File/file-service"
import { 
    GetCommentSuccess,
    GetCommentRequest,UpdateCommentSuccess,
    GetNotificationCommentSuccess,
    GetPageNotificationCommentSuccess,
    GetNotificationCommentRequest
} from '../../Action/comment';
import { 
    GET_COMMENT,
    VOTE_COMMENT,
    DELETE_COMMENT,
    GET_NOTIFICATION_COMMENT,
    URL_UPDATE_NOTIFICATION_COMMENT 
} from '../../config/constainUrl'
import {TotalHandlingUpdate} from "../../Action/total-handling"


function* GetComment(action){
    try{
        const response = yield call(apiCall,"POST",`${GET_COMMENT}`, { AppId: action.payload.appid, 
                                                                      LogId: action.payload.logId,
                                                                      UserIdDiscuss:action.payload.userIdDiscuss
                                                                } ); // Goi API
        if(response.code===200)  yield put(GetCommentSuccess(response));
    }
    catch(e){
    }
}

function* SaveComment(action){
           try{
               const response = yield call(addCommnet,action.payload);
                if(response.code===200)
                  yield put(GetCommentRequest( {
                                                appid: action.payload.applicationId,
                                                logId: action.payload.lastLogId,
                                                userIdDiscuss:action.payload.userIdDiscuss
                                                }));
                else yield put(UpdateCommentSuccess( {id :action.payload.id, content:action.payload.content  }));
            }
            catch(e){
            }
}

function* VoteComment(action){
    try{
    const response = yield call(apiCall,"PUT",`${VOTE_COMMENT}/${action.payload.commentId}/${action.payload.vote}`);
        if(response.code===200)
             yield put(GetCommentRequest(action.payload.applicationId ));
            }
            catch(e){
            }
}

function* DeleteComment(action){
    try{
            const response = yield call(apiCall,"DELETE",`${DELETE_COMMENT}/${action.payload.commentId}`);
            if(response.code===200){
                yield put(GetCommentRequest(action.payload.applicationId ));
            }
         }
        catch(e){}
}

function* GetNotificationComment(){
    try{
                const response = yield call(apiCall,"POST",`${GET_NOTIFICATION_COMMENT}`,{ pageIndex: 0, itemsPerPage:10 });
                if(response.code===200){
                    yield put(GetNotificationCommentSuccess(response));
                    yield put(TotalHandlingUpdate({ type :"NOTI_COMMENT" , value: response.data.total}));
                }
        }
         catch(e){
             
        }
}

function* UpdateNotification(action){
    try{
                const response = yield call(apiCall,"GET",`${URL_UPDATE_NOTIFICATION_COMMENT}/${action.payload.commentId}`);
                if(response.code===200){
                    yield put(GetNotificationCommentRequest());   
                }
        }
         catch(e){
             
        }
}

function* GetPageNotificationComment(action){
    try{
                const response = yield call(apiCall,"POST",`${GET_NOTIFICATION_COMMENT}`,{ pageIndex: action.payload.pageIndex,
                                                                                           itemsPerPage: action.payload.itemsPerPage });
                if(response.code===200){
                    yield put(GetPageNotificationCommentSuccess(response));
                }
        }
         catch(e){
        }
}

export default function* Comment(){
    yield takeEvery(Type.GET_COMMENT_REQUEST, GetComment);
    yield takeEvery(Type.SAVE_COMMENT_REQUEST, SaveComment);
    yield takeEvery(Type.VOTE_COMMENT_REQUEST, VoteComment);
    yield takeEvery(Type.DELETE_COMMENT_REQUEST, DeleteComment);
    yield takeEvery(Type.GET_NOTIFICATION_COMMENT_REQUEST, GetNotificationComment);
    yield takeEvery(Type.UPDATE_NOTIFICATION_COMMENT, UpdateNotification);
    yield takeEvery(Type.GET_PAGE_NOTIFICATION_COMMENT_REQUEST, GetPageNotificationComment);
    
    
}