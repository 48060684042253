import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';
import {
  GET_ACHIEVEMENT_BY_ORG
} from '../../config/constainUrl';
import {
  GetAchievementByOrgSuccess
} from '../../Action/category';
import * as Type from '../../config/constaints';
import {
  apiCall
} from '../../API/index';

function* GetAchievementByOrg(action){
  const {id, gradelLevelId, departmentId, year} = {...action.payload}
  try{ 
      const response = yield call(apiCall,"GET",`${GET_ACHIEVEMENT_BY_ORG}/${id}/${gradelLevelId}/${departmentId}/${year}`); 
      if(response.code===200)
           yield put(GetAchievementByOrgSuccess(response));  
  }
  catch(e){
    
  }
}

export default function* Achievement(){
  yield takeEvery(Type.GET_ACHIEVEMENT_BY_ORG, GetAchievementByOrg);
}