
import * as Types from '../config/constaints'
export const GetEmloyeeCVRequest = (data)=>{
    return { 
        type: Types.EMPLOYEE_CV_REQUEST,
        payload:data
    }
}

export const GetEmloyeeCVSuccess = (data)=>{
    return {
        type: Types.EMPLOYEE_CV_SUCCESS,
        payload:data
    }
}
