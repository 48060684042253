import * as Types from '../config/constaints'

export const GetAccountPermissionRequest = (data) => {
  return {
    type: Types.GET_ACCOUNT_PERMISSION_REQUEST,
    payload: data
  }
}
// export const GetAccountPermissionSuccess = (data) => {
//     return {
//         type: Types.GET_ACCOUNT_PERMISSION_SUCCESS,
//         payload: data
//     }
// }
export const SetAccountPermission = (data)=>{;
    return {
        type: Types.SET_ACCOUNT_PERMISSION,
        payload:data
    }
}
export const GetPageButtonPermissionRequest = (data) => {
  return {
    type: Types.GET_PAGE_BUTTON_PERMISSION_REQUEST,
    payload: data.payload
  }
}
export const SetPageButtonPermission = (data) => {
  return {
    type: Types.SET_PAGE_BUTTON_PERMISSION,
    payload: data
  }
}