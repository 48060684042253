import { downloadFile } from "../File/file-service";
import axios from "axios";
import {EXPORT_TIMEKEEPRE,CHECK_PERMISSION_MANIPULATE } from "../../config/constainUrl";
import { HOST_API } from '../../config/constaints';
import {apiCall} from '../../API/index';
import { getValueCrypt } from '../../utils/crypt'

export function getDatesInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
        days.push(date.getDate());
        date.setDate(date.getDate() + 1);
    }
    return days;
}

export const checkPermissionManipulate = async (data) => {
    return await apiCall("GET", `${CHECK_PERMISSION_MANIPULATE}/${data.departmentId}/${data.gradelLevelId}`);
  }

export function getDateNameInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    var weekdays = new Array(7);
    weekdays[0] = "CN";
    weekdays[1] = "T2";
    weekdays[2] = "T3";
    weekdays[3] = "T4";
    weekdays[4] = "T5";
    weekdays[5] = "T6";
    weekdays[6] = "T7";
    while (date.getMonth() === month) {
        var r = weekdays[date.getDay()];
        days.push(r);
        date.setDate(date.getDate() + 1);
    }
    return days;
}
export function getDepartments (item,result) {
    if (item.childs.length > 0) {
        item.childs.forEach(e => {
          getDepartments(e,result);
        });
    }
    result.lstDepartments.push(item.id);
}
export function downFile (data) {
    var _fileName = fileName(data);
    axios({
        url: `${HOST_API}/${EXPORT_TIMEKEEPRE}`, //your url
        method: 'POST',
        data,
        headers: {
            "Authorization":  'Bearer ' + getValueCrypt("JWT_TOKEN")
        },
        responseType: 'blob', // important
      }).then((response) => {
          
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', _fileName); //or any other extension
         document.body.appendChild(link);
         link.click();
      });
}
function fileName (data) {
    let name="";
    switch (data.style) {
        case "CCF":
            name= "file-ket-qua-cham-cong.xlsx";
        break;
        case "CCL":
                name= "file-ket-qua-cham-cong-luong.xlsx";
         break;
        case "CCBS":
              name= "file-ket-qua-cham-cong-bo-sung.xlsx";
          break;
        default:
            break;
    }
    return name;
}


export const splitstr =  (str) => {
    var arr = str.split("/");
    return arr[0];
  }
  
export  const creatArray =(e)=>{
    var x = [];
    for (var i=1;i<=e;i++) {
      x.push(i);
    }
    return x;
  }

export  const  convertDay =  (str) => {
    var number = 0;
    switch (str) {
        case "Monday":
            number = 0;
            break;
        case "Tuesday":
            number = 1;
            break;
        case "Wednesday":
            number = 2;
            break;
        case "Thursday":
            number = 3;
            break;
        case "Friday":
            number = 4;
            break;
        case "Saturday":
            number = 5;
            break;
        default:
            number = 6;
            break;
    }
    return number;
}


