
import * as Types from "../../config/constaints";


const  initialState= {
    forms: [],
  data: null,
  isError: false,
  messageError: null,
  isLoading: 0,
  dataPrintDiscuss: {
    forms: [],
     data: null

  }
  };

  export default function FormWithDataJson (state =initialState,action ){


    switch (action.type) { 
      
        case Types.FORM_DATAJSON_SUCCESS:{   
          
          if(action.payload.code !== 200)
            return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
          else {

            
            return  {...state,
              forms:   action.payload.data.forms,
              data:  action.payload.data.data,
              isLoading: state.isLoading + 1
              };

          
          }
        }
        case Types.DETAIL_UPDATE_FORM:{ // Cập nhật Data Json của Form 
            return {...state, forms: action.payload}
          }

        case Types.DETAIL_UPDATE_DATALOG:{ // Cập nhật Data Json của Form
      
           return {...state, data: action.payload}
        }
        case Types.FORM_DATAJSON_PRINT_SUCCESS:{ // Cập nhật Data Json của Form
          return {...state, dataPrintDiscuss: {
            forms:   action.payload.data.forms,
            data:    action.payload.data
          } }
       }
        default:
          break;
    }

    
    return state;
  }

