import * as Types from "../../config/constaints";

const  initialState= {
    data: {
      list: [],
     total: 0
    },
    isError: false,
    messageError: null,
    };
    export default function ApplicationSearch (state =initialState,action ){
        switch (action.type) {
            case Types.GET_APPLICATION_SEARCH_SUCCESS:{
                if(action.payload.code !== 200)
                    return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                else {
                    return  {...state
                      ,data: {
                        list: action.payload.data.data,
                        total: action.payload.data.total
                    },
                       };
                  }  
            }
            default:
                return state;
        }
    }