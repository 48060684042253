import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import PrivateRoute from './PrivateRoute/index';
import Loader from "react-loaders";
import { MainNav } from "../AppNav/NavItems";
import ApplicationCancelBox from "../../Pages/Application/ApplicationCancelBox";

const AppHeader = lazy(() => import('../../Layout/AppHeader/index'));
const AppSidebar = lazy(() => import('../../Layout/AppSidebar/index'));
const Breadcrumb = lazy(() => import('../../Layout/Breadcrumb/index'));
const Inbox = lazy(() => import("../../Pages/Application/Inbox/index"));
const DiscussInbox = lazy(() => import("../../Pages/Application/Discuss-Inbox/index"));
const ApplicationDetail = lazy(() => import("../../Pages/Application/Detail/index"));
const UserCcDefault = lazy(() => import("../../Pages/Application/Usercc-Default/index"));
const Employees = lazy(() => import("../../Pages/Application/Employees/index"));
const Profile = lazy(() => import("../../Pages/PersonalInformation/Profile/index"));
const EmployeeCV = lazy(() => import("../../Pages/Application/Employee-CV/index"));
const WorkProgress = lazy(() => import("../../Pages/PersonalInformation/WorkProgress/index"));
const Contract = lazy(() => import("../../Pages/PersonalInformation/Contract/index"));
const PartyUnion = lazy(() => import("../../Pages/PersonalInformation/Party-Union/index"));
const DigitizingRecords = lazy(() => import("../../Pages/PersonalInformation/Digitizing-Records/index"));
const Proficiency = lazy(() => import("../../Pages/Category/Proficiency/index"));
const GroupSalaryRank = lazy(() => import("../../Pages/Category/GroupSalaryRank/index"));
const Country = lazy(() => import("../../Pages/Category/Country/index"));
const HRPlanning = lazy(() => import("../../Pages/Category/HRPlanning/index"));
const Administrativeunit = lazy(() => import("../../Pages/Category/Administrativeunit/index"));
const Religion = lazy(() => import("../../Pages/Category/Religion/index"));
const KPIs = lazy(() => import("../../Pages/Category/KPIs/index"));
const Ethnic = lazy(() => import("../../Pages/Category/Ethnic/index"));
const Specialized = lazy(() => import("../../Pages/Category/Specialized/index"));
const Organization = lazy(() => import("../../Pages/Category/Organization/index"));
const School = lazy(() => import("../../Pages/Category/School/index"));
const Holiday = lazy(() => import("../../Pages/Category/Holiday/index"));
const Zoning = lazy(() => import("../../Pages/Zoning/index"));
const OrganizationDecentralization = lazy(() => import("../../Pages/Administration/Organization-Decentralization/index"));
const Timekeeper = lazy(() => import("../../Pages/Parameters/Timekeeper/index"));
const Achievement = lazy(() => import("../../Pages/Category/Achievement/index"));
const ProcessedTime = lazy(() => import("../../Pages/Report/Processed-Time/index"));
const ApplicationDMS = lazy(() => import("../../Pages/Application/DMS/index"));
const ReportPGV = lazy(() => import("../../Pages/Report/PGV/index"));
const DeadlineInbox = lazy(() => import("../../Pages/Application/Deadline-Inbox/index"));
const AssignInbox = lazy(() => import("../../Pages/Application/Assign-inbox/index"));
const ApplicationSearch = lazy(() => import("../../Pages/Application/ApplicationSearch/index"));
const PageNotificationComment = lazy(() => import("../../Pages/Application/Notification/Comment"));
const StatusApp =  lazy(() => import("../../Pages/Report/StatusApp/index"));
const ReportAgergate = lazy(() => import("../../Pages/Report/Aggergate/index"));
const ApplicationProgress = lazy(() => import("../../Pages/Report/ApplicationProgress/index"));
const Density = lazy(() => import("../../Pages/Report/Density/index"));

const ReportKPIsAppComplete = lazy(() => import("../../Pages/Report/Kpis/AppComplete/index"));
const ReportKPIs = lazy(() => import("../../Pages/Report/Kpis/Kpis/index"));
const AggergateComplete = lazy(() => import("../../Pages/Report/AggergateComplete/index"));

const AppMain = () => {
    return (
        <>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <Breadcrumb />
                    <div className="main">
                        <Suspense fallback={
                            <div className="loader-container">
                                <div className="loader-container-inner">
                                    <div className="text-center">
                                        <Loader type="ball-pulse-rise" />
                                    </div>
                                    <h6 className="mt-5">
                                        Xin vui lòng đợi hệ thống đang load dữ liệu ...
                                    </h6>
                                </div>
                            </div>
                        }>
                            <PrivateRoute exact path={`/`} component={Inbox} />
                            <PrivateRoute path={`/inbox`} component={Inbox} />
                            <PrivateRoute path={`/inbox-follow`} component={Inbox} />
                            <PrivateRoute path={`/inbox-send`} component={Inbox} />
                            <PrivateRoute path={`/inbox-cc`} component={Inbox} />
                            <PrivateRoute path={`/inbox-discuss`} component={DiscussInbox} />
                            <PrivateRoute path={`/detail/:id`} component={ApplicationDetail} />
                            <PrivateRoute path={`/usercc-default`} component={UserCcDefault} />
                            <PrivateRoute path={`/employees`} component={Employees} />
                            <PrivateRoute path={`/profile`} component={Profile} />
                            <PrivateRoute path={`/inbox-cv`} component={EmployeeCV} />
                            <PrivateRoute path={`/inbox-dms`} component={ApplicationDMS} />
                            <PrivateRoute path={`/workProgress`} component={WorkProgress} />
                            <PrivateRoute path={`/contract`} component={Contract} />
                            <PrivateRoute path={`/party-union`} component={PartyUnion} />
                            <PrivateRoute path={`/digitizing-records`} component={DigitizingRecords} />
                            <PrivateRoute path={`/proficiency`} component={Proficiency} />
                            <PrivateRoute path={`/groupSalaryRank`} component={GroupSalaryRank} />
                            <PrivateRoute path={`/country`} component={Country} />
                            <PrivateRoute path={`/hrplanning`} component={HRPlanning} />
                            <PrivateRoute path={`/administrativeunit`} component={Administrativeunit} />
                            <PrivateRoute path={`/religion`} component={Religion} />
                            <PrivateRoute path={`/KPIs`} component={KPIs} />
                            <PrivateRoute path={`/ethnic`} component={Ethnic}></PrivateRoute>
                            <PrivateRoute path={`/specialized`} component={Specialized}></PrivateRoute>
                            <PrivateRoute path={`/organization`} component={Organization}></PrivateRoute>
                            <PrivateRoute path={`/school`} component={School}></PrivateRoute>
                            <PrivateRoute path={`/holiday`} component={Holiday}></PrivateRoute>
                            <PrivateRoute path={`/zonings`} component={Zoning}></PrivateRoute>
                            <PrivateRoute path={`/organization-decentralization`} component={OrganizationDecentralization}></PrivateRoute>
                            <PrivateRoute path={`/timekeeper`} component={Timekeeper}></PrivateRoute>
                            <PrivateRoute path={`/achievement`} component={Achievement}></PrivateRoute>
                            <PrivateRoute path={`/processed-time`} component={ProcessedTime}></PrivateRoute>
                            <PrivateRoute path={`/report-pgv`} component={ReportPGV}></PrivateRoute>
                            <PrivateRoute path={`/inbox-deadline`} component={DeadlineInbox}></PrivateRoute>
                            <PrivateRoute path={`/inbox-assign`} component={AssignInbox}></PrivateRoute>
                            <PrivateRoute path={`/inbox-cancel`} component={ApplicationCancelBox}></PrivateRoute>
                            <PrivateRoute path={`/application-search`} component={ApplicationSearch}></PrivateRoute>
                            <PrivateRoute path={`/lst-commnent`} component={PageNotificationComment}></PrivateRoute>
                            <PrivateRoute path={`/report-status-app`} component={StatusApp}></PrivateRoute>
                            <PrivateRoute path={`/report-aggregate`} component={ReportAgergate}></PrivateRoute>
                            <PrivateRoute path={`/report-application-progress`} component={ApplicationProgress}></PrivateRoute>
                            <PrivateRoute path={`/report-density`} component={Density}></PrivateRoute>
                            <PrivateRoute path={`/kpis-app-complete`} component={ReportKPIsAppComplete}></PrivateRoute>
                            <PrivateRoute path={`/kpis-app-complete-2`} component={ReportKPIs}></PrivateRoute>
                            <PrivateRoute path={`/report-aggregate-complete`} component={AggergateComplete}></PrivateRoute>
                        </Suspense>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AppMain;
