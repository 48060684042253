import { emptyParallel } from "linq-to-typescript";
export const HOST_API ="http://gateway.dvsc.com.vn/api.hrmv2";
export const HOST_IFRAME ="http://iframe.dvsc.com.vn";
export const HOST_HRM_V1="http://gateway.dvsc.com.vn/api.hrm";
export const IMG_AVARTAR="http://gateway.dvsc.com.vn/FileTemp"  
export const HUB_CONNECTION ="http://gateway.dvsc.com.vn/notification"; 

export const SYSTEM = { 
  id: "859C74AE-C5C1-4EC0-AEB1-D15671D5BBCC",
  code: "HRM",
  name: "HRM"
};


export  const PRIVATE_KEY_CRYPT ="19790388-6598-469b-808c-f8ed167d584c"
export const APPLICATION_TYPES = {
  "GOC": {
    ID: "5F24729A-1A2D-4B87-A4C1-B3DBDB2F2CCA",
    CODE: "GOC",
    NAME: "Gốc",
    CLASS: ""
  },
  "CHUYEN_TIEP": {
    ID: "45078BF0-EDE8-4FB5-B6CB-390DAB4D054D",
    CODE: "CT",
    NAME: "Chuyển tiếp",
    CLASS: ""
  },
  "DOC_LAP": {
    ID: "BE10991D-20B6-4E0D-9A59-3B5FD3E40228",
    CODE: "DL",
    NAME: "Độc lập",
    CLASS: "badge-cicle-success"
  },
  "PHOI_HOP_CHINH": {
    ID: "E140462B-B26A-43CE-90AF-7E6015B5F2E5",
    CODE: "PHC",
    NAME: "Phối hợp chính",
    CLASS: ""
  },
  "PHOI_HOP_PHU": {
    ID: "DAD2128B-BCEA-4118-BA9E-4F3AD9046878",
    CODE: "PHP",
    NAME: "Phối hợp phụ",
    CLASS: ""
  },
  "NOI_TIEP": {
    ID: "F0811675-35AB-4030-ABB4-3F062D588D3F",
    CODE: "NT",
    NAME: "Nối tiếp",
    CLASS: ""
  },
  "SAO_GUI": {
    ID: "809E122C-039D-4A2D-ACFC-D7CC9B315A3F",
    CODE: "SG",
    NAME: "Sao gửi",
    CLASS: ""
  },
  "BAO_CAO_DINH_KY": {
    ID: "BDCE2780-285A-41BB-826F-0D45EA1E085A",
    CODE: "BCDK",
    NAME: "Báo cáo định kỳ",
    CLASS: ""
  },
  "KE_HOACH_CONG_VIEC": {
    ID: "01B3C841-63F1-4328-85C3-5663E72BF326",
    CODE: "KHCV",
    NAME: "Kế hoạch công việc",
    CLASS: ""
  },
  "THAO_LUAN": {
    ID: "E8B9CDF0-4A55-47E6-995E-0A6BF24A6F3D",
    CODE: "TL",
    NAME: "Thảo luận",
    CLASS: ""
  }
}

export const TYPE_DISTRISCT =[{id:"Huyện", name: "Huyện"},{id:"Quận", name: "Quận"},{id:"Thành Phố", name: "Thành Phố"},{id:"Thị xã", name: "Thị xã"}]
export const TYPE_WARDS =[{id:"Phường", name:"Phường"},{id: "Thị trấn",name: "Thị trấn"},{id:"Xã",name:"Xã"}]
// trucnt1
export const YEARS = ['2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032'];
export const MONTH = [{id:"01",name:"1"},
{id:"02",name:"2"},
{id:"03",name:"3"},
{id:"04",name:"4"},{id:"05",name:"5"}
,{id:"06",name:"6"},
{id:"07",name:"7"},
{id:"08",name:"8"},{id:"09",name:"9"},{id:"10",name:"10"},{id:"11",name:"11"},{id:"12",name:"12"}];
export  const GroupPositions = [
  {id:"92941200-4c56-49c2-b15d-8e8feac16059",name:"Chuyên viên"},
  {id:"ce271b4c-863c-460e-849a-a267c2401122",name:"Phó"},
  {id:"37ddc954-fa1b-4356-9dc4-f21488e1a3e6",name:"Trưởng "} ]
// Danhs sách sản phẩm PGV
  export const PRODUCT_PGV =[ {"value":"457c00d7-975e-4ffa-9df6-e16365bd37be",title : "Xử lý kỷ luật"},
                              {"value":"AD105981-C3FB-4B16-89D1-18B8AE983659",title : "Bổ nhiệm ngoại lệ"},
                              {"value":"308C4189-69A5-4586-B731-EAA5CAAC66EC",title : "Bỗ nhiệm thông thường/Giao nhiệm vụ"},
                              {"value":"043EC1E0-A091-45E5-89BC-DAF47C8F374B",title : "Điều động và giao nhiệm vụ"},
                              {"value":"E98EF0D9-E1F1-452B-9351-8A42EF9A712B",title : "Điều động"},
                              {"value":"5FC6D49E-BB90-47AA-9C49-997C8377EC1E",title : "Điều động và bổ nhiệm"},
                              {"value":"82772BC0-9571-44C6-97C5-F764F2780D40",title : "Tuyển dụng"},
                              {"value":"6aedda0e-5503-4b7c-a652-98cff346ae07",title : "Giao công việc"}]
                       
export const PRODUCT_RECRUIT_STAGE_ID="3b2a025c-7d59-46c6-a71d-23166d4ffb33"; // quy trình tuyển tạo dùng CVonline
export const PRODUCT_RECRUIT_ID="82772bc0-9571-44c6-97c5-f764f2780d40"; // quy trình tuyển tạo dùng CVonline
export const PRODUCT_ON_LEAVE='34dfbcb8-bcbc-4334-b123-55e8de7b45fd' // quy trình nghỉ phép
export const PRODUCT_CONG_TAC='f7965fe1-85b4-4c3c-99a5-9ca67718b0a8' // quy trình trình công tác
export const  NOT_APPROVE ="NotApprove";
export const KEY_TIMEKEEPEE ='9f1e9045-9aac-4094-91a0-dcbefa2b206c' // quyền chốt chấm công
// initialState
export const initialState = {
  data: [],
  isError: false,
  messageError: null,
  isAdd: false
};

//sản phẩm profile employee
export const productProfileId= "6828A575-D798-4417-A2EB-2B2565807E88";

export const PRODUCTS = {
  "THI_DUA_KHEN_THUONG": "3a1b9b48-2aca-4197-b1ca-f5756e4bbf4c",
  "XU_LY_KY_LUAT": "457c00d7-975e-4ffa-9df6-e16365bd37be",
  "THAY_DOI_DKKD": "115f6a4d-5051-4de7-bcaa-d8549540568a",
  "GCV": "6aedda0e-5503-4b7c-a652-98cff346ae07",
  "TVB": "8623e616-9c15-4b82-be9a-70a7b464fc1f",
  
}

// Login
export const LOGIN_REQUEST = "LOGIN_REQUEST"  // Đang nhập
export const LOGIN_SUCCESS = "LOGIN_SUCCESS" // tra ket qua dang nhap thanh cong
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"

//Tạo mới hồ sơ
export const ADD_SUCCESS = "ADD_SUCCESS"
export const ADD_SELECT_REQUEST = "ADD_SELECT_REQUEST"
export const DATA_SELECT_ADD = "DATA_SELECT_ADD"
export const ADD_REQUEST = "ADD_REQUEST"
export const ADD_CV_ONLINE_REQUEST = "ADD_CV_ONLINE_REQUEST"
export const ADD_CV_ONLINE_SUCCESS="ADD_CV_ONLINE_SUCCESS"
export const CLOCK_IS_CREATED="CLOCK_IS_CREATED"

// Inbox
export const INBOX_REQUEST = "INBOX_REQUEST"
export const INBOX_SUCCESS = "INBOX_SUCCESS"
export const INBOX_ISFOLLOW_SUCCESS = "INBOX_ISFLLOW_SUCCESS"
export const INBOX_ISFOLLOW_REQUEST = "INBOX_ISFLLOW_REQUEST"

// Discuss Inbox
export const DISCUSS_INBOX_REQUEST = "DISCUSS_INBOX_REQUEST"
export const DISCUSS_INBOX_SUCCESS = "DISCUSS_INBOX_SUCCESS"
export const DISCUSS_INBOX_ISFOLLOW_SUCCESS = "DISCUSS_INBOX_ISFLLOW_SUCCESS"
export const DISCUSS_INBOX_ISFOLLOW_REQUEST = "DISCUSS_INBOX_ISFLLOW_REQUEST"
export const DISCUSS_INBOX_VIEW_FROM_REQUEST = "DISCUSS_INBOX_VIEW_FROM_REQUEST"
export const DISCUSS_INBOX_VIEW_FROM_SUCCESS = "DISCUSS_INBOX_VIEW_FROM_SUCCESS"
export const DISCUSS_INBOX_UPDATE_SUCCESS = "DISCUSS_INBOX_UPDATE_SUCCESS"

// Chi tiết hồ sơ
export const DETAIL_REQUEST = "DETAIL_REQUEST"
export const DETAIL_SUCCESS = "DETAIL_SUCCESS"
export const FORM_DATAJSON_REQUEST = "FORM_DATAJSON_REQUEST"
export const FORM_DATAJSON_SUCCESS = "FORM_DATAJSON_SUCCESS"
export const DETAIL_UPDATE_DATALOG = "DETAIL_UPDATE_DATALOG"
export const DELETE_APPLICATION_REQUEST="DELETE_APPLICATION_REQUEST"
export const TREE_APPLICATION_REQUEST="TREE_APPLICATION_REQUEST"
export const TREE_APPLICATION_SUCCESS="TREE_APPLICATION_SUCCESS"
export const DETAIL_UPDATE_FORM ="DETAIL_UPDATE_FORM";
export const FORM_DATAJSON_PRINT_SUCCESS ="FORM_DATAJSON_PRINT_SUCCESS";
// File
export const FILE_ADD_REQUEST = "FILE_ADD_REQUEST"

// Total Handling
export const TOTAL_HANDLING_SUCCESS = "TOTAL_HANDLING_SUCCESS"
export const TOTAL_HANDLING_UPDATE = "TOTAL_HANDLING_UPDATE"
export const TOTAL_HANDLING_REQUEST = "TOTAL_HANDLING_REQUEST"

// User toàn hoàn
export const USER_REQUEST = "USER_REQUEST"
export const USER_RESETPASSWORD_REQUEST = "USER_RESETPASSWORD_REQUEST"
export const USER_RESETPASSWORD_WITH_USER_REQUEST = "USER_RESETPASSWORD_WITH_USER_REQUEST"
export const USER_SUCCESSFULLY = "USER_SUCCESSFULLY"
export const USER_FAILURE = "USER_FAILURE"
export const USER_SELECTED_NODE_REQUEST="USER_SELECTED_NODE_REQUEST"

// Góp ý
export const GET_COMMENT_REQUEST = "GET_COMMENT_REQUEST";
export const GET_COMMTENT_SUCCESS = "GET_COMMTENT_SUCCESS";
export const SAVE_COMMENT_REQUEST = "SAVE_COMMENT_REQUEST";
export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const GET_COMMTENT_FAILURE = "GET_COMMTENT_FAILURE";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const VOTE_COMMENT_REQUEST = "VOTE_COMMENT_REQUEST";
export const VOTE_COMMENT_SUCCESS = "VOTE_COMMENT_SUCCESS"
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";



// Gán cứng bước tạo hs thảo luận. Nếu có thêm trường hợp tương tự sẽ đánh cờ từ workflow.
export const STAGE_CREATE_APPLICATION_DISCUSS = [
  "0223f09c-6402-4f80-81a7-1a3fe406d108",
  "e105d489-1f23-4057-b5a3-e04e4c675037"
];

// FLOW
export const FLOW_GET_DATA_SAVE = "FLOW_GET_DATA_SAVE";
export const FLOW_GET_DATA_TRANSFER = "FLOW_GET_DATA_TRANSFER";

//UserCcDefault

export const GET_PRODUCT_REQUEST="GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS="GET_PRODUCT_SUCCESS";
export const INSERT_UPDATE_USERCCDEFAULT ="INSERT_UPDATE_USERCCDEFAULT";
export const GET_USERCC_DEFAULT_REQUEST ="GET_USERCC_DEFAULT_REQUEST";
export const GET_USERCC_DEFAULT_SUCCESS ="GET_USERCC_DEFAULT_SUCCESS";


// EmployeeProfile
export const EMPLOYEE_INDIVIDUAL_REQUEST="EMPLOYEE_INDIVIDUAL_REQUEST";
export const EMPLOYEE_PROFILE_SUCCESS="EMPLOYEE_PROFILE_SUCCESS";
export const EMPLOYEE_WORKPROGRESS_REQUEST="EMPLOYEE_WORKPROGRESS_REQUEST";
export const EMPLOYEE_CONTRACT_REQUEST="EMPLOYEE_CONTRACT_REQUEST";
export const EMPLOYEE_PARTY_UNION_REQUEST="EMPLOYEE_PARTY_UNION_REQUEST";
export const EMPLOYEE_DIGITIZING_RECORDS_REQUEST="EMPLOYEE_DIGITIZING_RECORDS_REQUEST";
export const EMPLOYEE_APPLICATION_REQUEST="EMPLOYEE_APPLICATION_REQUEST";
export const EMPLOYEE_APPLICATION_SUCCESS="EMPLOYEE_APPLICATION_SUCCESS";
export const EMPLOYEE_FROM_DATAJSON_SUCCESS="EMPLOYEE_FROM_DATAJSON_SUCCESS";
export const EMPLOYEE_FROM_DATAJSON_REQUEST="EMPLOYEE_FROM_DATAJSON_REQUEST";
export const EMPLOYEE_UPDATE_FROM_DATAJSON_SUCCESS="EMPLOYEE_UPDATE_FROM_DATAJSON_SUCCESS";

//Employee-CV
export const EMPLOYEE_CV_REQUEST ="EMPLOYEE_CV_REQUEST";
export const EMPLOYEE_CV_SUCCESS ="EMPLOYEE_CV_SUCCESS";

// BUTTON LUONG HS
export const GET_ALL_BUTTONS = "GET_ALL_BUTTONS";
export const SET_ALL_BUTTONS = "SET_ALL_BUTTONS";
export const SET_IS_STAGE_SELF = "SET_IS_STAGE_SELF";
export const SET_IS_SHOW_PRINT = "SET_IS_SHOW_PRINT";
export const SET_IS_SHOW_CANCEL = "SET_IS_SHOW_CANCEL";
export const SET_IS_SHOW_SAVE = "SET_IS_SHOW_SAVE";
export const SET_IS_SHOW_TRANSFER = "SET_IS_SHOW_TRANSFER";
export const SET_IS_SHOW_ROLLBACK = "SET_IS_SHOW_ROLLBACK";
export const SET_IS_SHOW_APPROVE = "SET_IS_SHOW_APPROVE";
export const SET_IS_SHOW_COMPLETED = "SET_IS_SHOW_COMPLETED";
export const SET_IS_SHOW_SUPPORT = "SET_IS_SHOW_SUPPORT";
export const SET_IS_SHOW = "SET_IS_SHOW";
export const SET_IS_SHOW_ADD_INDEPENDENCE = "SET_IS_SHOW_ADD_INDEPENDENCE";
export const SET_IS_SHOW_ADD_SERIES = "SET_IS_SHOW_ADD_SERIES";
export const GET_IS_SHOW_CREATE_APPLICATION_CHILDREN = "GET_IS_SHOW_CREATE_APPLICATION_CHILDREN";
export const SET_IS_SHOW_CREATE_APPLICATION_CHILDREN = "SET_IS_SHOW_CREATE_APPLICATION_CHILDREN";
export const GET_IS_SHOW_APPROVE_ON_CHANGE_STAGE= "GET_IS_SHOW_APPROVE_ON_CHANGE_STAGE";
export const SET_IS_SHOW_APPROVE_ON_CHANGE_STAGE= "SET_IS_SHOW_APPROVE_ON_CHANGE_STAGE";
export const GET_RESET_BUTTONS = "GET_RESET_BUTTONS";
export const SET_RESET_BUTTONS = "SET_RESET_BUTTONS";

//Tree-ORG
export const TREE_ORG_REQUEST ="TREE_ORG_REQUEST";
export const TREE_ORG_SUCCESS ="TREE_ORG_SUCCESS";

export const DELETE_EMP_DES_POS ="DELETE_EMP_DES_POS";
export const INSERT_EMP_DES_POS ="INSERT_EMP_DES_POS";
export const INSERT_DELETE_EMP_DES_POS ="INSERT_DELETE_EMP_DES_POS";

export const GET_GROUP_SALARY_RANK_All_REQUEST="GET_GROUP_SALARY_RANK_All_REQUEST";
export const GET_GROUP_SALARY_RANK_All_SUCCESS ="GET_GROUP_SALARY_RANK_All_SUCCESS";
export const GET_BY_ID_GROUP_SALARY_RANK_REQUEST ="GET_BY_ID_GROUP_SALARY_RANK_REQUEST"
export const GET_BY_ID_GROUP_SALARY_RANK_SUCCESS ="GET_BY_ID_GROUP_SALARY_RANK_SUCCESS"
export const GET_BY_SALARY_RANK_REQUEST = "GET_BY_SALARY_RANK_REQUEST";
export const GET_BY_SALARY_RANK_SUCCESS = "GET_BY_SALARY_RANK_SUCCESS";
export const INSERT_UPDATE_GROUP_SALARY_RANK="INSERT_UPDATE_GROUP_SALARY_RANK";
export const DELETE_GROUP_SALARY_RANK="DELETE_GROUP_SALARY_RANK"
export const INSERT_UPDATE_SALARY_RANK="INSERT_UPDATE_SALARY_RANK";
export const DELETE_SALARY_RANK="DELETE_SALARY_RANK";
export const INSERT_UPDATE_SALARY_LEVE="INSERT_UPDATE_SALARY_LEVE";
export const DELETE_SALARY_LEVE="DELETE_SALARY_LEVE";
export const GET_PROVINCE_All_REQUEST="GET_PROVINCE_All_REQUEST";
export const GET_PROVINCE_All_SUCCESS="GET_PROVINCE_All_SUCCESS";
export const GET_DISTRICT_WITH_PROVINCE_REQUEST="GET_DISTRICT_WITH_PROVINCE_REQUEST";
export const GET_DISTRICT_WITH_PROVINCE_SUCCESS="GET_DISTRICT_WITH_PROVINCE_SUCCESS";
export const GET_WARDS_WITH_DISTRICT_REQUEST="GET_WARDS_WITH_DISTRICT_REQUEST";
export const GET_WARDS_WITH_DISTRICT_SUCCESS="GET_WARDS_WITH_DISTRICT_SUCCESS";
export const GET_COUNTRY_REQUEST="GET_COUNTRY_REQUEST";
export const GET_COUNTRY_SUCCESS="GET_COUNTRY_SUCCESS";
export const INSERT_UPDATE_PROVINCE ="INSERT_UPDATE_PROVINCE";
export const DELETE_PROVINCE ="DELETE_PROVINCE";
export const INSERT_UPDATE_DISTRICT ="INSERT_UPDATE_DISTRICT";
export const DELETE_DISTRICT ="DELETE_DISTRICT";
export const INSERT_UPDATE_WARDS ="INSERT_UPDATE_WARDS";
export const DELETE_WARDS ="DELETE_WARDS";

export const GET_RELIGION_REQUEST="GET_RELIGION_REQUEST";
export const GET_RELIGION_SUCCESS="GET_RELIGION_SUCCESS";
export const INSERT_UPDATE_RELIGION ="INSERT_UPDATE_RELIGION";
export const DELETE_RELIGION ="DELETE_RELIGION";

export const GET_KPIS_REQUEST ="GET_KPIS_REQUEST";
export const GET_KPIS_SUCCESS ="GET_KPIS_SUCCESS";
export const INSERT_UPDATE_KPIS ="INSERT_UPDATE_KPIS";
export const DELETE_KPIS ="DELETE_KPIS";


export const GET_SPECIALIZED_REQUEST ="GET_SPECIALIZED_REQUEST";
export const GET_SPECIALIZED_SUCCESS ="GET_SPECIALIZED_SUCCESS";
export const INSERT_UPDATE_SPECIALIZED ="INSERT_UPDATE_SPECIALIZED";
export const DELETE_SPECIALIZED ="DELETE_SPECIALIZED";

export const CREATE_USER = "CreateUser"
export const GET_GRADELLEVEL_REQUEST ="GET_GRADELLEVEL_REQUEST";
export const GET_GRADELLEVEL_SUCCESS ="GET_GRADELLEVEL_SUCCESS";
export const INSERT_UPDATE_GRADELLEVEL ="INSERT_UPDATE_GRADELLEVEL";


export const GET_DEPARTMENT_GRADELLEVEL_BRANCH_REQUEST ="GET_DEPARTMENT_GRADELLEVEL_BRANCH_REQUEST";
export const GET_DEPARTMENT_GRADELLEVEL_BRANCH_SUCCESS ="GET_DEPARTMENT_GRADELLEVEL_BRANCH_SUCCESS";
export const INSERT_UPDATE_DEPARTMENT_GRADELLEVEL ="INSERT_UPDATE_DEPARTMENT_GRADELLEVEL";



export const GET_POSITION_DEPARTMENT_REQUEST ="GET_POSITION_DEPARTMENT_REQUEST";
export const GET_POSITION_GROUP_DEPARTMENT_REQUEST ="GET_POSITION_GROUP_DEPARTMENT_REQUEST";
export const GET_POSITION_DEPARTMENT_SUCCESS ="GET_POSITION_DEPARTMENT_SUCCESS";
export const INSERT_UPDATE_POSITION_DEPARTMENT ="INSERT_UPDATE_POSITION_DEPARTMENT";

export const GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT_REQUEST ="GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT_REQUEST";
export const GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT_SUCCESS ="GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT_SUCCESS";
export const GET_POSITION_GROUP_DEPARTMENT_SUCCESS="GET_POSITION_GROUP_DEPARTMENT_SUCCESS"
export const INSERT_UPDATE_GROUP_DEPARTMENT ="INSERT_UPDATE_GROUP_DEPARTMENT";

export const INSERT_UPDATE_BRANCH_GROUP_DEPARTMENT ="INSERT_UPDATE_BRANCH_GROUP_DEPARTMENT";

//School
export const GET_SCHOOL_REQUEST ="GET_SCHOOL_REQUEST";
export const GET_SCHOOL_SUCCESS ="GET_SCHOOL_SUCCESS";
export const INSERT_UPDATE_SCHOOL ="INSERT_UPDATE_SCHOOL";
export const DELETE_SCHOOL ="DELETE_SCHOOL";
//Holiday
export const GET_HOLIDAY_REQUEST ="GET_HOLIDAY_REQUEST";
export const GET_HOLIDAY_SUCCESS ="GET_HOLIDAY_SUCCESS";
export const INSERT_UPDATE_HOLIDAY ="INSERT_UPDATE_HOLIDAY";
export const DELETE_HOLIDAY ="DELETE_HOLIDAY";
export const GET_HOLIDAY_MONTH_YEAR_REQUEST ="GET_HOLIDAY_MONTH_YEAR_REQUEST";
export const GET_HOLIDAY_MONTH_YEAR_SUCCESS ="GET_HOLIDAY_MONTH_YEAR_SUCCESS";
// TimeKeeper

export const TIMEKEEPER_USER_REQUEST ="TIMEKEEPER_USER_REQUEST";
export const TIMEKEEPER_USER_SUCCESS ="TIMEKEEPER_USER_SUCCESS";
export const GET_TIMEKEEPER_REQUEST="GET_TIMEKEEPER_REQUEST";
export const GET_TIMEKEEPER_SUCCESS="GET_TIMEKEEPER_SUCCESS";
export const UPDATE_TIMEKEEPER_PAGE_INDEX ="UPDATE_TIMEKEEPER_PAGE_INDEX";
export const UPDATE_TIMEKEEPER_MONTH ="UPDATE_TIMEKEEPER_MONTH";
export const UPDATE_TIMEKEEPER_YEAR ="UPDATE_TIMEKEEPER_YEAR";
export const EXPORT_TIMEKEEPER="EXPORT_TIMEKEEPER";
export const SAVE_LATCH="SAVE_LATCH";
export const GET_ISLATCH_TIMEKEEPER_REQUEST="GET_ISLATCH_TIMEKEEPER_REQUEST";
export const UPDATE_ISLATCH_TIMEKEEPER_SUCCESS="UPDATE_ISLATCH_TIMEKEEPER_SUCCESS";
export const UPDATE_DAY_LATCH ="UPDATE_DAY_LATCH";
export const UPDATE_PUBLIC_DAY ="UPDATE_PUBLIC_DAY";
export const SAVE_TIMESHEET_SALARY ="SAVE_TIMESHEET_SALARY";
export const SAVE_DATA_TIMEKEEPER_WITH_ADMIN="SAVE_DATA_TIMEKEEPER_WITH_ADMIN";
export const UPDATE_PERMISSION_MANIPULATE_REQUEST ="UPDATE_PERMISSION_MANIPULATE_REQUEST";
export const UPDATE_PERMISSION_MANIPULATE_SUCCESS ="UPDATE_PERMISSION_MANIPULATE_SUCCESS";
//Loading
export const UPDATE_LOADING_SUCCESS ="UPDATE_LOADING_SUCCESS";
// Achievement
export const GET_ACHIEVEMENT_BY_ORG = 'GET_ACHIEVEMENT_BY_ORG';
export const GET_ACHIEVEMENT_BY_ORG_SUCCESS = "GET_ACHIEVEMENT_BY_ORG_SUCCESS";

//Report
export const COLUMNS_PROCCESSED_TIME = [{
  dataField: 'name',
  text: 'Số HS'
}, {
  dataField: 'description',
  text: 'Nội dung'
 }, {
  dataField: 'stringStartedDate',
  text: 'Ngày tạo'
},{
  dataField: 'departmentName',
  text: 'Phòng ban'
},{
  dataField: 'branchName',
  text: 'Chi nhánh'
},{
  dataField: 'gradelLevelName',
  text: 'Khối'
},{
  dataField: 'fullName',
  text: 'Cán bộ'
},{
  dataField: 'processedTime',
  text: 'Thời gian xử lý'
},{
  dataField: 'rollbackTimes',
  text: 'Số lần trả lại'
}];
export const GET_PROCCESSED_TIME_BY_STAGE_REQUEST = 'GET_PROCCESSED_TIME_BY_STAGE_REQUEST';
export const GET_PROCCESSED_TIME_BY_STAGE_SUCCESS= 'GET_PROCCESSED_TIME_BY_STAGE_SUCCESS';
export const GET_APPLICATION_DMS_REQUEST ="GET_APPLICATION_DMS_REQUEST";
export const GET_APPLICATION_DMS_SUCCESS ="GET_APPLICATION_DMS_SUCCESS";
export const ADD_APP_DMS_SUCCESS ="ADD_APP_DMS_SUCCESS";
export const GET_REPORT_PGV_REQUEST = 'GET_REPORT_PGV_REQUEST';
export const GET_REPORT_PGV_SUCCESS= 'GET_REPORT_PGV_SUCCESS';
export const GET_LIST_EMPLOYEE_CHANGE_DEADLINE_REQUEST="GET_LIST_EMPLOYEE_CHANGE_DEADLINE_REQUEST"
export const GET_LIST_EMPLOYEE_CHANGE_DEADLINE_SUCCESS="GET_LIST_EMPLOYEE_CHANGE_DEADLINE_SUCCESS";
export const INSERT_LIST_EMPLOYEE_CHANGE_DEADLINE="INSERT_LIST_EMPLOYEE_CHANGE_DEADLINE";
export const GET_LIST_APPROVE_CHANGE_DEADLINE_REQUEST ="GET_LIST_APPROVE_CHANGE_DEADLINE_REQUEST";
export const GET_LIST_APPROVE_CHANGE_DEADLINE_SUCCESS ="GET_LIST_APPROVE_CHANGE_DEADLINE_SUCCESS"
export const INBOX_DEADLINE_REQUEST ="INBOX_DEADLINE_REQUEST";
export const INBOX_DEADLINE_SUCCESS ="INBOX_DEADLINE_SUCCESS";
export const INSERT_ASSIGNMENT="INSERT_ASSIGNMENT";
export const GET_INBOX_ASSIGN_REQUEST ="GET_INBOX_ASSIGN_REQUEST";
export const GET_INBOX_ASSIGN_SUCCESS ="GET_INBOX_ASSIGN_SUCCESS";
export const INBOX_ASSIGN_ISFOLLOW_SUCCESS="INBOX_ASSIGN_ISFOLLOW_SUCCESS";
export const GET_SEND_BACK_DMS_REQUEST ="GET_SEND_BACK_DMS_REQUEST";
export const GET_KPIS_APP ="GET_KPIS_APP"

// CANCEL APPLICATION
export const GET_APPLICATION_CANCEL_INFO_REQUEST = "GET_APPLICATION_CANCEL_INFO_REQUEST";
export const GET_APPLICATION_CANCEL_INFO_SUCCESS = "GET_APPLICATION_CANCEL_INFO_SUCCESS";
export const UPDATE_APPLICATION_CANCEL_CREATE_COMMENT = "UPDATE_APPLICATION_CANCEL_CREATE_COMMENT";
export const UPDATE_APPLICATION_CANCEL_APPROVE_COMMENT = "UPDATE_APPLICATION_CANCEL_APPROVE_COMMENT";
export const UPDATE_APPLICATION_CANCEL_ACCEPT = "UPDATE_APPLICATION_CANCEL_ACCEPT";
export const GET_APPLICATION_CANCEL_BOX_REQUEST = "GET_APPLICATION_CANCEL_BOX_REQUEST";
export const GET_APPLICATION_CANCEL_BOX_SUCCESS = "GET_APPLICATION_CANCEL_BOX_SUCCESS";
export const RESET_APPLICATION_CANCEL_STATE = "RESET_APPLICATION_CANCEL_STATE";

// Permission Button
export const BTN_VIEW_PERMISSION = "2838d6bc-0dfd-4c61-b373-d6b96ea6fc12"; 
export const BTN_ADD_PERMISSION = "19945cc6-f056-4f19-8c40-9afc665bc350";
export const BTN_EDIT_PERMISSION = "0a7188bb-b573-453a-b50a-5dbd3e4965cf";
export const BTN_RESET_PASSWORD_PERMISSION = "b86e5f4b-9c9c-44d6-95b3-e46493de973c";
export const BTN_VIEW_DETAIL_PERMISSION = "e83403cd-d9c9-4f5c-9124-52e978b36e7b";

// // Permission
export const PER_ADMIN = "de873feb-9f00-4d1a-8654-12a7541cf2e8";
export const PER_RESET_PASS = "5dfbe42a-b434-4dc3-a9dc-5ad7b9fee270";
export const PER_ADMIN_QLNS = "c00c6971-9278-44be-8648-db1c6ba8580b";
export const PER_LATCH_TIME_KEEPER = "64f636ab-5c65-45a9-9e44-f0013b6e7a6d";


// Permission Action
export const GET_PAGE_BUTTON_PERMISSION_REQUEST = "GET_PAGE_BUTTON_PERMISSION_REQUEST";
export const SET_PAGE_BUTTON_PERMISSION = "SET_PAGE_BUTTON_PERMISSION";
export const GET_ACCOUNT_PERMISSION_REQUEST = "GET_ACCOUNT_PERMISSION_REQUEST";
export const SET_ACCOUNT_PERMISSION = "SET_ACCOUNT_PERMISSION";

//test block 
export const PRODUCT_TEXT_BLOCK=['8623E616-9C15-4B82-BE9A-70A7B464FC1F','6AEDDA0E-5503-4B7C-A652-98CFF346AE07']
// Application search
export const GET_APPLICATION_SEARCH_REQUEST = "GET_APPLICATION_SEARCH_REQUEST";
export const GET_APPLICATION_SEARCH_SUCCESS = "GET_APPLICATION_SEARCH_SUCCESS";

//Notification Comment
export const GET_NOTIFICATION_COMMENT_REQUEST = "GET_NOTIFICATION_COMMENT_REQUEST";
export const GET_NOTIFICATION_COMMENT_SUCCESS = "GET_NOTIFICATION_COMMENT_SUCCESS";
export const UPDATE_NOTIFICATION_COMMENT= "UPDATE_NOTIFICATION_COMMENT";
export const UPDATE_NOTIFICATION_COMMENT_REDIRECT ="UPDATE_NOTIFICATION_COMMENT_REDIRECT";
export const GET_PAGE_NOTIFICATION_COMMENT_REQUEST ="GET_PAGE_NOTIFICATION_COMMENT_REQUEST"
export const GET_PAGE_NOTIFICATION_COMMENT_SUCCESS ="GET_PAGE_NOTIFICATION_COMMENT_SUCCESS"

export const GET_APPLICATION_PROGRESS_TREE = "GET_APPLICATION_PROGRESS_TREE";
export const SET_APPLICATION_PROGRESS_TREE = "SET_APPLICATION_PROGRESS_TREE";
export const GET_APPLICATION_PROGRESS_DATA = "GET_APPLICATION_PROGRESS_DATA";
export const SET_APPLICATION_PROGRESS_DATA = "SET_APPLICATION_PROGRESS_DATA";

// BEGIN REPORT DENSITY
export const GET_DENSITY_REPORT_DATA = "GET_DENSITY_REPORT_DATA";
export const SET_DENSITY_REPORT_DATA = "SET_DENSITY_REPORT_DATA";
// END REPORT DENSITY
export const GUID_EMPTY ="00000000-0000-0000-0000-000000000000";