import { GenerateDensityReportChart } from "../../Service/Report";
import * as Types from "../../config/constaints";

const initialState = {
  dataProccessedTimeStages: [],
  dataReportPGV: [],
  totalReportPGV: 0,
  applicationProgress: {
    isError: false,
    treeData: null,
    applicationsData: [],
  },
  densityReport: {
    data: null,
    chartData: null,
    isError: false,    
  }
};

const Report = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_PROCCESSED_TIME_BY_STAGE_SUCCESS:
      return { ...state, dataProccessedTimeStages: action.payload };
    case Types.GET_REPORT_PGV_SUCCESS:
      return { ...state, dataReportPGV: action.payload };
    case Types.SET_APPLICATION_PROGRESS_TREE:
      return {
        ...state,
        applicationProgress: {
          ...state.applicationProgress,
          isError: false,
          treeData: action.payload,
        }
      };
    case Types.SET_APPLICATION_PROGRESS_DATA:
      return {
        ...state,
        applicationProgress: {
          ...state.applicationProgress,
          isError: false,
          applicationsData: action.payload,
        }
      };
    case Types.SET_DENSITY_REPORT_DATA:
      const chartData = GenerateDensityReportChart(action.payload)
      return {
        ...state,
        densityReport: {
          ...state.densityReport,
          isError: false,
          data: action.payload,
          chartData: chartData
        }
      };
    default:
      break;
  }
  return state;
};

export default Report;
