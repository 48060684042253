import { faPaperclip, faThumbsDown, faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Collapse } from 'reactstrap'
import { useDispatch } from 'react-redux';
import { SaveCommentRequest } from '../../Action/comment';
import avatar1 from "../../assets/utils/images/avatars/icon-default.png";
import avatar2 from "../../Layout/Img/avartar.gif";
import classnames from "classnames";
import { extention} from '../../Service/File/file-service';
import { toast } from 'react-toastify';
import { IMG_AVARTAR } from '../../config/constaints';
import { MentionsInput, Mention } from 'react-mentions';
import {fomatText} from '../../Service/Hepper/hepper-service';
import './mention-style.css'

export default function SingleComment({ VoteComment,
         DeleteComment,
         commentData, 
         singleData,
         parent,
         appid,
         appName,
         userLoginId,
         users,
         downloadFile,
         logId,
         userAssginId,
         iSelected
        }) {
   
  
    const [ChilData, setChilData] = useState([]);
    const [showReply, setshowReply] = useState(false);
    const dispatch = useDispatch()
    const [listFileUpload, setlistFileUpload] = useState([]);
    const [tags, setTags] = useState([]);
    React.useEffect(() => {
        var childList = commentData.filter(p => p.parent == parent);
        setChilData(childList);
    }, [singleData]);

    React.useEffect(() => {
        if ((singleData.parent === null && ChilData.length === 0 && singleData.userId ===userLoginId) || singleData.parent && singleData.userId ===userLoginId) {
            setshowReply(true);
        } else {
            setshowReply(false);
        }
    }, [ChilData]);

    const [toogle, settoogle] = useState(false)
    const [toogleEdit, settoogleEdit] = useState(false)
    function toggle() {
        settoogle(!toogle);
    }

    const [hideButton, sethideButton] = useState(true);
    const [lsFileUploadSuccess, setlsFileUploadSuccess] = useState([]);

    function uploadFileChill(e) {

        var listFile = [];
        for (var i = 0; i < e.length; i++) {
            listFile.push(e[i])
        }
        setlistFileUpload(listFile);
    }

    function DeleteFileUpload(file) {
        setlsFileUploadSuccess(lsFileUploadSuccess.filter(p => p.name != file.name));
    }

    const [commentDTO, setcommentDTO] = useState({
        parent: parent,
        content: "",
        attachments: [],
        pings: {},
        id: null
    })

    const [comment, setcomment] = useState({
        appId: appid,
        userLoginId: userLoginId,
        data: commentDTO
    })

    function saveComment() {
        
        if(!comment.data.content)
              return;
        if(appName===undefined){
            toast.error("Không tìm thấy tên hồ sơ");
            return;
        }
             
        if(toogleEdit){
            dispatch(SaveCommentRequest({ ...comment.data,applicationId :comment.appId ,files: listFileUpload,appName:appName, pings: tags,userIdDiscuss: userAssginId,
                lastLogId: logId  })); 
            settoogleEdit(!toogleEdit);
        }
        else{
            dispatch(SaveCommentRequest({ ...comment.data, id: null,applicationId :comment.appId,files: listFileUpload,appName:appName, pings: tags,userIdDiscuss: userAssginId,
                lastLogId: logId   }));
            setcommentDTO({
                parent: parent,
                content: "",
                contentRaw: "",
                attachments: [],
                pings: {}
       
            });
            setcomment({
                appId: appid,
                userLoginId: userLoginId,
                data: commentDTO
            });
        }
        setlsFileUploadSuccess([]);
        sethideButton(true);
        toggle();
    }

    const editComment =(e)=>{
        let setTagsValue =[];
        settoogleEdit(!toogleEdit);
        toggle();
        if(e.pings.length > 0)
        {
                 e.pings.forEach(i => {
                    setTagsValue.push({id: i.userTag, name: i.userTagName})
                });
        }
        setcommentDTO({ ...commentDTO, content: e.content, id: e.id, parent: e.parent,  contentRaw:e.contentRaw });
        setTags(setTagsValue)
    }


    useEffect(() => {
        setcomment({ ...comment, data: commentDTO });
    }, [commentDTO])

    useEffect(() => {
        setcommentDTO({ ...commentDTO, attachments: lsFileUploadSuccess });
    }, [lsFileUploadSuccess])

    const removeFile = (e) =>{
       let list =  [...listFileUpload];
       let index=  list.indexOf(e);
       list.splice(index,1);
       setlistFileUpload(list);
    }

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    
    const value = event.target.value;
    const regex = /[^{}]+(?=})/g;
    value.match(regex);
    var content= fomatText(newValue);
    setcommentDTO({...commentDTO, contentRaw: value, content: content})
    let tags =[];
    mentions.forEach(e => {
        tags.push({UserTag:e.id,UserTagName: e.display.replace("@","") })
    });
    setTags(tags); 
}

function handleAnswer (value){
    
    let setTagsValue =[{id: value.userId, name: value.fullName + " - " +  value.userName}];
    setcommentDTO({ ...commentDTO, content:null, id: null, parent: value.id,  contentRaw: "["+ value.fullName + " - " + value.userName + "_"+ value.id + "]"  });
    setTags(setTagsValue)
}



    return (
        <Fragment>
            <div className="col-12">
                <div>
                    <div className="col-12">
                        <div className="avatar-icon avatar-icon-comment">
                            <img src={singleData.profilePictureURL ? IMG_AVARTAR + singleData.profilePictureURL  : avatar2 } alt="" />
                           
                        </div>
                        <p className="font-weight-bold title-name-comment">{singleData.fullName}  
                        <small style={{ float: "right" }} className="mt-1 opacity-6">{moment(singleData.created).format("DD/MM/YYYY HH:mm:ss")}</small>
                        </p>
                        <div>
                   </div> 
                  
                    </div>
                </div>
                <div>
                  {!toogleEdit &&

                            <div     className={classnames({
                                'user-commnet-active': userLoginId ===singleData.userId,
                                'user-commnet-notactive': userLoginId !=singleData.userId,
                                'comment-content': userLoginId== userLoginId,
                                'margin-5': userLoginId== userLoginId
                            })}  >
                       
                      <div dangerouslySetInnerHTML={{ __html: singleData.content }} />

              
                    </div>
                  }
                    
                    <div className='margin-5'>
                        {
                            singleData.attachments   ?
                                singleData.attachments.map((item, i) => {
                                    return (
                                        <div key={item.id}  onClick={() => downloadFile(item)}  className="badge color-file-comment cursor">
                                            <FontAwesomeIcon icon={faPaperclip} size="1x"></FontAwesomeIcon>
                                            {item.fileName}
                                        </div>
                                    )
                                })
                                : ""
                        }
                    </div>
                    <div className="font-weight-bold opacity-8 margin-5">
                        {
                          singleData.userId !=userLoginId &&
                            <Button className="btn cursor" color="default" onClick={() => {
                                toggle(singleData)
                                handleAnswer(singleData)
                            }}>
                            Trả lời
                            </Button>
                        }
                      
                        {/* <a onClick={() => VoteComment(singleData.id, true)}>
                            <span className="badge badge-pill cursor">{singleData.upvoteCountLike} </span>
                            {
                                singleData.userHasUpvoted == true ?
                                    <FontAwesomeIcon icon={faThumbsUp} color="blue" />
                                    :
                                    <FontAwesomeIcon color='#ccc' icon={faThumbsUp} />
                            }
                        </a>

                        <a onClick={() => VoteComment(singleData.id, false)}>
                            <span className="badge badge-pill cursor">{singleData.upvoteCountDisLike}</span>
                            {
                                singleData.userHasUpvoted == false ?
                                    <FontAwesomeIcon icon={faThumbsDown} color="red" />
                                    :
                                    <FontAwesomeIcon color='#ccc' icon={faThumbsDown} />
                            }
                        </a> */}

                        {
                            singleData.isComment && showReply ?
                                <>
                                    {/* <Button className="btn cursor" color="default"  onClick={() => {
                                                editComment(singleData);
                                    } } >
                                        Chỉnh sửa
                                    </Button> */}
                                    <Button className="btn cursor" color="default"  onClick={() => DeleteComment(singleData.id, singleData.created)}>
                                        Thu hồi
                                    </Button>
                                </>
                                :
                                ""
                        }
                         <Collapse isOpen={toogle} >
                <div className="chat-box-wrapper">
                    <div>
                        <div className="avatar-icon-wrapper mr-1">
                            <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />
                            <div className="avatar-icon avatar-icon-lg rounded">
                                <img width={42} className="rounded-circle" src={avatar1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "100%" }}>
                        <div style={{ position: "relative" }}>
                                           
                            <MentionsInput
                                        value={commentDTO.contentRaw ? commentDTO.contentRaw : ""}
                                        onChange={handleChange}
                                        placeholder="Nhập nội dung, sử dụng biểu tượng @ để gắn thẻ người dùng khác"
                                        className="mentions"
                                        >
                                        <Mention
                                            type="user"
                                            trigger="@"
                                            markup="[__display_____id__]"
                                            data={users}
                                            displayTransform={(id, display) => {
                                                return `@${display}`;
                                              }}
                                            className="mentions__mention"
                                        />
                                        </MentionsInput>
                        </div>
                        <div>
                            {
                                commentDTO.attachments.map((item, i) => {
                                    return (
                                        <div key={item.id} className="badge badge-pill badge-secondary">
                                            <FontAwesomeIcon icon={faPaperclip} size="1x"></FontAwesomeIcon>
                                            {item.name}
                                            <a style={{ cursor: "pointer" }} onClick={() => DeleteFileUpload(item)}>
                                                <FontAwesomeIcon className="ml-1" icon={faTimes} size="1x"></FontAwesomeIcon>
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ float: "right" }}  >
                            <label htmlFor={singleData.id} className="btn btn-light btn-sm mt-2 mr-2">
                                <FontAwesomeIcon icon={faPaperclip} size="1x"></FontAwesomeIcon>
                            </label>
                            <input id={singleData.id} multiple type="file" hidden={true} onChange={(e) => uploadFileChill(e.target.files)} />
                            <ul className='list-file-comment'> 
                                {
                                    listFileUpload.map((item, i) => 
                                            <div className="content-group margin-unset" key={i}>
                                                <div className="ficon ">
                                                    <img className="h-20 img-file-15" src={extention(item.name)} />
                                                </div>
                                                <div className="name url">
                                                    <div className="ap-xdot">
                                                        <span > <span className="underline cursor" > { item.name.substring(0, 20) } </span></span>
                                                        <span >< span onClick={() => removeFile(item)} className="lnr-trash cursor" > </span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                }
                            </ul>
                            <Button color="info" size="sm" className="btn" onClick={() => saveComment()}>Gửi</Button>
                        </div>
                    </div>
                </div>
            </Collapse>
                       
                    </div>
                </div>
            </div>

            {
             
             <ul style={{ listStyleType: "none" }}>
                 {
                    singleData.childs  &&   singleData.childs.sort((a, b) => moment( a.created) - moment(b.created)).map((child, i) => {
                     
                         return (
                             <li key={child.id}>
                                 <SingleComment VoteComment={VoteComment} DeleteComment={DeleteComment} commentData={commentData} singleData={child} parent={child.id}
                                     commentDTO={commentDTO}
                                     setcommentDTO={setcommentDTO}
                                     saveComment={saveComment}
                                     hideButton={hideButton}
                                     sethideButton={sethideButton}
                                     appid={appid}
                                     appName={appName}
                                     userLoginId={userLoginId}
                                     users={users}
                                     userAssginId = {userAssginId}
                                     logId={logId}
                                 ></SingleComment>
                             </li>
                         )
                     })
                 }
             </ul> 
            
     }

            {/* {
                !singleData.parent ?
                    <ul style={{ listStyleType: "none" }}>
                        {
                            ChilData .sort((a, b) => moment(a.created) ).map((child, i) => {
                                return (
                                    <li key={child.id}>
                                        <SingleComment userAssginId ={userAssginId} VoteComment={VoteComment} DeleteComment={DeleteComment} commentData={commentData} singleData={child} parent={child.id}
                                            commentDTO={commentDTO}
                                            setcommentDTO={setcommentDTO}
                                            saveComment={saveComment}
                                            hideButton={hideButton}
                                            sethideButton={sethideButton}
                                            appid={appid}
                                            appName={appName}
                                            userLoginId={userLoginId}
                                            users={users}
                                            logId={logId}
                                            iSelected={iSelected}
                                            
                                        ></SingleComment>
                                    </li>
                                )
                            })
                        }
                    </ul> :
                    <>
                        {
                            ChilData.sort((a, b) => moment(a.created) ).map((child, i) => {
                                return (
                                    // <li key={child.id}>
                                    <SingleComment key={child.id} VoteComment={VoteComment} DeleteComment={DeleteComment} commentData={commentData} singleData={child} parent={child.id}
                                        commentDTO={commentDTO}
                                        setcommentDTO={setcommentDTO}
                                        saveComment={saveComment}
                                        hideButton={hideButton}
                                        sethideButton={sethideButton}
                                        appName={appName}
                                        appid={appid}
                                        users={users}
                                        userLoginId={userLoginId}
                                        logId={logId}
                                        userAssginId={userAssginId}
                                        iSelected={iSelected}
                                    ></SingleComment>
                                    // </li>
                                )
                            })
                        }
                    </>
            } */}
        </Fragment >

    )
}
