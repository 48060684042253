
import * as Types from "../../config/constaints";

const  initialState= {
  data: null,
  isError: false,
  messageError: null
  };

  export default function EmployeeProfile (state =initialState,action ){
    // eslint-disable-next-line default-case
    switch (action.type) { 
          case Types.EMPLOYEE_PROFILE_SUCCESS:{ 
                if(action.payload.code !== 200)
                  return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                else {
                  return  {...state,data:  action.payload.data };
                }
          }
          case Types.EMPLOYEE_APPLICATION_SUCCESS:{ 
            if(action.payload.code !== 200)
              return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
            else {
              return  {...state,data:  action.payload.data };
            }
      }
  }
  return state;
}

