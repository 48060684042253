
import { call, put, takeEvery } from 'redux-saga/effects';
import { LoginSuccess, LoginRequest,LoginFail } from '../../Action/login';
import * as Type from '../../config/constaints';
import { USER_RESETPASSWORD,RESET_PASSWORD_USER } from '../../config/constainUrl';
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
function* login(action){
    try{
        
        let response='';
        if(!action.payload.isUserSupport)
              response = yield call(apiCall,"POST","account/login", action.payload); 
        else 
              response = yield call(apiCall,"POST","account/login-user-support", action.payload); 
        if(response.code===200)
             yield put(LoginSuccess(response));  
         else  yield put(LoginFail(response));         
    }
    catch(e){
        yield put(LoginFail(e));  
    }
}

function* ResetPassWord(action){
    try{
        const response = yield call(apiCall,"POST",USER_RESETPASSWORD,action.payload ); 
        if(response.code===200)
                toast.success(`${response.message}`, { theme: "colored" });   
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });   
    }
}

function* ResetPassWordUser(action){
    try{
        const response = yield call(apiCall,"POST",RESET_PASSWORD_USER,action.payload ); 
        if(response.code===200){
            toast.success(`${response.message}`, { theme: "colored" });   
            setTimeout(function() {
                window.location.href ="/login";
            }, 1500);
        }
    }
    catch(e){
        
        toast.error(`${e.message}`, { theme: "red" });   
    }
}

export default function* Account(){
    yield takeEvery(Type.LOGIN_REQUEST, login);
    yield takeEvery(Type.USER_RESETPASSWORD_REQUEST, ResetPassWord);
    yield takeEvery(Type.USER_RESETPASSWORD_WITH_USER_REQUEST, ResetPassWordUser);
}