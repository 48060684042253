import * as Types from '../config/constaints'

export const userRequest = () => {
    return {
        type: Types.USER_REQUEST,
        payload: null
    }
}
export const userSuccessfully = (data) => {
    return {
        type: Types.USER_SUCCESSFULLY,
        payload: data
    }
}
export const userFailure = (data) => {
    return {
        type: Types.USER_FAILURE,
        payload: data
    }
}

export const userReSetPassWordRequest = (data) => {
    return {
        type: Types.USER_RESETPASSWORD_REQUEST,
        payload: data
    }
}

export const userSelectNodeRequest = (data) => {
    return {
        type: Types.USER_SELECTED_NODE_REQUEST,
        payload: data
    }
}


export const userReSetPassWordWithUserRequest = (data) => {
    return {
        type: Types.USER_RESETPASSWORD_WITH_USER_REQUEST,
        payload: data
    }
}