
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_HOLIDAY,INSERT_UPDATE_HOLIDAY,DELETE_HOLIDAY, GET_HOLIDAY_WITH_MONTH_YEAR
   } from '../../config/constainUrl';
import { 
    GetHolidayRequest,GetHolidaySuccess} from '../../Action/category';
    import { GetHolidayWithMonthYearSuccess } from '../../Action/timeKeeper';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';

function* GetHolidayWithYear(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_HOLIDAY}/${action.payload}`); 
        if(response.code===200)
             yield put(GetHolidaySuccess(response));  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })   
    }
}

function* InsertOrUpdateHoliday(action){
    try{ 
        const response = yield call(apiCall,"POST",`${INSERT_UPDATE_HOLIDAY}`,action.payload); 
        if(response.code===200){
            yield put(GetHolidayRequest(action.payload.year));  
            toast.success(response.message, { theme: "colored" })    
        }   
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}
function* DeleteHoliday(action){
    try{ 
        const response = yield call(apiCall,"DELETE",`${DELETE_HOLIDAY}/${action.payload.id}`); 
        
        if(response.code===200){
            yield put(GetHolidayRequest(action.payload.intYear));  
            toast.success(response.message, { theme: "colored" })    
        }    
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* GetHolidayWithMonthYear(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_HOLIDAY_WITH_MONTH_YEAR}/${action.payload.month}/${action.payload.year}`); 
        if(response.code===200)
             yield put(GetHolidayWithMonthYearSuccess(response.data));  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })   
    }
}

export default function* Holiday(){
    yield takeEvery(Type.GET_HOLIDAY_REQUEST, GetHolidayWithYear);
    yield takeEvery(Type.INSERT_UPDATE_HOLIDAY, InsertOrUpdateHoliday);
    yield takeEvery(Type.DELETE_HOLIDAY, DeleteHoliday);
    yield takeEvery(Type.GET_HOLIDAY_MONTH_YEAR_REQUEST, GetHolidayWithMonthYear);
   
}