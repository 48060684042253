
import * as Types from "../../config/constaints";
import { CreateService } from  '../../Service/Create/create'

const initialState = {
  data: [],
  dataSelect: [],
  isError: false,
  isCreate: false,
  applicationId: '',
  messageError: null
  };

  export default function Create (state =initialState,action ){
    switch (action.type) { 
        case Types.ADD_SUCCESS:{ 
          if(action.payload.code !== 200)
            return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
          else  {
             return  {...state, isCreate: true,applicationId: action.payload.data };
          }
      }
      case Types.DATA_SELECT_ADD:{    
        if(action.payload.code !== 200)
          return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
        else {
           const data = CreateService.getDate(action.payload.data);
          return  {...state,
             data:action.payload.data,
             dataSelect:data,
             };
        }
    }
    case Types.ADD_CV_ONLINE_SUCCESS:{ 
      if(action.payload.code !== 200)
        return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
      else  {
         return  {...state, isCreate: true,applicationId: action.payload.data };
      }
  }
  case Types.CLOCK_IS_CREATED:
    { 
                return {...state, isCreate: action.payload }
    }
        default:
          break;
    }
    return state;
  }

