import axios from "axios";
import {HOST_API, AXIOS_HEADERS} from "../../config/constaints"
import
{
  UPDATE_USER_DISCUSS_WITH_USER,
  DISCUSS_CREATE_APPLICATION,
  APPLICATION_USER_DISCUSS,
  DATA_JSON_LOG ,FORM_DISCUSS,
  APPLICATION_DETAIL
}

from '../../config/constainUrl'
import { apiCall } from '../../API/index';
import { UrlApplicationDetail } from '../../Service/ApplicationDetail'
import { getValueCrypt } from "../../utils/crypt";

export const updateUserDiscussWithUser = (data) =>{
    return apiCall("POST",UPDATE_USER_DISCUSS_WITH_USER,data)
  }

  export const updateStatusDiscuss = (list, key) =>{
    for (let index = 0; index < list.length; index++) {
      if(list[index].id===key){
        list[index].status =1
      }
    }
    return  list;
  }

// flowGetRawNextStages: Lấy next stage từ wfm về không filter .
export const discussCreateApplication = async (userId, lastLogId, param) => {
  const url = `${HOST_API}/${DISCUSS_CREATE_APPLICATION}/${userId}/${lastLogId}`;
  const response = await axios({
      method: 'post',
      url: url,
      data: param,
      headers: {
        "Authorization": 'Bearer ' + getValueCrypt("JWT_TOKEN"),
        'Content-Type': 'application/json'
    }
  })
  const { status, data } = response;
  if (status === 200) {
      return {
          isSuccess: true,
          data: data.data,
          message: data.message
      };
  }
  else {
      return {
          isSuccess: false,
          data: "",
          message: `Lỗi khi Save: ${url}`
      }
  }
}


export   const getDataFormView = async (param) =>{

      const responseDetail = await apiCall("GET", `${APPLICATION_DETAIL}/${param.appId}`)
      // Lấy form xin ý kiến
      const responseFormDiscuss = await apiCall("GET",`${FORM_DISCUSS}/${param.formId}/${param.logId}`);
      // Lấy data json log
      const responseDataJson = await apiCall("GET",`${DATA_JSON_LOG}/${param.appId}/${param.logId}`);
       return  {
                data: responseDataJson.data,
                form: {...responseFormDiscuss.data[0], currentLogId: param.logId},
                model:responseDetail.data }
}
   