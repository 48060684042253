import React  from "react"; 
 import { AES, enc,mode,pad } from 'crypto-js';
 import { PRIVATE_KEY_CRYPT } from "../config/constaints";
 import { Redirect  } from "react-router-dom";
 export const decrypt =  (text) => {

    try {
       var _decrypt = AES.decrypt(text, PRIVATE_KEY_CRYPT);
        const decrypted = _decrypt.toString(enc.Utf8);
        return decrypted;
    } 
    catch (err) {}
  };

  export const encrypt = (text) => {
    try {
        const cipherText = AES.encrypt(text, PRIVATE_KEY_CRYPT).toString();
        return cipherText;
    }
     catch (err) {
    }
  };

  export const getValueCrypt =  (key) => {
    
    try {
       return   decrypt(localStorage.getItem(key));
    }
    catch {
      
        return   <Redirect to="/login" ></Redirect>
    }
  }


  
  export const encryptionPassword = (key) => {
    try {
      var key = enc.Utf8.parse('59b50e345cab8b6d421b161918ea3fbd7e5921eea7d43d1ac54fa92cca452bb5');
      var iv = enc.Utf8.parse('00000000000000000000000000000000');
      var encrypted = AES.encrypt(enc.Utf8.parse(key), key,
          {
              keySize: 128 / 8,
              iv: iv,
              mode: mode.CBC,
              padding: pad.Pkcs7
          });
      return encrypted.toString();
    }
    catch {
        
    }
   

  }
