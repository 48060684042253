import { call, put, takeEvery } from 'redux-saga/effects';
import { GetApplicationCancelBoxSuccess, GetApplicationCancelInfoSuccess, GetUserApproveCancelApplicationSuccess } from '../../Action/detail';
import { apiCall } from '../../API/index';
import { GET_APPLICATION_CANCEL_BOX, GET_APPLICATION_CANCEL_INFO, GET_USER_APPROVE_CANCEL_APPLICATION } from '../../config/constainUrl';
import {GET_APPLICATION_CANCEL_BOX_REQUEST, GET_APPLICATION_CANCEL_INFO_REQUEST, GET_APPLICATION_CANCEL_INFO_SUCCESS, GET_USER_APPROVE_CANCEL_APPLICATION_REQUEST, HOST_API} from '../../config/constaints';

function* getApplicationCancelInfoRequest(action) {
  try {
    const response = yield call(apiCall, "GET", `${GET_APPLICATION_CANCEL_INFO}/${action.payload.applicationId}/${action.payload.applicationLogId}`, null);
    if(response.code===200){
      yield put(GetApplicationCancelInfoSuccess(response.data))
  }
 } catch (err) {
    
  }
}

function* getApplicationCancelBoxRequest(action) {
  try {
    const response = yield call(apiCall, "POST", `${GET_APPLICATION_CANCEL_BOX}`, action.payload);
    if(response.code===200){
      yield put(GetApplicationCancelBoxSuccess(response.data))
    }
  } catch (err) {
   
  }
}

export default function* ApplicationCancel(){
  yield takeEvery(GET_APPLICATION_CANCEL_INFO_REQUEST, getApplicationCancelInfoRequest);
  yield takeEvery(GET_APPLICATION_CANCEL_BOX_REQUEST, getApplicationCancelBoxRequest);
}
