
import * as Types from "../../config/constaints";
import { CreateService } from  '../../Service/Create/create'

const initialState = {
  employees: [],
  data:{},
  messageError: null
  };

  export default function AppListChangeDeadline (state =initialState,action ){
    switch (action.type) { 
        case Types.GET_LIST_EMPLOYEE_CHANGE_DEADLINE_SUCCESS:{ 
            return  {...state, employees: action.payload.data };
        }

      case Types.GET_LIST_APPROVE_CHANGE_DEADLINE_SUCCESS:{ 
        return  {...state, employees: action.payload.data.employees,data:action.payload.data  };
       }
      default:
          break;
    }
    return state;
  }

