
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import { GET_PROCCESSED_TIME_BY_STAGES,GET_REPORT_PGV, URL_GET_APPLICATION_PROGRESS_DATA, URL_GET_DENSITY_REPORT_DATA, URL_GET_REPORT_APP_PROGRESS } from '../../config/constainUrl';
import { apiCall } from '../../API/index'; 
import { GetProccessedTimeByStageSuccess,GetReportPGVSuccess, SetApplicationProgressData, SetApplicationProgressTree, SetDensityReportData} from  '../../Action/report';
import { UpdateLoading } from '../../Action/loading';
import { toast } from 'react-toastify';

function* GetProccessedTimeByStages(action) {
    try {
        
        const response = yield call(apiCall,"POST",GET_PROCCESSED_TIME_BY_STAGES, action.payload); 
        if (response.code === 200) { yield put(GetProccessedTimeByStageSuccess(response.data)); } 
        else  toast.error(response.message, { theme: "red" })    
    }
    catch (e) { toast.error(e.message, { theme: "red" }) }
}

function* GetReportPGV(action) {
    try {
        const response = yield call(apiCall,"POST",GET_REPORT_PGV, action.payload); 
        if (response.code === 200) { yield put(GetReportPGVSuccess(response.data)); } 
        else  toast.error(response.message, { theme: "red" })    
    }
    catch (e) { toast.error(e.message, { theme: "red" }) }
}

function* GetApplicationProgressTree(action) {
    try {
        const response = yield call(apiCall, "POST", URL_GET_REPORT_APP_PROGRESS, action.payload);
        if (response.code === 200) { yield put(SetApplicationProgressTree(response.data)); }
        else  toast.error(response.message, { theme: "red" })    
    }
    catch (e) {

    }
}

function* GetApplicationProgressData(action) {
    try {
        const response = yield call(apiCall, "POST", URL_GET_APPLICATION_PROGRESS_DATA, action.payload);
        if (response.code === 200) { yield put(SetApplicationProgressData(response.data)); }
        else  toast.error(response.message, { theme: "red" }) 
    } catch (e) {
        
    }
}
function* GetDensityReportData(action) {
    try {
        const response = yield call(apiCall, "POST", URL_GET_DENSITY_REPORT_DATA, action.payload);
        if (response.code === 200) { yield put(SetDensityReportData(response.data)); }
        else  toast.error(response.message, { theme: "red" }) 
    } catch(e){

    }
}

export default function* Report() {
    yield takeEvery(Type.GET_PROCCESSED_TIME_BY_STAGE_REQUEST, GetProccessedTimeByStages);
    yield takeEvery(Type.GET_REPORT_PGV_REQUEST, GetReportPGV);
    yield takeEvery(Type.GET_APPLICATION_PROGRESS_TREE, GetApplicationProgressTree);
    yield takeEvery(Type.GET_APPLICATION_PROGRESS_DATA, GetApplicationProgressData);
    yield takeEvery(Type.GET_DENSITY_REPORT_DATA, GetDensityReportData);
}