
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index'; 
import { InboxSuccess,InboxIsFollowSuccess } from '../../Action/inbox';
import { TotalHandlingUpdate } from '../../Action/total-handling';
import { isFollow } from '../../Service/Inbox/inbox-service'
import { InboxIsFollowAssignSuccess } from '../../Action/appAssignment';


function* DataInbox(action){
    try{
        const response = yield call(apiCall,"POST","application/get-inbox",action.payload); // Goi API
        if(response.code===200){
            yield put(InboxSuccess(response));
            if(action.payload.paramFilter.type ==='INBOX' || action.payload.paramFilter.type ==='FOLLOW')
                yield put(TotalHandlingUpdate({  type :action.payload.paramFilter.type , value: response.data.unProcessed } ));
        }
            
    }
    catch(e){
       
    }
}

function* InboxIsFollow(action){
    try{
        const response = yield call(isFollow,!action.func ?  action.payload : action.payload.id);
        if(response.code===200){
            if(!action.func)
                  yield put(InboxIsFollowSuccess( { id:action.payload,response }));
            else   
                  yield put(InboxIsFollowAssignSuccess( { id:action.payload.id,response }));
        }
       
    }
    catch(e){
       
    }
}

export default function* Inbox(){
    yield takeEvery(Type.INBOX_REQUEST, DataInbox);
    yield takeEvery(Type.INBOX_ISFOLLOW_REQUEST, InboxIsFollow);
    
}