import * as Types from '../../config/constaints';

var initialState = {
    notificationCommentData: [],
    pageNotificationCommentData: [],
    total: 0,
    totalPageNotification: 0,
    isShow: false,
    isLoading: true,
    isRedirect:false
};

const NotificationComment = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_NOTIFICATION_COMMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                notificationCommentData: action.payload.data.list,
                total: action.payload.data.total
            };
            case Types.UPDATE_NOTIFICATION_COMMENT_REDIRECT:
                return {
                    ...state,
                    isRedirect: action.payload,
                };
            case Types.GET_PAGE_NOTIFICATION_COMMENT_SUCCESS:
                return {
                    ...state,
                    pageNotificationCommentData: action.payload.data.list,
                    totalPageNotification: action.payload.data.total
                };
        default: 
            return state;
    }
};

export default NotificationComment;