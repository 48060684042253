
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_SPECIALIZED,
    INSERT_UPDATE_SPECIALIZED,
    DELETE_SPECIALIZED
   } from '../../config/constainUrl';
import {  
    GetSpecializedRequest,
    GetSpecializedSuccess
       } from '../../Action/category';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';

function* GetSpecializedAll(action){
    try{ 
        
        const response = yield call(apiCall,"GET", `${GET_SPECIALIZED}/${action.payload.pageIndex}/${action.payload.pageSize}` ); 
        if(response.code===200)
             yield put(GetSpecializedSuccess(response));  
    }
    catch(e){
          toast.error(e.message, { theme: "red" })    
    }
}

function* InsertOfUpdateSpecialized(action){
    try{ 
        const response = yield call(apiCall,"POST",INSERT_UPDATE_SPECIALIZED,action.payload); 
        if(response.code===200){
            yield put(GetSpecializedRequest({pageSize: 10, pageIndex:0}));  
            toast.success(response.message, { theme: "colored" })    
        }
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* DeleteSpecialized(action){
    try{ 
        const response = yield call(apiCall,"DELETE",`${DELETE_SPECIALIZED}/${action.payload}`); 
        if(response.code===200){
            yield put(GetSpecializedRequest({pageSize: 10, pageIndex:0}));  
            toast.success(response.message, { theme: "colored" })    
        }    
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

export default function* Specialized(){
    yield takeEvery(Type.GET_SPECIALIZED_REQUEST, GetSpecializedAll);
    yield takeEvery(Type.INSERT_UPDATE_SPECIALIZED, InsertOfUpdateSpecialized);
    yield takeEvery(Type.DELETE_SPECIALIZED, DeleteSpecialized);
   
}