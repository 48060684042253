
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import {GET_INBOX_USER_DEADLINE} from '../../config/constainUrl';
import { apiCall } from '../../API/index'; 
import {  InboxDeadlineSuccess } from '../../Action/deadline-inbox';

function* GetDeadLineInbox(action){
    try{
        const response = yield call(apiCall,"POST",GET_INBOX_USER_DEADLINE,action.payload);
        if(response.code===200){
            yield put( InboxDeadlineSuccess(response));
        }
    }
    catch(e){
        
    }
}

export default function* DeadlineInbox(){
    yield takeEvery(Type.INBOX_DEADLINE_REQUEST, GetDeadLineInbox);   
   
    
}