
import React, { Fragment, useState } from 'react';
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import Moment from 'moment';

export default function Item({item, index, openModalHandler, ...otherProps}) {
  
  return (
    <tr>
      <td width="5%" className="text-center">
        {index}
      </td>
      <td width="35%">
        <a onClick={() => openModalHandler(item)}>
          <div className="inbox-title cursor" style={{ color: "#000" }}>
            <b>
              <span>[{item.applicationName}]</span>
            </b> {item.description}
          </div>
        </a>
      </td>
      <td width="30%">
        <b>Người gửi:</b> {item.fullName} <br />
        <b>Chức danh:</b> {item.positionName} <br />
        <b>Đơn vị:</b> {item.departmentName} <br />
        <b>Ngày xin hủy: {Moment(item.createDate).format('DD/MM/YYYY')}</b> 
      </td>
      <td width="20%" className='text-left'>        
        {
          item.createComment.length < 150 ? item.createComment :
          `${item.createComment.substring(0, 149)}...`
        }
      </td>
      <td width="20%" className='text-center'>
        {!item.await && !item.accept && 
        <i color="red" className="pe-7s-close color-pe-7s-close font-3em"></i>
        }
        {!item.await && item.accept && 
        <i color="red" className="pe-7s-check color-pe-7s-check font-3em"></i>
        }
        {item.await && <Badge color="secondary" className='cursor'>Đang đợi</Badge>}
      </td>
    </tr>
  )
}
