
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_COUNTRY,
   
   } from '../../config/constainUrl';
import { 
  
     GetCountrySuccess} from '../../Action/category';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';


function* GetCountryAll(action){
    try{ 
        const response = yield call(apiCall,"GET",GET_COUNTRY); 
        if(response.code===200)
             yield put(GetCountrySuccess(response));  
    }
    catch(e){
      
    }
}

export default function* Country(){
    yield takeEvery(Type.GET_PROVINCE_All_REQUEST, GetCountryAll);
   
}