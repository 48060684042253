
import * as Types from "../../config/constaints";
import { getValueCrypt } from "../../utils/crypt";

const  initialState= {
  infor: {},
  historys:[],
  treeApplication:[],
  model: null,
  isError: false,
  messageError: null
  };

  export default function ApplicationDetail (state =initialState,action ){
    switch (action.type) { 
      
      case Types.DETAIL_SUCCESS:{    
        if(action.payload.code !== 200)
          return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
        else {
          state = initialState;
          let listIds =[];
          if(action.payload.data.implementByLogId)
          {
            const auth = JSON.parse(getValueCrypt("ROLE"));
            const positions =JSON.parse(auth.positions);
            positions.forEach(e => {
              if( e.Permission.IsLeader)
                  listIds.push(e.Permission.DepartmentId)
            });
          }
          var ischeckimplementByDepartment =listIds.find((v)=> { return v== action.payload.data.implementByDepartmentId }) 
          const item = {
            id: action.payload.data.id,
            parentId: action.payload.data.parentId,
            name: action.payload.data.name,
            product_id: action.payload.data.productId,
            is_finished : action.payload.data.isFinished,
            is_completed: action.payload.data.isCompleted,
            stage_name:  action.payload.data.stageName,
            stage_id:  action.payload.data.stageId,
            description: action.payload.data.description,
            created_date: action.payload.data.createdDate,
            deadline_app:  action.payload.data.deadline,
            approve_by_user_full_name: action.payload.data.approveByUserFullName,
            rate: action.payload.data.rate,
            last_log_id : action.payload.data.lastLogId,
            allow_edit : action.payload.data.allowEdit,
            phase_name:  action.payload.data.phaseName,
            product_name: action.payload.data.productName,
            status_application:action.payload.data.statusApplication,
            approve_by_username: action.payload.data.approveByUsername,
            approve_by_department_name: action.payload.data.approveByDepartmentName,
            file_upload_in_stage: action.payload.data.fileUploadInStage,
            is_approve: action.payload.data.isAccept,
            c3: action.payload.data.c3,
            isShowChangeDealine: ischeckimplementByDepartment ? true : false ,
            isShowRequestCancel: ischeckimplementByDepartment ? true : false ,
            gradelLevel_current_name: action.payload.data.gradelLevelCurrentName,
            department_current_name: action.payload.data.departmentCurrentName,
            position_current_name: action.payload.data.positionCurrentName,
            isAccept : action.payload.data.isAccept
          }
          return  {...state,
             infor:  item,
             historys:  action.payload.data.historys,
             model:   action.payload.data,
            };
        }
   }
   case Types.TREE_APPLICATION_SUCCESS:{    
        if(action.payload.code !== 200)
            return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
        else {
            return  {...state
              ,treeApplication:action.payload.data,
              };
      }
   }
        default:
          break;
    }
    return state;
  }

