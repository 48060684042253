
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_RELIGION_ALL,
    DELETE_RELIGION,
    INSERT_UPDATE_RELIGION
   
   } from '../../config/constainUrl';
import { 
  
    GetReligionRequest,
    GetReligionSuccess} from '../../Action/category';
import { MESSAGE_SUCCESS_DELETE } from '../../config/constaintNotify';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';

function* GetReligionAll(action){
    try{ 
        const response = yield call(apiCall,"GET",GET_RELIGION_ALL); 
        if(response.code===200)
             yield put(GetReligionSuccess(response));  
    }
    catch(e){
          toast.error(e.message, { theme: "red" })    
    }
}

function* InsertOfUpdateReligion(action){
    try{ 
        
        const response = yield call(apiCall,"POST",INSERT_UPDATE_RELIGION,action.payload); 
        if(response.code===200){
            yield put(GetReligionRequest());  
            toast.success(response.message, { theme: "colored" })    
        }
            
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* DeleteReligion(action){
    try{ 
        const response = yield call(apiCall,"DELETE",`${DELETE_RELIGION}/${action.payload}`); 
        if(response.code===200){
            yield put(GetReligionRequest(response));  
            toast.success(response.message, { theme: "colored" })    
        }
            
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

export default function* Religion(){
    yield takeEvery(Type.GET_RELIGION_REQUEST, GetReligionAll);
    yield takeEvery(Type.INSERT_UPDATE_RELIGION, InsertOfUpdateReligion);
    yield takeEvery(Type.DELETE_RELIGION, DeleteReligion);
   
}