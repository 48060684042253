import React, { useState} from "react";
import Pagination from "react-js-pagination";
 

  export default function Paginate (props) {

    const [isPage, setPage] = useState( { pageIndex: (props.pageIndex === 0 ) ? 1 : props.pageIndex ,total: props.total});
    function handlePageChange (pageNumber){
      setPage({...isPage, pageIndex: pageNumber});
        props.pagination(pageNumber)
      }
    return (
      <div>
        <Pagination  className ="pull-right" 
          activePage={ isPage.pageIndex}
          itemsCountPerPage={props.pageSize}
          totalItemsCount={props.total}
          pageRangeDisplayed={5}
          onChange={ handlePageChange }
        />
      </div>
    )
  
}

