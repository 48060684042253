
import { call, put, takeEvery } from 'redux-saga/effects';
import {  GetApplicationDMSSuccess,GetApplicationDMSRequest } from '../../Action/dms';
import * as Type from '../../config/constaints';
import { GET_APPLICATION_DMS, SEND_BACK_PREMISSION } from "../../config/constainUrl";
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';


function* GetData(action){

    try{ 
        const response = yield call(apiCall,"POST",GET_APPLICATION_DMS,action.payload); 
        if(response.code===200)
             yield put(GetApplicationDMSSuccess(response));
        else   toast.error(response.message, { theme: "red" })   
    }
    catch(e){
        toast.error(e.message, { theme: "red" })   
    }
}

function* SendBackDMSRequest(action){

    try{ 
        const response = yield call(apiCall,"POST",`${SEND_BACK_PREMISSION}`, { c3: action.payload.c3,  note: action.payload.note}); 
        if(response.code===200)
        {
            yield put(GetApplicationDMSRequest(action.payload.isFiiter));
            toast.success(response.message, { theme: "colored" })   
        }
           
        else   toast.error(response.message, { theme: "red" })   
    }
    catch(e){
        toast.error(e.message, { theme: "red" })   
    }
}

export default function* DMS(){
    yield takeEvery(Type.GET_APPLICATION_DMS_REQUEST, GetData);
    yield takeEvery(Type.GET_SEND_BACK_DMS_REQUEST, SendBackDMSRequest);
}