
import * as Types from "../../config/constaints";
import { getIndexArrayWithId } from "../../Service/Hepper/hepper-service"
const  initialState= {
    users: [],
    table:[],
    total: 0,
    timekeeper:[],
    isPermissionTimekeeper:false,
    holiday:[],
    page: {
      itemsPerPage: 10,
      pageIndex: 0
    },
    fitter: {
      month: new Date().getMonth() + 1 <= 9 ? "0" + (new Date().getMonth() + 1)  :new Date().getMonth() + 1,
      year:new Date().getFullYear(),
      searchStr:'',
      isLatch:false,
      islatchTimeSheetSalary: false,
      day:0,
      publicDay: 0
    },
    isError: false,
    messageError: null
  };

  export default function Timekeeper (state =initialState,action ){

    switch (action.type) { 
      case Types.TIMEKEEPER_USER_SUCCESS:{   
        if(action.payload.code !== 200)
          return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
        else return  {...state,users: action.payload.data.data,total:action.payload.data.total };
   }
   case Types.GET_TIMEKEEPER_SUCCESS:{     

      if(action.payload.code !== 200)
        return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
      else return  {...state,timekeeper: action.payload.data};
          
      }
      case Types.UPDATE_TIMEKEEPER_PAGE_INDEX:{  
               
          return  {...state,page: {itemsPerPage: 10, pageIndex:action.payload }};
       }

       case Types.UPDATE_TIMEKEEPER_MONTH:{   
          let  fitterNew ={...state.fitter};
          fitterNew.month =action.payload;
          return  {...state,fitter: fitterNew}
        };
     
      case Types.UPDATE_TIMEKEEPER_YEAR:{         
            let  fitterNew ={...state.fitter};
            fitterNew.year =action.payload;
            return  {...state,fitter: fitterNew}
          }
           
      case Types.UPDATE_ISLATCH_TIMEKEEPER_SUCCESS:{         
        let  fitterNew ={...state.fitter};
        fitterNew.isLatch =action.payload.data;
        return  {...state,fitter: fitterNew}
      }
      case Types.UPDATE_DAY_LATCH:{  
        
        if(action.payload.code === 200)     
        {
          let  fitterNew ={...state.fitter};
          fitterNew.day =  action.payload.data != null ? action.payload.data.day : 0;
          fitterNew.islatchTimeSheetSalary =  action.payload.data != null ? true : false
          return  {...state,fitter: fitterNew}
        }  
      }
      
      // eslint-disable-next-line no-fallthrough
      case Types.UPDATE_PUBLIC_DAY:{  
        
            if(action.payload.code === 200)     
            {
              let  fitterNew ={...state.fitter};
              fitterNew.publicDay =action.payload.data;
              return  {...state,fitter: fitterNew}
            }  
      }
      // eslint-disable-next-line no-fallthrough
      case Types.GET_HOLIDAY_MONTH_YEAR_SUCCESS:{  
          return  {...state,holiday: action.payload}
      }

      case Types.UPDATE_PERMISSION_MANIPULATE_SUCCESS:{  
        return  {...state,timekeeper: action.payload}
    }
    
      
       default:
          return state;
    }
    return state;
  }

