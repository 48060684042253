import * as Types from "../../config/constaints";

const  initialState= {
  data: [], //Nhóm gạch lương,
  total:0,
  isError: false,
  messageError: null
  };

  export default function Specialized (state =initialState,action ){
    
    // eslint-disable-next-line default-case
    switch (action.type) { 
          case Types.GET_SPECIALIZED_SUCCESS:{ 
                if(action.payload.code !== 200)
                  return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                else {
                  return  {...state,data:  action.payload.data.list, total: action.payload.data.total };
                }
          }
         
          default:
            return state;
  }
}

