
import * as Types from '../config/constaints'
export const GetTreeOrgRequest = (data)=>{
    return { 
        type: Types.TREE_ORG_REQUEST
    }
}

export const GetTreeOrgSuccess = (data)=>{
    return {
        type: Types.TREE_ORG_SUCCESS,
        payload:data
    }
}


export const InsertEmpDesPos = (data)=>{
    return {
        type: Types.INSERT_EMP_DES_POS,
        payload:data
    }
}


export const DeleteEmpDesPos = (data)=>{
    return {
        type: Types.DELETE_EMP_DES_POS,
        payload:data
    }
}

export const InsertDeleteEmpDesPos = (data)=>{
    return {
        type: Types.INSERT_DELETE_EMP_DES_POS,
        payload:data
    }
}

