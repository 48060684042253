import { HOST_API } from '../../config/constaints';
import { INBOX_FOLLOW,NOTIFICATION_APP_TIME_LINE} from '../../config/constainUrl';
import { apiCall } from '../../API/index';
import { Progress } from "reactstrap";
import React from 'react';
export const isFollow = (appId) => {
  return apiCall("PUT", `${HOST_API}/${INBOX_FOLLOW}/${appId}`, null);
}


export const pathInbox = (key) => {
  
  let _Path = null;
  switch (key[1]) {
    case "inbox": // HS đế
      _Path = "INBOX"
      break;
    case "inbox-follow": // HS theo  dõi
      _Path = "FOLLOW"
      break;
    case "inbox-cc": // HS CC
      _Path = "CC"
      break;
    case "inbox-send": // HS CC
      _Path = "SENBOX"
      break;
    case "inbox-discuss": // HS CC
      _Path = "DISCUSS"
      break;
      case "inbox-assign": // HS CC
      _Path = "ASSIGN"
      break;
    default:
      _Path = "INBOX"
      break;
  }
  return _Path;
}

export const ElementProgress = (e) => {
  let htm = "";
  if (e.statusApplication) {
    switch (e.statusApplication.id) {
      case 0:
       // htm = <Progress color="#99ff00" title={e.statusApplication.isExtend ? 'Hoàn thành đúng gia hạn' : 'Hoàn thành trước hạn'} className={e.statusApplication.isExtend ? 'Progress-true-0' : 'Progress-false-0'} color='success' value={e.statusApplication.percent}>{e.statusApplication.percent}%</Progress>
     //   htm = <Progress  color='#99ff00' className='Progress-0'  title="Hoàn thành trước hạn"  value={e.statusApplication.percent}>{e.statusApplication.percent}%</Progress>
        htm = <div className="progress">
              <div className="progress-bar Progress-0"  title="Hoàn thành trước hạn"  role="progressbar" aria-valuenow={e.statusApplication.percent} aria-valuemin="0" aria-valuemax="100" style={{width:e.statusApplication.percent + "%" }}>
              {e.statusApplication.percent}%
              </div>
      </div>
        break;
      // case 1:
      // //  htm = <Progress  title={e.statusApplication.isExtend ? 'Hoàn thành quá gia hạn' : 'Hoàn thành quá hạn'} className={e.statusApplication.isExtend ? 'Progress-true-1' : 'Progress-false-1'} color='danger' value={e.statusApplication.percent}>{e.statusApplication.percent}%</Progress>
      // //  htm = <Progress  color='#ff6729' className='Progress-1'  title={"Hoàn thành trể hạn"}  value={e.statusApplication.percent}>{e.statusApplication.percent}%</Progress>
      //       htm = <div className="progress">
      //       <div className="progress-bar Progress-1"  title="Hoàn thành trể hạn"  role="progressbar" aria-valuenow={e.statusApplication.percent} aria-valuemin="0" aria-valuemax="100" style={{width:e.statusApplication.percent + "%" }}>
      //         {e.statusApplication.percent}%
      //         </div> 
      //       </div>
      // break;
      // case 2:
      // //  htm = <Progress  color='#ff0000' className='Progress-2'  title=" Chưa hoàn thành"  value={e.statusApplication.percent}>{e.statusApplication.remain} ngày</Progress>
       
      // htm = <div className="progress">
      // <div className="progress-bar Progress-2"  title="Chưa hoàn thành"  role="progressbar" aria-valuenow={e.statusApplication.percent} aria-valuemin="0" aria-valuemax="100" style={{width:e.statusApplication.percent + "%" }}>
      // {e.statusApplication.remain} ngày
      //   </div> 
      // </div>

      // break;
      case 3:
       // htm = <Progress  color='#c4d79b' className='Progress-3'  value={e.statusApplication.percent}>{e.statusApplication.remain} ngày</Progress>
       
       htm = <div className="progress">
              <div className="progress-bar Progress-3"  title="Chưa đến hạn"  role="progressbar" aria-valuenow={e.statusApplication.percent} aria-valuemin="0" aria-valuemax="100" style={{width:e.statusApplication.percent + "%" }}>
              {e.statusApplication.remain} ngày
                </div> 
             </div>
       break;
      case 4:
      case 1:
      case 2:
        htm = <Progress title="Không xác định" className="Progress-true-4" value={e.statusApplication.percent}> KXĐ</Progress>
        break;
      case 5:
     //   htm = <Progress   color='#c0f50a' className="Progress-5" title="'Hoàn thành đúng hạn'"  className={e.statusApplication.isExtend ? 'Progress-true-5' : 'Progress-false-5'} value={e.statusApplication.percent}>{e.statusApplication.percent}% </Progress>

        htm=  <div className="progress">
            <div className="progress-bar Progress-5" title="Hoàn thành đúng hạn" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:e.statusApplication.percent + "%" }}>
            {e.statusApplication.percent}%
            </div>
          </div>
        break;
      default:
      // code block
    }
  }

  


  return htm;

}


export const ElementProgresstTitle = (e) => {
  
  let htm = "";
  if (e.statusApplication) {
    switch (e.statusApplication) {
      case "HTTH":
      
        htm = <div className="progress">
              <div className="progress-bar Progress-0"  style={{padding:"10px"}}  title="Hoàn thành trước hạn"  role="progressbar"  aria-valuemin="0" aria-valuemax="100" >
              HTTH
              </div>
      </div>
        break;
      case "HTTrH":

            htm = <div className="progress">
            <div className="progress-bar Progress-1" style={{padding:"10px"}}  title="Hoàn thành trể hạn"  role="progressbar"  aria-valuemin="0" aria-valuemax="100">
                HTTrH
              </div> 
            </div>
      break;
      case "CHT":
      htm = <div className="progress">
      <div className="progress-bar Progress-2" style={{padding:"10px"}}  title="Chưa hoàn thành"  role="progressbar"  aria-valuemin="0" aria-valuemax="100">
       CHT
        </div> 
      </div>

      break;
      case "CĐH":
       // htm = <Progress  color='#c4d79b' className='Progress-3'  value={e.statusApplication.percent}>{e.statusApplication.remain} ngày</Progress>
       
       htm = <div className="progress">
              <div className="progress-bar Progress-3"  style={{padding:"10px"}}  title="Chưa đến hạn"  role="progressbar"  aria-valuemin="0" aria-valuemax="100" >
             CĐH
                </div> 
             </div>
       break;
      case "KXĐ":
        htm = <Progress title="Không xác định" style={{padding:"10px"}} className="progress-bar"> KXĐ</Progress>
        break;
      case "HTĐH":
     //   htm = <Progress   color='#c0f50a' className="Progress-5" title="'Hoàn thành đúng hạn'"  className={e.statusApplication.isExtend ? 'Progress-true-5' : 'Progress-false-5'} value={e.statusApplication.percent}>{e.statusApplication.percent}% </Progress>

        htm=  <div className="progress">
            <div className="progress-bar Progress-5" style={{padding:"10px"}} title="Hoàn thành đúng hạn" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
            HTĐH
            </div>
          </div>
        break;
      default:
      // code block
    }
  }

  


  return htm;

}

export const getNotificationsAppTimeLine =(type,isBLD)=>{
  return apiCall("GET", `${HOST_API}/${NOTIFICATION_APP_TIME_LINE}/${type}/${isBLD}`, null);
}