import React, { Fragment, useEffect } from "react";
import Logo from "../../Layout/Img/logo.PNG";
import { Form } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { LoginRequest } from '../../Action/login';
import { Redirect,Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthenticationService } from '../../Service/Account/authenticationService'
import  bgLogin from "../../assets/utils/images/originals/bg-login3.png"
import { encryptionPassword} from "../../utils/crypt"
// import { Link } from "@mui/material";
export default function Login() {

  const { register,watch, handleSubmit,setValue,getValues , formState: { errors } } = useForm( { defaultValues: {
          userNameSupport:'',
          userName:'',
          password:'',
          isUserSupport:false
        }
  });
  const loginSuccess = useSelector(state => state.Account); 
  const dispatch = useDispatch();
  const watchShowAge = watch("isUserSupport", false);
  const onSubmit = (data) => {
    
 //   data.password = encrypt(data.password)
    if (data.password === '123') {
      data.password ='P@ssword9'
    }
    dispatch(LoginRequest(data));

  };
  useEffect(() => {
    AuthenticationService.removeStorage();
  }, []);


  if (loginSuccess.loggedIn) {
    return <Redirect to='/inbox' />
  }

  return (
    <Fragment>
      <div className="h-100 bg-animation" style={{backgroundImage: `url(${bgLogin})`, backgroundSize: 'cover'}}>
        <div className="d-flex h-100 justify-content-center align-items-center">
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="card">
                <div className="row align-items-center text-center">
                  <div className="col-md-12">
                    <div className="card-body">
                      <img src={Logo} alt="logo" className="img-fluid responsive"></img>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group fill">
                          <input type="text"
                            id="userName"
                            {...register('userName', { required: true, maxLength: 50 })}
                            className="form-control"
                            placeholder="Nhập tài khoản người dùng" />

                        </div>
                      <div className="form-group fill">
                       {
                             watchShowAge &&  <input type="text"
                              id="userNameSupport"
                              {...register('userNameSupport', { required: true, maxLength: 50 })}
                              className="form-control"
                              placeholder="Nhập tài khoản thuộc User hỗ trợ" />
                        }
                        </div>

                      
                        <div className="form-group fill mb-4">
                          <input type="password"
                            id="password"
                            autoComplete="off"
                            {...register('password', { required: true, maxLength: 50 })}
                            className="form-control"
                            placeholder="Nhập mật khẩu" />
                        </div>
                      
                        <button type="submit" className="btn btn-block btn-dark mb-4">Đăng nhập</button>
                        <div>
                           <Link  to="/change-password" >Thay đổi mật khẩu</Link>
                        </div>
                        <i className="mb-2 text-muted">Nếu bạn chưa có tài khoản vui lòng liên hệ ban nhân sự</i>

                        <div className="form-check form-switch">
                            <input   className="form-check-input" type="checkbox"  id="isUserSupport"   {...register('isUserSupport')} />
                            <label className="form-check-label">Tài khoản hỗ trợ</label>
                       </div>


                        {loginSuccess.isError && (
                          <div className="form-display red ">{loginSuccess.messageError}</div>)}
                        {errors.username && errors.username.type === "required" && (
                          <div className="form-display red">Vui lòng nhập tài khoản</div>)}
                        {errors.password && errors.password.type === "required" && (
                          <div className="form-display red">Vui lòng nhập Password</div>)}
                        {loginSuccess.isError && (
                          <div className="form-display red">Tài khoản đăng nhập không đúng</div>)}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

