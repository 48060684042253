import * as Types from "../../config/constaints";

const  initialState= {
  data: [], 
  isError: false,
  messageError: null
  };

  export default function Country (state =initialState,action ){
    
    // eslint-disable-next-line default-case
    switch (action.type) { 
          case Types.GET_COUNTRY_SUCCESS:{ 
                if(action.payload.code !== 200)
                  return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                else {
                  return  {...state,data:  action.payload.data };
                }
          }
         
          default:
            return state;
  }
}

