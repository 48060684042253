import * as Types from "../../config/constaints";

const  initialState= {
  gradelLevel: [], 
  branch:[],
  departmentHO: [],
  departmentBranch: [], 
  positionHO:[],
  positionBranch:[],
  isError: false,
  messageError: null
  };

  export default function Organization (state =initialState,action ){
    
    // eslint-disable-next-line default-case
    switch (action.type) { 
          case Types.GET_GRADELLEVEL_SUCCESS:{ 
            
                if(action.payload.data.code !== 200)
                  return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                      else {
                        
                        return  {...state,gradelLevel: action.payload.data.data,
                                       branch:action.payload.branch.data,
                                       departmentHO : action.payload.data.data.length === 0 ? [] : state.departmentHO,
                                      };
                      }
                }
          case Types.GET_DEPARTMENT_GRADELLEVEL_BRANCH_SUCCESS:{ 
            if(action.payload.code !== 200)
              return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                    else {
                      return  {...state,departmentHO: action.payload.data,
                          positionHO: action.payload.data.length === 0 ? [] : state.positionHO };
                    }
              }
      case Types.GET_POSITION_DEPARTMENT_SUCCESS:{ 
        if(action.payload.code !== 200)
          return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
              else {
                return  {...state,positionHO: action.payload.data };
              }
        }

        case Types.GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT_SUCCESS:{ 
          if(action.payload.code !== 200)
            return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                else {
                  return  {...state,departmentBranch: action.payload.data };
                }
          }

          case Types.GET_POSITION_GROUP_DEPARTMENT_SUCCESS:{ 
            if(action.payload.code !== 200)
              return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                  else {
                    return  {...state,positionBranch: action.payload.data };
                  }
            }
          
          
         
          default:
            return state;
  }
}

