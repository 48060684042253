import * as Types from "../../config/constaints";

const initialState = {
    statusCode: 400,
    isSuccess: false,
    data: [],
    message: ""

}

const TreeOrg = (state = initialState, action) => {
            switch (action.type) { 
                case Types.TREE_ORG_SUCCESS:{    
                if(action.payload.code !== 200)
                    return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                else {
                    return  {...state,data:  action.payload.data };
                }
                
            }
          
        }
        return state;
}

export default TreeOrg;