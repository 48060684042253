
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import { INSERT_OF_DELETE_EMPLOYEE_DEPARTMENT_PERMISSION,GET_ORGTREE_PERMISSION } from '../../config/constainUrl';
import { apiCall } from '../../API/index'; 
import { GetTreeOrgSuccess  } from '../../Action/treeOrg';
import { GetTreeOrgRequest } from "../../Action/treeOrg";
import { toast } from 'react-toastify';
function* GetTreeOrg(action){
    try{

        const response = yield call(apiCall,"GET",GET_ORGTREE_PERMISSION); // Goi API
        if(response.code===200){
           
           yield put(GetTreeOrgSuccess(response));
        }
             
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });
    
    }
}


function* InsertDeleteEmpDesPos(action){
    try{
        const response = yield call(apiCall,"POST",INSERT_OF_DELETE_EMPLOYEE_DEPARTMENT_PERMISSION,action.payload); // Goi API
        if(response.code===200) {
            toast.success(response.message, { theme: "colored" }) 
            yield put(GetTreeOrgRequest());
        } 
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });
    }
}


export default function* TreeOrg(){
    yield takeEvery(Type.TREE_ORG_REQUEST, GetTreeOrg);
    yield takeEvery(Type.INSERT_DELETE_EMP_DES_POS, InsertDeleteEmpDesPos);
   
}