import * as Types from '../config/constaints'


export const DiscussInboxRequest = (data)=>{
 
    return { 
        type: Types.DISCUSS_INBOX_REQUEST,
        payload:data
    }
}

export const DiscussInboxSuccess = (data)=>{
    return {
        type: Types.DISCUSS_INBOX_SUCCESS,
        payload:data
    }
}

export const DiscussInboxIsFollowRequest = (data)=>{
    return {
        type: Types.DISCUSS_INBOX_ISFOLLOW_REQUEST,
        payload:data
    }
}
export const DiscussInboxIsFollowSuccess = (data)=>{
    return {
        type: Types.DISCUSS_INBOX_ISFOLLOW_SUCCESS,
        payload:data
    }
}


export const DiscussInboxViewFormRequest = (data)=>{
    return {
        type: Types.DISCUSS_INBOX_VIEW_FROM_REQUEST,
        payload:data
    }
}

export const DiscussInboxViewFormSuccess = (data)=>{
    return {
        type: Types.DISCUSS_INBOX_VIEW_FROM_SUCCESS,
        payload:data
    }
}

export const UpdateStatusDiscuss = (data)=>{
    return {
        type: Types.DISCUSS_INBOX_UPDATE_SUCCESS,
        payload:data
    }
}







