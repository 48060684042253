export const MainNav = [{
    icon: "pe-7s-note2",
    label: "Quản lý hồ sơ",
    content: [{
        icon: "pe-7s-angle-right",
        label: "Hồ sơ đến",
        to: "/inbox",
        bredcrumb: "HRM/Quản lý hồ sơ/Hồ sơ đến"



      },
      {
        icon: "pe-7s-angle-right",
        label: "Hồ sơ đã xử lý",
        to: "/inbox-send",
        bredcrumb: "HRM/Quản lý hồ sơ/Hồ sơ đã xử lý"


      },
      {
        icon: "pe-7s-angle-right",
        label: "Hồ sơ theo dõi",
        to: "/inbox-follow",
        bredcrumb: "HRM/Quản lý hồ sơ/Hồ sơ theo dõi"

      },
      {
        icon: "pe-7s-angle-right",
        label: "Hồ sơ CC",
        to: "/inbox-cc",
        bredcrumb: "HRM/Quản lý hồ sơ/Hồ sơ CC"

      },
      {
        icon: "pe-7s-angle-right",
        label: "Thảo luận",
        to: "/inbox-discuss",
        bredcrumb: "HRM/Quản lý hồ sơ/Thảo luận"

      }, {
        icon: "pe-7s-angle-right",
        label: "Kiểm soát hồ sơ",
        to: "/inbox-assign",
        bredcrumb: "HRM/Quản lý hồ sơ/Kiểm soát hồ sơ"

      },
      {
        icon: "pe-7s-angle-right",
        label: "Sao gửi mặc định",
        to: "/usercc-default",
        bredcrumb: "HRM/Quản lý hồ sơ/Sao gửi mặc định"

      },
      {
        icon: "pe-7s-angle-right",
        label: "CV ứng tuyển",
        to: "/inbox-cv",
        bredcrumb: "HRM/Quản lý hồ sơ/CV ứng tuyển"
      },

      {
        icon: "pe-7s-angle-right",
        label: "Hồ sơ công văn",
        to: "/inbox-dms",
        bredcrumb: "HRM/Quản lý hồ sơ/Hồ sơ công văn"
      },
      {
        icon: "pe-7s-angle-right",
        label: "Gia hạn",
        to: "/inbox-deadline",
        bredcrumb: "HRM/Quản lý hồ sơ/Gia hạn"
      },
      {
        icon: "pe-7s-angle-right",
        label: "Hồ sơ hủy",
        to: "/inbox-cancel",
        bredcrumb: "HRM/Quản lý hồ sơ/Hồ sơ hủy"
      },
      {
        icon: "pe-7s-angle-right",
        label: "Tìm kiếm",
        to: "/application-search",
        bredcrumb: "HRM/Quản lý hồ sơ/Tìm kiếm"
      }
    ]
  },
  {
    icon: "pe-7s-add-user",
    label: "Thông tin cá nhân",
    IsRoot: false,
    content: [

      {
        icon: "",
        label: "Hồ sơ cá nhân",
        to: "/profile",
        bredcrumb: "HRM/Hồ sơ cá nhân/profile"

      },
      {
        icon: "",
        label: "Quá trình làm việc",
        to: "/workProgress",
        bredcrumb: "HRM/Hồ sơ cá nhân/Quá trình làm việc"

      },
      {
        icon: "",
        label: "Hợp đồng lao động",
        to: "/contract",
        bredcrumb:"HRM/Hồ sơ cá nhân/Hợp đồng lao động"

      },
      {
        icon: "",
        label: "Đảng - Đoàn",
        to: "/party-union",
        bredcrumb: "HRM/Hồ sơ cá nhân/Đảng - Đoàn"

      },
      {
        icon: "",
        label: "Số hóa hồ sơ",
        to: "/digitizing-records",
        bredcrumb: "HRM/Hồ sơ cá nhân/Số hóa hồ sơ"

      }
    ]
  },
  {
    icon: "pe-7s-albums",
    label: "Danh mục",
    IsRoot: false,
    content: [{
        icon: "",
        label: "Khung năng lực",
        to: "/proficiency",
        bredcrumb: "HRM/Danh mục/Khung năng lực"
      },
      {
        icon: "",
        label: "Thang bảng lương",
        to: "/groupSalaryRank",
        bredcrumb: "HRM/Danh mục/Thang bảng lương"
      },
      {
        icon: "",
        label: "Quốc gia",
        to: "/country",
        bredcrumb: "HRM/Danh mục/Quốc gia"
      }, {
        label: "Định biên",
        to: "/hrplainning",
        bredcrumb: "HRM/Danh mục/Định biên"
      },
      {
        label: "Đơn vị hành chính",
        to: "/administrativeunit",
        bredcrumb: "HRM/Danh mục/Đơn vị hành chính"
      },
      {
        label: "Tôn giáo",
        to: "/religion",
        bredcrumb: "HRM/Danh mục/Tôn giáo"
      },

      {

        label: "Quản lý Dân tộc",
        to: "/ethnic",
        bredcrumb: "HRM/Danh mục/Dân tộc"
      },
      {
        label: "KPIs quy đổi",
        to: "/KPIs",
        bredcrumb: "HRM/Danh mục/KPIs quy đổi"
      },
      {
        label: "Trường đào tạo",
        to: "/school",
        bredcrumb:"HRM/Danh mục/Trường đào tạo"
      }
      ,
      {
        label: "Ngành đạo tạo",
        to: "/specialized",
        bredcrumb:"HRM/Danh mục/Ngành đào tạo"
      }
      ,
      {
        label: "Khối - P.Ban - Chứ danh",
        to: "/organization",
        bredcrumb: "HRM/Danh mục/Khối - P.Ban - Chứ danh"
      },
      {
        label: "Quản lý ngày nghỉ lễ",
        to: "/holiday",
        bredcrumb: "HRM/Danh mục/Quản lý ngày nghỉ lễ"
      },
      {
        label: "Khen thưởng",
        to: "/achievement",
        bredcrumb: "HRM/Danh mục/Khen thưởng"
      }
    ]
  }
];

export const AdminNav = [{
  // icon: "pe-7s-note2",
  icon: "lnr-users",
  label: "Quản lý nhân sự",
  to: "/employees",
  bredcrumb: "HRM/Quản lý Nhân sự",
  content: [

  ]
}, {
  icon: "pe-7s-id",
  label: "Quy hoạch cán bộ",
  to: "/zonings",
  bredcrumb: "HRM/Quy hoạch cán bộ",
  content: [

  ]
}]

export const Administration = [{
  icon: "pe-7s-note2",
  label: "Quản Trị",
  content: [{
      // icon: "pe-7s-note2",
      icon: "lnr-users",
      label: "Phân quyền cây tổ chức",
      to: "/organization-decentralization",
      bredcrumb: "HRM/Quản Trị/phân quyền cây tổ chức",
      content: [

      ]
    }

  ]
}]

export const Parameters = [{
  icon: "pe-7s-note2",
  label: "Tham số lương",
  content: [{
      // icon: "pe-7s-note2",
      icon: "lnr-users",
      label: "Chấm công",
      to: "/timekeeper",
      bredcrumb: "HRM/Tham số/chấm công",
      content: [

      ]
    },
    {
      icon: "lnr-users",
      label: "Báo cáo ngày nghỉ CBNV",
      to: "/report-day-off",
      bredcrumb: "HRM/Tham số lương/Báo cáo ngày nghỉ CBNV",
      content: [

      ]
    }
  ]
}]


export const Report = [{
  icon: "pe-7s-note2",
  label: "Báo cáo",
  content: [{
      // icon: "pe-7s-note2",
      icon: "lnr-users",
      label: "Báo cáo thời gian thực hiện",
      to: "/processed-time",
      bredcrumb: "HRM/báo cáo/Thời gian thực hiện",
      content: [

      ]
    },
    {
      // icon: "pe-7s-note2",
      icon: "lnr-users",
      label: "Báo cáo tổng hợp PGV",
      to: "/report-pgv",
      bredcrumb: "HRM/báo cáo/Tổng hợp phiếu giao việc",
      content: [

      ]
    },
    {
      // icon: "pe-7s-note2",
      icon: "lnr-users",
      label: "Trạng thái hồ sơ",
      to: "/report-status-app",
      bredcrumb:"HRM/báo cáo/Tổng hợp trạng thái hồ sơ",
      content: [

      ]
    },
    {
      icon: "",
      label: "Báo cáo Tiến độ công việc",
      to: "/report-application-progress",
      bredcrumb: "HRM/Báo cáo/Tiến độ công việc",
      content: [
      ]
    },
    {
      // icon: "pe-7s-note2",
      icon: "lnr-users",
      label: "Báo cáo tổng hợp tiến độ",
      to: "/report-aggregate",
      bredcrumb:"HRM/báo cáo/Báo cáo tổng hợp tiến độ công việc",
      content: [
           
      ]
    },
    {
      // icon: "pe-7s-note2",
      icon: "lnr-users",
      label: "Báo cáo tổng hợp tiến độ theo trạng thái hoàn thành",
      to: "/report-aggregate-complete",
      bredcrumb:"HRM/báo cáo/Báo cáo tổng hợp tiến độ theo trạng thái hoàn thành",
      content: [
           
      ]
    },
    {
      icon: "lnr-users",
      label: "Báo cáo tỷ trọng",
      to: "/report-density",
      bredcrumb:"HRM/Báo cáo/Tỷ trọng",
      content: []
    },
    {
      // icon: "pe-7s-note2",
      icon: "lnr-users",
      label: "BC T.Kê HSHT trong kỳ",
      to: "/kpis-app-complete",
      bredcrumb:"HRM/báo cáo/Báo cáo tiến độ công việc",
      content: [
           
      ]
    },
    {
      // icon: "pe-7s-note2",
      icon: "lnr-users",
      label: "KPIs",
      to: "/kpis-app-complete-2",
      bredcrumb:"HRM/báo cáo/KPIs",
      content: [
           
      ]
    }

  ]
}]
