import * as Types from '../config/constaints'

export const GetApplicationSearchRequest = (data) => {
    return {
        type: Types.GET_APPLICATION_SEARCH_REQUEST,
        payload: data
    }
}

export const GetApplicationSearchSuccess = (data) => {
    return {
        type: Types.GET_APPLICATION_SEARCH_SUCCESS,
        payload: data
    }
}