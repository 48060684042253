
import * as Types from '../config/constaints'

export const GetProccessedTimeByStageRequest = (data)=>{
    return { 
        type: Types.GET_PROCCESSED_TIME_BY_STAGE_REQUEST,
        payload: data
    }
}
export const GetProccessedTimeByStageSuccess = (data)=>{
    return {
        type: Types.GET_PROCCESSED_TIME_BY_STAGE_SUCCESS,
        payload:data
    }
}

export const GetReportPGVRequest = (data)=>{
    return { 
        type: Types.GET_REPORT_PGV_REQUEST,
        payload: data
    }
}
export const GetReportPGVSuccess = (data)=>{
    return {
        type: Types.GET_REPORT_PGV_SUCCESS,
        payload:data
    }
}
export const GetApplicationProgressTree = (data) => {
    return {
        type: Types.GET_APPLICATION_PROGRESS_TREE,
        payload: data
    }
}
export const SetApplicationProgressTree = data => ({
    type: Types.SET_APPLICATION_PROGRESS_TREE,
    payload: data
})

export const GetApplicationProgressData = data => ({
    type: Types.GET_APPLICATION_PROGRESS_DATA,
    payload: data
})
export const SetApplicationProgressData = data => ({
    type: Types.SET_APPLICATION_PROGRESS_DATA,
    payload: data
})
export const GetDensityReportData = data =>({
    type: Types.GET_DENSITY_REPORT_DATA,
    payload: data
})
export const SetDensityReportData = data => ({
    type: Types.SET_DENSITY_REPORT_DATA,
    payload: data
})