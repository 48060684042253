import { call, join, put, takeEvery } from 'redux-saga/effects';
import {  InforSuccess,
    FormWithDaJsonSuccess,
    GetTreeApplicationSuccess,
    GetListEmployeeChangeDeadlineSuccess,
    GetListApproveChangeDeadlineSuccess,
    FormWithDaJsonPrintSuccess
 } from '../../Action/detail';
import * as Type from '../../config/constaints';
import { DELETE_APPLICATION,
    TREE_APPLICATION,
    APPLICATION_DETAIL,
    FROM_WITH_DATAJSON,
    GET_LIST_EMPLOYEE_CHANGE_DEADLINE ,
    INSERT_LIST_EMPLOYEE_CHANGE_DEADLINE,
    GET_LIST_APPROVE_CHANGE_DEADLINE
    } from '../../config/constainUrl';
import { apiCall } from '../../API/index';
import { UrlApplicationDetail,UrFormWithDataJson } from '../../Service/ApplicationDetail/index'
import { toast } from 'react-toastify';
import { PRODUCT_ON_LEAVE } from '../../config/constaints'

function* GetApplicationDetail(action){
    try{ 
        const responseInfo = yield call(apiCall,"GET",`${APPLICATION_DETAIL}/${action.payload}`); 
        const responseFormDataJson = yield call(apiCall,"GET",`${FROM_WITH_DATAJSON}/${action.payload}`);
        if(responseInfo.code===200)
             yield put(InforSuccess(responseInfo));  
        if(responseFormDataJson.code===200)
            {
                if(PRODUCT_ON_LEAVE ===responseInfo.data.productId) // trường hợp đặt biệt đối với nghỉ phép
                {
                    var formApps = JSON.parse(responseInfo.data.forms);
                    if(formApps){
                        var formLogs =responseFormDataJson.data.forms;
                        const finalArr = formLogs.filter(({ id }) => formApps.some(exclude => exclude.Id === id));
                        responseFormDataJson.data.forms =finalArr;
                    }
                    else if (responseInfo.data.historys[0].forms.length > 0){
                        var formLogs =responseFormDataJson.data.forms;
                        const finalArr = formLogs.filter(({ id }) =>
                         responseInfo.data.historys[0].forms.some(exclude => exclude.id === id));
                        responseFormDataJson.data.forms =finalArr;
                    }
                }
                yield put(FormWithDaJsonSuccess(responseFormDataJson));  
            }
    }
    catch(e){
    }
}

function* GetFormWithDataJson(action){
    try{ 
        const response = yield call(UrFormWithDataJson,action.payload); 
        if(response.code===200)
             yield put(FormWithDaJsonPrintSuccess(response));  
    }
    catch(e){
    }
}

function* DeleteApplication(action){
    try{ 
        const response = yield call(apiCall,"GET",`${DELETE_APPLICATION}/${action.payload.applicationId}/${action.payload.productId}`); 
        if(response.code===200){
            toast.success(`${response.message}`, { theme: "colored" });
            window.location = `/inbox`;
        }   
    }
    catch(e){}
}


function* GetTreeApplication(action){
    try{ 
        const response = yield call(apiCall,"GET",`${TREE_APPLICATION}/${action.payload}`); 
        if(response.code===200) 
            yield put(GetTreeApplicationSuccess(response));  
    }
    catch(e){}
}

function* GetListEmployeeChangeDeadline(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_LIST_EMPLOYEE_CHANGE_DEADLINE}/${action.payload}`); 
        if(response.code===200) 
            yield put(GetListEmployeeChangeDeadlineSuccess(response));  
    }
    catch(e){}
}

function* InsertListEmployeeChangeDeadline(action){
    try{ 
        const response = yield call(apiCall,"POST",INSERT_LIST_EMPLOYEE_CHANGE_DEADLINE,action.payload); 
        if(response.code===200) {
            toast.success(`${response.message}`, { theme: "colored" });
        }
            
    }
    catch(e){}
}

function* GetListApproveChangeDeadline(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_LIST_APPROVE_CHANGE_DEADLINE}/${action.payload.applicationId}/${action.payload.applicationlogId}`,action.payload); 
        if(response.code===200){
            yield put(GetListApproveChangeDeadlineSuccess(response)); 
        } 
          
    }
    catch(e){}
}

export default function* ApplicationDetail(){
    yield takeEvery(Type.DETAIL_REQUEST, GetApplicationDetail);
    yield takeEvery(Type.DELETE_APPLICATION_REQUEST, DeleteApplication);
    yield takeEvery(Type.TREE_APPLICATION_REQUEST, GetTreeApplication);
    yield takeEvery(Type.GET_LIST_EMPLOYEE_CHANGE_DEADLINE_REQUEST, GetListEmployeeChangeDeadline);
    yield takeEvery(Type.INSERT_LIST_EMPLOYEE_CHANGE_DEADLINE, InsertListEmployeeChangeDeadline);
    yield takeEvery(Type.GET_LIST_APPROVE_CHANGE_DEADLINE_REQUEST, GetListApproveChangeDeadline);
    yield takeEvery(Type.FORM_DATAJSON_REQUEST, GetFormWithDataJson);

    
    
}