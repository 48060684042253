import * as Types from "../../config/constaints";
import {
  ComputeAccountPermission,
  ComputePageButtonPermission
} from "../../Service/Permission/permission";

// import {
//   setAccountPermission
// } from '../../Service/Permission/index';

const initialState = {
  groupPermission: {
    admin: false,
    adminQLNS: false,
    resetPassword: false,
    latchTimeKeeperPermission: false    
  },
  buttons: {
    view: false,
    edit: false,
    add: false,
    viewDetail: false
  }
}

export default function Permission(state = initialState, action) {
  switch (action.type) {
    // case Types.GET_ACCOUNT_PERMISSION_SUCCESS: {
    //   const permissions = setAccountPermission(action.payload);
    //   return {
    //     ...state,
    //     permissions: permissions
    //   }
    // }
    case Types.SET_ACCOUNT_PERMISSION: {
      const groupPermissions = ComputeAccountPermission(action.payload);
      return {
        ...state,
        groupPermission: {...groupPermissions}
      }
    }
    case Types.SET_PAGE_BUTTON_PERMISSION: {
      const buttonPermissions = ComputePageButtonPermission(action.payload);
      return {
        ...state,
        buttons: {...buttonPermissions}
      }
    }
    default:
      return {...state}
  }
}