
import { call, put, takeEvery } from 'redux-saga/effects';
import {  AddSuccess,DataSelectAdd,AddCVOnlineSuccess } from '../../Action/create';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';
import { getValueCrypt } from "../../utils/crypt";

function* DataSelect(action){

    try{ 
        const response = yield call(apiCall,"GET","stage/get-product-by-user",null,getValueCrypt('JWT_TOKEN')); 
        if(response.code===200)
             yield put(DataSelectAdd(response));  
    }
    catch(e){
    }
}

function* AddApplication(action){
    try{
        const response = yield call(apiCall,"POST","application/create-application",action.payload); 
        if(response.code===200){
            response.data =action.payload.applicationId;
            yield put(AddSuccess(response));
        }
    }
    catch(e){
      
    }
}
function* AddCVOnlineApplication(action){
    try{
        const response = yield call(apiCall,"POST","application/create-application-cv-online",action.payload); 
        if(response.code===200){
            response.data =action.payload.applicationId;
            yield put(AddCVOnlineSuccess(response));
        }
       
    }
    catch(e){
    }
}

function* AddDMSApplication(action){
    try{
        const response = yield call(apiCall,"POST","ApplicationDMS/create-application-dms-hrm",action.payload); 
        if(response.code===200){
            response.data =action.payload.applicationId;
            yield put(AddCVOnlineSuccess(response));
        }
    }
    catch(e){
    }
}
export default function* Create(){
    yield takeEvery(Type.ADD_SELECT_REQUEST, DataSelect);
    yield takeEvery(Type.ADD_REQUEST, AddApplication);
    yield takeEvery(Type.ADD_CV_ONLINE_REQUEST, AddCVOnlineApplication);
    yield takeEvery(Type.ADD_APP_DMS_SUCCESS, AddDMSApplication);
}