import {
  apiCall
} from '../../API/index';
import {
  APPLICATION_DETAIL,
  FROM_WITH_DATAJSON,
  INSERT_OR_UPDATE_USER_DISCUSS,
  UPDATE_PROFILE,
  DATA_JSON_LOG,
  ALLOW_CREATE_NEW_USER,
  GET_FILE_ID_IN_LOG,
  CHECK_USER_APPROVE_CHANGE_DEADLINE,
  GET_HISTORY_CHANGE_DEADLINE,
  UPDATE_USER_CHANGE_DEADLINE,
  SAVE_APPLICATION_CANCEL,
  GET_FORMS_IN_LOG
} from '../../config/constainUrl'
import {
  STAGE_CREATE_APPLICATION_DISCUSS
} from '../../config/constaints'

export const UrlApplicationDetail = (data) => {
  return apiCall("GET", `${APPLICATION_DETAIL}/${data}`)
}

export const UrFormWithDataJson = (data) => {
  return apiCall("GET", `${FROM_WITH_DATAJSON}/${data}`)
}
export const GetDataJsonLog = async (appId, logId) => {
  return await apiCall("GET", `${DATA_JSON_LOG}/${appId}/${logId}`);
}
export const GetFormsInLog = async (appId, logId) => {  
  return await apiCall("GET", `${GET_FORMS_IN_LOG}/${appId}/${logId}`);
}


export const insertOrUpdateSUerDiscuss = (stringData, model) => {
  const {
    id,
    lastLogId,
    stageId
  } = model
  const isCreateApplication = STAGE_CREATE_APPLICATION_DISCUSS.some(p => p === stageId);
  // let param =
  return apiCall("POST", INSERT_OR_UPDATE_USER_DISCUSS, {
    appId: id,
    formId: stringData.form.id,
    lastLogId: lastLogId,
    finishDateDiscuss: stringData.discuss.finishDateDiscuss,
    Userdiscusses: stringData.discuss.userDiscusses,
    stageId: stageId,
    isCreateApplication: isCreateApplication
  })
}

export const updateProfile = async (appId, parentId, logId, productId, userExecute, files) => {
  parentId = parentId ? parentId : '00000000-0000-0000-0000-000000000000'
  var param = {
    appId,
    parentId,
    logId,
    productId,
    userExecute,
    files: files
  }
  return await apiCall("POST", `${UPDATE_PROFILE}`, param);
}

export const allowCreateNewUser = async (appId) => {
  return await apiCall("PUT", `${ALLOW_CREATE_NEW_USER}/${appId}`);
}
// check file Require: trước khi kết thúc hồ sơ
export const checkFileRequire = async (logId, fileRequired) => {
  let isValid = true;
  await apiCall("GET", `${GET_FILE_ID_IN_LOG}/${logId}`).then(e => {
    if (e.code === 200) {
      fileRequired.forEach(file => {
        if (!e.data.includes(file.id)) isValid = false;
      });
    } else {
      isValid = false;
    }
  })
  return isValid;
}

export const checkUseApproveChangeDeadline = (data) => {
  return apiCall("GET", `${CHECK_USER_APPROVE_CHANGE_DEADLINE}/${data.applicationId}/${data.applicationlogId}`)
}

export const getHistoryChangeDeadline = (data) => {
  return apiCall("GET", `${GET_HISTORY_CHANGE_DEADLINE}/${data.appId}`)
}

export const updateUserChangeDeadline =(data)=>{
  return apiCall("POST", `${UPDATE_USER_CHANGE_DEADLINE}`,data)  
}

// CANCEL APPLICATION
export const saveApplicationCancel = (data) => {
  return apiCall("POST", `${SAVE_APPLICATION_CANCEL}`, data);
}