import * as Types from "../../config/constaints";

const  initialState= {
  data: [],
  total: 0,
  isError: false,
  messageError: null
  };

  export default function School (state =initialState,action ){
    
    // eslint-disable-next-line default-case
    switch (action.type) { 
          case Types.GET_SCHOOL_SUCCESS:{ 
                if(action.payload.code !== 200)
                  return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                else {
                  return  {...state,data:  action.payload.data.data,total:action.payload.data.total  };
                }
          }
         
          default:
            return state;
  }
}

