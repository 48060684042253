import { all } from "redux-saga/effects";
import Account from "./Account/index";
import Create from "./Create/index";
import Inbox from "./Inbox/index";
import ApplicationDetail from "./ApplicationDetail/index";
import DiscussInbox from "./Discuss-Inbox/index";
import TotalHandling from "./Total-Handling/index"
import User from "./User"
import Comment from "./Comment"
import UserCcDefault  from "./UserCcDefault/index";
import EmployeeProfile from "./EmployeeProfile/index";
import GetEmployeeCV from "./EmployeeCV/index";
import TreeOrg from "./TreeOrg/index";
import Salary from "./Category/salary";
import Administrativeunit from "./Category/administrativeunit";
import Country from "./Category/country";
import Religion from "./Category/religion";
import KPIs from "./Category/kpis";
import Specialized from "./Category/specialized";
import Organization from "./Category/organization";
import School from "./Category/shool";
import Holiday from "./Category/Hoiday";
import Timekepper from "./Timekeeper/index";
import Achievement from "./Category/achievement";
import Report from "./Report/index";
import DMS from "./DMS/index";
import DeadlineInbox from "./Deadline-Inbox/index";
import ApplciationAssignment from "./AssignInbox/index";
import ApplicationCancel from './ApplicationCancel/index'
import Permission from "./Permission";
import ApplicationSearch from './ApplicationSearch'

function* rootSaga() {
  yield all([
    Account(),
    Create(),
    Inbox(),
    ApplicationDetail(),
    DiscussInbox(),
    TotalHandling(),
    User(),
    Comment(),
    UserCcDefault(),
    EmployeeProfile(),
    GetEmployeeCV(),
    TreeOrg(),
    Salary(),
    Administrativeunit(),
    Country(),
    Religion(),
    KPIs(),
    Specialized(),
    Organization(),
    School(),
    Holiday(),
    Timekepper(),
    Achievement(),
    Report(),
    DMS(),
    DeadlineInbox(),
    ApplciationAssignment(),
    ApplicationCancel(),
    Permission(),
    ApplicationSearch()
  ]);
}

export default rootSaga;
