import * as Types from '../../config/constaints';

var initialState = {
    commentData: [],
    isShow: false,
    isLoading: true,
};

const Comment = (state = initialState, action) => {
  
   
    switch (action.type) {
        
            case Types.GET_COMMTENT_SUCCESS:
                var lstdata = []
                if(action.payload.data.length > 0)
                {
                    var roots = action.payload.data.filter(function(currentValue, index) {
                        return currentValue.parent ==null
                    })
                    roots.map((v,e)=>{
                        Object.assign(v, {childs: []});
                        Object.assign(v, {temParent: null});
                
                    })
                    handleData(roots,action.payload.data,lstdata)
                    action.payload.data =roots
                }
                return {
                    ...state,
                    isLoading: false,
                    commentData: action.payload.data,
                };
            case Types.VOTE_COMMENT_SUCCESS:
                var lstdata = []
                if(action.payload.data.length > 0)
                {
                    var roots = action.payload.data.filter(function(currentValue, index) {
                        return currentValue.parent ==null
                    })
                    roots.map((v,e)=>{
                        Object.assign(v, {childs: []});
                        Object.assign(v, {temParent: null});
                
                    })
                    handleData(roots,action.payload.data,lstdata)
                    action.payload.data =roots
                }
        
                    return {
                        ...state,
                        isLoading: false,
                        commentData: action.payload.data,
                        };
            case Types.GET_COMMTENT_FAILURE:
                return {
                    ...state,
                    isLoading: false,
                };
            case Types.UPDATE_COMMENT_SUCCESS:
                
                let stateNew =[...state.commentData]
                    for (let index = 0; index < stateNew.length; index++) {
                        if(stateNew[index].id ==action.payload.id){
                            stateNew[index].content =action.payload.content;
                        }     
                    }
                    return {
                        ...state, commentData: stateNew,
                        isLoading: false,
                    };
                
            case Types.DELETE_COMMENT_SUCCESS:
            
            return  {
                ...state,commentData: state.commentData.filter(element => element.id !== action.payload),
                isLoading: false,
                
            } ;
        default: 
            return state;
    }
};


const handleData =(roots, datas, result)=>{
    roots.map((value,e)=>{
        value.created = new Date(value.created)
        var items = datas.filter(function(currentValue, index) {
            return currentValue.parent ==value.id
        })

        if(items.length > 0 )
        {
            value.childs = items;
            mapChilds(items,datas,value )
          
        }
      

    })

}


const mapChilds =(childs,datas, root) =>{

    childs.map((value,e)=>{
        value.created = new Date(value.created)
        var items = datas.filter(function(currentValue, index) {
            return currentValue.parent ===value.id
        })
        if(items.length > 0 )
        {
            root.childs=  root.childs.concat(items);
            mapChilds(items,datas,root )
          
        }
    })
}

export default Comment;