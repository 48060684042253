import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { getValueCrypt } from "../../../utils/crypt"

const history = createBrowserHistory();



 const PrivateRoute = ({ component: Component, ...rest }) => (
   
    <Route {...rest} render={props => ( 
        getValueCrypt('JWT_TOKEN')
            ? <Component {...props} />
            : <Redirect to= '/login' />
    )} />
)

export default PrivateRoute;