
import axios from "axios";
import { DOWNLOAD_FILE_DETAIL } from '../../config/constainUrl';
import { HOST_API } from '../../config/constaints';
import { encrypt,getValueCrypt } from '../../utils/crypt'

function login(data) {
  return axios({
    url: `${HOST_API}/account/login`,
    method: 'POST',
    data: data
  });
}

function getJwtToken() {
  return getValueCrypt(this.JWT_TOKEN);
}

function logout() {
  this.removeStorage();
}

function storeInfo(res) {
  localStorage.setItem("JWT_TOKEN",  encrypt(res.data.token));
  localStorage.setItem("USER_INFO", encrypt(JSON.stringify(res.data.userInfo)));
  localStorage.setItem("ROLE",encrypt(res.data.role));
  const NavItems = getNavItems(res.data.role);
  localStorage.setItem("NAV_ITEMS", encrypt(JSON.stringify(NavItems)));
}

function removeStorage() {
  localStorage.removeItem("JWT_TOKEN");
  localStorage.removeItem("USER_INFO");
  localStorage.removeItem("ROLE");
  localStorage.removeItem("NAV_ITEMS");
}

function getNavItems(role) {
  const roles = JSON.parse(role);
  const { positions, employees } = roles;
  // positions
  const permission_positions = JSON.parse(positions)
    .filter(p => p.Menu.length > 0);
  let _menus = permission_positions.map(p => p.Menu);

  // employees.
  let permission_employees = [JSON.parse(employees)]
    .filter(p => p.Menu.length > 0);
  if (permission_employees.length) {
    _menus = [..._menus, ...permission_employees.map(p => p.Menu)];
  }

  let _mns = [];
  _menus.forEach(mn => {
    mn.forEach(m => {
      _mns = [..._mns, m]
    });
  });

  // unionMenus
  const menus = UnionMenus(_mns);
  let navItems = []
  menus.forEach(menu => {
    const navItem = createNav(menu);
    navItems.push(navItem);
  });
  return navItems;
}

function UnionMenus(menus) {
  // get parent menu
  let results = Array.from(new Set(menus.map(menu => menu.Id)))
    .map(Id => menus.find(menus => menus.Id === Id));

  // get children menus
  let childrenMunes = new Array();
  menus.forEach(menu => {
    childrenMunes = [...childrenMunes, ...menu.Childrend];
  });

  // menus is permission.
  let distinctIsPermissions = new Array();
  let childIsPermissions = childrenMunes.filter(child => child.IsPermission);
  childIsPermissions.forEach(child => {
    // union buttons.
    UnionButtons(distinctIsPermissions, child, childIsPermissions);
  });

  // distinct menus is not permission.
  let childIsNotPermissions = childrenMunes.filter(child => !child.IsPermission);
  let distinctIsNotPermissions = Array.from(new Set(childIsNotPermissions.map(child => child.Id)))
    .map(Id => childIsNotPermissions.find(child => child.Id === Id));

  // results
  results.forEach(parent => {
    let permissions = distinctIsPermissions.filter(child => child.ParentId === parent.Id);
    let idPermissions = permissions.map(p => p.Id);
    let notPermissions = distinctIsNotPermissions.filter(child => child.ParentId === parent.Id && !idPermissions.includes(child.Id));
    let childs = [...permissions, ...notPermissions];
    parent.Childrend = childs;
  });
  return results;
}
function UnionButtons(results, child, childIsPermissions) {
  if (!results.length || !results.some(rs => rs.Id === child.Id)) {
    let pages = childIsPermissions.filter(p => p.Id === child.Id);
    let buttons = new Array();
    pages.forEach(page => {
      buttons = [...buttons, ...page.Buttons];
    });
    child.Buttons.forEach(button => {
      button.IsPermission = buttons.some(btn => btn.Id === button.Id && btn.IsPermission);
    });
    results.push(child);
  }
}
function createNav(parentMenu) {
  const navItem = {
    icon: parentMenu.Icon,
    label: parentMenu.Name,
    to: parentMenu.Route,
    bredcrumb: parentMenu.ModuleId,
    content: []
  };
  parentMenu.Childrend.forEach(childMenu => {
    navItem.content.push(createNav(childMenu));
  });
  return navItem;
}
export const AuthenticationService = {
  getJwtToken,
  logout,
  storeInfo,
  removeStorage,
  login
};