import { createStore, compose,applyMiddleware} from "redux";
import reducers from "../reducers";
import  rootSaga from '../Saga/index'
import createSagaMiddleware from 'redux-saga';

// const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers =
//   typeof window === 'object' &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
// const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
// const Store = createStore(reducers, enhancer);
// sagaMiddleware.run(Saga);  // Saga phai chay sau  Middleware

const composeEnhancers =
process.env.NODE_ENV !== 'production' &&
typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: false,
    })
  : compose;

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
const middlewares = [sagaMiddleware];
const enhancers = [applyMiddleware(...middlewares)];
const store = createStore(reducers, composeEnhancers(...enhancers));
sagaMiddleware.run(rootSaga);
return store;
};



export default configureStore;
