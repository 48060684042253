import ThemeOptions from "./ThemeOptions";
import Account from "./Account/Account";
import Create from "./Create/Create";
import Inbox from "./Inbox/Inbox";
import DiscussInbox from "./Disscuss-Inbox/disscuss-Inbox"
import ApplicationDetail from "./Detail/ApplicationDetail";
import FormWithDataJson from "./Detail/FormDetail";
import { combineReducers } from "redux";
import TotalHandling from "./Total-Handling/Total-handling";
import User from "./User";
import Comment from "./Comment/Comment";
import UserCcDefault from "./UserCcDefault/index";
import EmployeeProfile from "./EmployeeProfile/EmployeeProfile";
import EmployeeFormDataJson from "./EmployeeProfile/EmployeeFormDataJson";
import EmployeeCV from "./EmployeeCV/EmployeeCV";
import Buttons from "./Buttons"
import TreeOrg from "./TreeOrg/index";
import Salary from "./Category/Salary";
import Administrativeunit from "./Category/Administrativeunit";
import Country from "./Category/Country";
import Religion from "./Category/Religion";
import KPIs from "./Category/KPIs";
import Specialized from "./Category/Specialized";
import Organization from "./Category/Organization";
import School from "./Category/School";
import Holiday from "./Category/Holiday";
import Timekeeper from "./TimeKeeper/Timekeeper";
import Loading from "./Loading/loading";
import Achievement from "./Category/Achievement";
import Report from "./Report/Report";
import AppDMS from "./DMS/DMS";
import AppListChangeDeadline from "./AppListChangeDeadline/AppListChangeDeadline";
import DeadlineInbox from "./Deadline-Inbox/deadline-inbox";
import InboxAssign from "./Assign-Inbox/Assign-Inbox";
import {ApplicationCancelReducer as ApplicationCancel} from "./ApplicationCancelReducer/ApplicationCancelReducer";
import Permission from "./Permission/Permission";
import ApplicationSearch from './ApplicationSearch/ApplicationSearch'
import NotificationComment from './Comment/NotificationComment'

export default combineReducers ({
  ThemeOptions,
  Account,
  Create,
  Inbox,
  ApplicationDetail,
  FormWithDataJson,
  DiscussInbox,
  TotalHandling,
  User,
  Comment,
  UserCcDefault,
  EmployeeProfile,
  EmployeeFormDataJson,
  EmployeeCV,
  Buttons,
  TreeOrg,
  Salary,
  Administrativeunit,
  Country,
  Religion,
  KPIs,
  Specialized,
  Organization,
  School,
  Holiday,
  Timekeeper,
  Loading,
  Achievement,
  Report,
  AppDMS,
  AppListChangeDeadline,
  DeadlineInbox,
  InboxAssign,
  ApplicationCancel,
  Permission,
  ApplicationSearch,
  NotificationComment
});
