import * as Types from '../config/constaints'


export const GetApplicationDMSRequest = (data)=>{
 
    return { 
        type: Types.GET_APPLICATION_DMS_REQUEST,
        payload:data
    }
}

export const GetApplicationDMSSuccess = (data)=>{
    return {
        type: Types.GET_APPLICATION_DMS_SUCCESS,
        payload:data
    }
}

export const SendBackDMSRequest = (data)=>{
    return {
        type: Types.GET_SEND_BACK_DMS_REQUEST,
        payload:{
            c3: data.c3,
            note:data.note,
            isFiiter: data.isFiiter
        }
    }
}