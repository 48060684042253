// File
export const ADD_FILE_DETAIL ="file/upload-file";
export const GET_FILE_DETAIL ="file/get-file-app";
export const GET_FILE_REQUIRE = "file/get-file-require";
export const DOWNLOAD_FILE_DETAIL ="file/dowload-file";
export const DELETE_FILE ="file/delete-file";
export const DELETE_FILE_REQUIRE ="file/delete-file-require";
export const SECRET_FILE ="file/secret-file";
export const CHECK_FILES_REQUIRED_EXISTED = "file/check-list-file-require-uploaded";

// LUỒNG HỒ SƠ.
export const FLOW_GET_APPLICATION_INFORMATION = "application/flow-get-application-information"
export const FLOW_GET_USERS ="employee/flow-get-users";
export const FLOW_GET_DATA_APPLICATION_DRAFT_LOGS="applicationlog/flow-get-data-application-draft-logs"
export const FLOW_SAVE="applicationlog/flow-save"
export const FLOW_TRANSFER="applicationlog/flow-transfer"
export const FLOW_ROLLBACK="applicationlog/flow-rollback"
export const FLOW_COMPLETED="application/flow-completed"
export const FLOW_COMPLETED_PRODUCT="application/flow-completed-product"
export const FLOW_SUPPORT="applicationlog/flow-support"
export const FLOW_DELETE="applicationlog/flow-delete"
export const FLOW_PRINT="applicationlog/flow-print"
export const FLOW_GET_STAGES="stage/get-next-stages"
export const IFRAME_VIEW_FILE ="fileview/index";
export const IFRAME_VIEW_FILE_EMPLOYEE ="fileview/ViewFileEmployee";
export const FLOW_GET_APPLICATIONLOG ="applicationlog/flow-get-applicationlog";
export const FLOW_SAVE_FLOW_INFORMATION ="flowinformation/save-flow-information";
export const FLOW_GET_RAW_NEXT_STAGE ="stage/flow-get-raw-next-stages";
export const FLOW_GET_STAGES_CREATE_APPLICATION_DISCUSS ="stage/flow-get-stages-create-application-discuss";
export const FLOW_CREATE_APPLICATION_CHILDREN="application/flow-create-application-children"
export const FLOW_FINISH_APPLICATION = "application/flow-finish-application-not-complete";
export const GET_STAGE_CURRENT_USER ="stage/get-stages-current";

// Inbox
export const INBOX_FOLLOW ="application/application-follow";

//Detail
export const APPLICATION_DETAIL ="application/get-application-detail";
export const FROM_WITH_DATAJSON ="application/get-form-with-dataJson";
export const INSERT_OR_UPDATE_USER_DISCUSS ="UserDiscuss/insert-or-update-user-discuss";
export const UPDATE_PROFILE = "application/update-profile";
export const ALLOW_CREATE_NEW_USER = "application/allow-create-new-user";
export const GET_FILE_ID_IN_LOG = "ApplicationLog/get-file-id-in-log";
export const GET_FORMS_IN_LOG = "ApplicationLog/get-forms-in-log";
export const DELETE_APPLICATION= "application/delete-application";
export const TREE_APPLICATION ="application/tree-application";
// DiscussInbox
export const APPLICATION_USER_DISCUSS ="UserDiscuss/application-user-discuss";
export const DATA_JSON_LOG ="Application/get-data-json-log";
export const FORM_DISCUSS ="UserDiscuss/get-form-discuss";
export const UPDATE_USER_DISCUSS_WITH_USER ="UserDiscuss/update-user-discuss-with-user";
export const DISCUSS_CREATE_APPLICATION ="UserDiscuss/discuss-create-applicaiton";
// Toal-handlibg
export const GET_TOTAL_HANDLING ="Application/total-handling";


// Org and Employee
export const GET_ORG_TREE = "OrgTree";
export const GET_ORG_TREE_OLD = "OrgTree/get-org-tree-old";
export const GET_DEPARTMENT_FULL_BRANCH_GRALDEL = "Department/GetdepartmentFullBranchGraldel";
export const GET_ORG_TREE_OLD_REPORT = "OrgTree/get-org-tree-report";
export const GET_GRADELLEVEL = "GradelLevel";
export const GET_DEPARTMENT_BY_GRADEL = "Department/get-by-gradel";
export const GET_POSITION_BY_GRADEL_AND_DEPARTMENT = "Position/get-by-gradel-and-department";
export const ORG_GET_EMPLOYEES = "Employee/org-get-employees";
// Comment
export const GET_COMMENT ="Comment/get-comment";
export const SAVE_COMMENT ="Comment/save-comment";
export const VOTE_COMMENT ="Comment/vote-comment";
export const DELETE_COMMENT ="Comment/delete-comment";

//UserCcDefault
export const GET_PRODUCT ="UserCCDefaults/get-product";
export const GET_USER_CC_DEFAULT ="UserCCDefaults/get-user-ccDefaults";
export const INSERT_OR_UPDATE_USER_CC_DEFAULT ="UserCCDefaults/insert-or-update-user-ccDefaults";

// EmployeeProfile
export const GET_EMPLOYEE_INDIVIDUAL ="Employee/get-infor-individual-employee";
export const GET_EMPLOYEE_WORKPROGRESS ="Employee/get-infor-workprogress-employee";
export const GET_EMPLOYEE_CONTRACT="Employee/get-infor-Contract-employee";
export const GET_EMPLOYEE_PARTY_UNION="Employee/get-party-union-Contract-employee";
export const GET_EMPLOYEE_DIGITIZINGRECORDS="Employee/get-digitizing-records-employee";
export const GET_EMPLOYEE_APPLICATION="Employee/get-employee-Application";
export const GET_EMPLOYEE_FORM_DATAJSON="Employee/get-employee-form-dataJson";
export const UPLOAD_PROFILE_IMAGE = "File/upload-form-file-attachment-employee";
//User
export const USER_RESETPASSWORD="Account/reset-password";
export const RESET_PASSWORD_USER="Account/reset-password-user";
//EmployeeCV
export const GET_EMPLOYEE_CV="DataSystem/get-data-cv-hrm";

// Zoning
export const GET_ZONING_DATA = "zoning/get-zoning-data";
export const GET_ZONING_TERMS = "zoning/get-zoning-terms";

// Proficiency
export const GET_PROFICIENCY_CATEGORIES = "proficiency/categories";
export const GET_PROFICIENCY_BY_ORG = "proficiency/get-by-org";
export const GET_PROFICIENCY_BY_POSITION = "proficiency/get-by-position";
export const SAVE_PROFICIENCY = 'proficiency/save';

//TreeOrg
export const INSERT_OF_DELETE_EMPLOYEE_DEPARTMENT_PERMISSION = "OrgTree/insert-of-delete-employee-department-permission";
export const GET_ORGTREE_PERMISSION="OrgTree/get-org-tree-permission"

// Thang bảng lương
export const GET_GROUP_SALARY_RANK_All = "Category/get-group-salary-rank-all";
export const GET_BY_ID_GROUP_SALARY_RANK="SalaryRank/get-by-idGroupSalaryRank";
export const GET_BY_SALARY_RANK ="SalaryLevel/get-by-salaryrank";
export const INSERT_UPDATE_GROUP_SALARY_RANK = "Category/insert-of-update-group-salary-rank";
export const DELETE_GROUP_SALARY_RANK = "Category/delete-group-salary-rank";
export const INSERT_UPDATE_SALARY_RANK = "SalaryRank/insert-of-update-salary-rank";
export const DELETE_SALARY_RANK = "SalaryRank/delete-salary-rank";
export const INSERT_UPDATE_SALARY_LEVE = "SalaryLevel/insert-of-update-salary-leve";
export const DELETE_SALARY_LEVE = "SalaryLevel/delete-salary-leve";
 


// HR-Planning
export const GET_HR_PLANNING_BY_ORG = "category/get-hr-planning-by-org";
export const UPDATE_HR_PLANNING_AMOUNT = "category/update-hr-planning-amount";
export const IMPORT_HR_PLANNING = "category/import-hr-planning";
export const EXPORT_HR_PLANNING = "category/export-hr-planning";
export const DOWNLOAD_HR_PLANNING_TEMPLATE = "category/download-hr-planning-template";
//Province
export const GET_PROVINCE_ALL = "category/get-province-all";
export const GET_DISTRICT_WITH_PROVINCE = "category/get-District-with-provinceId";
export const GET_WARDS_WITH_DISTRICT = "category/get-wards-with-distrctId";
export const GET_COUNTRY ="category/get-Country";
export const INSERT_UPDATE_PROVINCE ="category/insert-of-update-province";
export const DELETE_PROVINCE ="category/delete-province";
export const INSERT_UPDATE_DISTRICT ="category/insert-of-update-district";
export const DELETE_DISTRICT ="category/delete-district";
export const INSERT_UPDATE_WARDS ="category/insert-of-update-wards";
export const DELETE_WARDS="category/delete-wards";
// Country
export const GET_COUNTRY_PER_PAGE = "category/get-countries-per-page";
export const SAVE_COUNTRY = "category/save-country";
export const DELETE_COUNTRY = "category/delete-country";


// Religion


export const GET_RELIGION_ALL = "category/get-religion";
export const INSERT_UPDATE_RELIGION = "category/insert-of-update-religion";
export const DELETE_RELIGION = "category/delete-religion";

// Ethnic
export const GET_ETHNICS = "category/ethnics";
export const SAVE_ETHNIC = "category/save-ethnic";
export const DELETE_ETHNIC = "category/delete-ethnic";

// KPIs
export const GET_KPIS_WITH_MONTH = "category/get-kpis-with-month";
export const INSERT_UPDATE_KPIS = "category/insert-of-update-kpis";
export const DELETE_KPIS = "category/delete-kpis";

export const GET_SPECIALIZED = "category/get-specialized";
export const INSERT_UPDATE_SPECIALIZED = "category/insert-of-update-specialized";
export const DELETE_SPECIALIZED = "category/delete-specialized";

export const GET_CATEGORY_GRADELLEVEL = "category/get-gradelLevel";
export const INSERT_UPDATE_GRADELLEVEL = "category/insert-of-update-gradelLevel";
export const DELETE_GRADELLEVEL = "category/delete-specialized";

export const GET_DEPARTMENT_GRADELLEVEL_BRANCH = "Department/get-department-with-branchId-gradelLevelId";
export const INSERT_UPDATE_DEPARTMENT_GRADELLEVEL = "category/insert-of-update-department-gradelLevel";
// export const DELETE_GRADELLEVEL = "category/delete-specialized";
// export const GET_DEPARTMENT_GRADELLEVEL = "category/delete-specialized"

export const GET_POSITION_DEPARTMENT = "Position/get-position-with-Des-pos";

export const GET_POSITION_GROUP_DEPARTMENT = "Position/get-position-with-group-des";
export const INSERT_UPDATE_POSITION_DEPARTMENT = "category/insert-of-update-position-department";
// export const DELETE_GRADELLEVEL = "category/delete-specialized";
// export const GET_DEPARTMENT_GRADELLEVEL = "category/delete-specialized"
export const GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT ="category/get-department-branch-group-department"
export const INSERT_UPDATE_GROUP_DEPARTMENT = "department/insert-of-update-group-department";
export const GET_BRANCH ="Branch/get-branch";
export const INSERT_UPDATE_POSITION_BRANCH_GROUP_DEPARTMENT = "category/insert-or-update-position_branch-group-department";
//School
export const GET_SCHOOL ="Category/get-school-per-page";
export const INSERT_UPDATE_SCHOOL ="Category/insert-or-update-school";
export const DELETE_SCHOOL="Category/delete-school";
//Holiday
export const GET_HOLIDAY ="Category/get-holiday-with-year";
export const INSERT_UPDATE_HOLIDAY ="Category/insert-or-update-holiday";
export const DELETE_HOLIDAY="Category/delete-holiday";
//Timekeeper
export const GET_TIMEKEEPRE_DATA="TimeKeeper/get-data"
export const GET_TIMEKEEPRE="TimeKeeper/get-timekeeper"
export const EXPORT_TIMEKEEPRE="TimeKeeper/export-timekeeper"
export const GET_BOOL_LATCH="TimeKeeper/get-bool-latch";
export const SAVE_LATCH="TimeKeeper/save-latch";
export const GET_DAY_LATCH="TimeKeeper/get-latch-timeSheet-salary";
export const GET_PUBLIC_DAY="TimeKeeper/get-public-day";
export const SAVE_TIMESHEET_SALARY ="TimeKeeper/save-timeSheet-salary";
export const GET_HOLIDAY_WITH_MONTH_YEAR="TimeKeeper/get-holiday-with-month-year";
export const SAVE_DATE_TIMEKEEPER_WITH_ADMIN="TimeKeeper/save-data-with-admin";
export const  CHECK_PERMISSION_MANIPULATE="TimeKeeper/check-permission-manipulate";
// Working status
export const GET_WORKING_STATUS = "category/get-working-status";
// Achievement
export const GET_ACHIEVEMENT_BY_ORG = "achievement/get-achieve-by-org";
//Report
export const GET_TREE_ORG_REPORT = "Report/get-tree-org-report";
export const GET_PROCCESSED_TIME_BY_STAGES = "Report/get-proccessed-time-by-stages";
export const GET_DETAIL_PROCCESSED_TIME_BY_STAGES = "Report/get-detail-proccessed-time-by-stages";
export const GET_REPORT_PGV = "Report/get-report-pgv";
export const GET_REPORT_APP_STATUS=  "Report/get-report-application-status";
export const GET_REPORT_APP_STATUS_WITH_USER= "Report/get-report-application-status-with-user";
export const GET_REPORT_APP_AGGREGATE=  "Report/get-report-application-aggregate";
export const GET_REPORT_APP_AGGREGATE_WITH_USER= "Report/get-report-application-aggregate-with-user";
export const URL_GET_REPORT_APP_PROGRESS = "report/get-application-progress-tree";
export const URL_GET_APPLICATION_PROGRESS_DATA = "report/get-application-progress-data";
export const URL_GET_DENSITY_REPORT_DATA = "report/density";
export const URL_REPORT_KPI_APP=  "Report/get-report-kpis-app";
//DMS
export const GET_APPLICATION_DMS ="ApplicationDMS/get-application-dms";
export const CREATE_APPLICATION_DMS="create-application-dms-hrm";
export const PERMISSION_DMS = "applicationdms/permission-dms"
export const SEND_BACK_PREMISSION="applicationdms/send-back-permission-dms"
export const GET_USER_WITH_DES="applicationdms/get-user-with-des";
export const UPDATE_APPDMS_WITH_USER="applicationdms/update-user-with-des";
// Change Dealine
export const GET_LIST_EMPLOYEE_CHANGE_DEADLINE ="application/get-list-employee-deadline";
export const INSERT_LIST_EMPLOYEE_CHANGE_DEADLINE ="application/insert-list-employee-deadline"
export const CHECK_USER_APPROVE_CHANGE_DEADLINE="application/check-user-change-deadline"
export const GET_LIST_APPROVE_CHANGE_DEADLINE="application/get-list-approve-change-deadline"
export const GET_HISTORY_CHANGE_DEADLINE="application/get-history-change-deadline"
export const GET_INBOX_USER_DEADLINE="application/get-inbox-user-change-deadline"
export const UPDATE_USER_CHANGE_DEADLINE="application/update-user-change-deadline"
// App Assignment
export const INSERT_ASSIGNMENT_URL= "ApplicationAssignment/insert-assignment";
export const GET_INBOX_ASSIGNMENT_URL = "ApplicationAssignment/get-inbox-assign";
// HRM V1
export const GET_EMPLOYEE_DEPARTMENT="Employee";

// Cancel Application
export const GET_USER_APPROVE_CANCEL_APPLICATION = 'application/get-user-approve-cancel-application';
export const GET_APPLICATION_CANCEL_INFO = 'application/get-application-cancel-info';
export const SAVE_APPLICATION_CANCEL = 'application/save-application-cancel';
export const GET_APPLICATION_CANCEL_BOX = 'application/get-application-cancel-box';


// Permission
export const GET_PAGE_BUTTON_PERMISSION="permission/get-page-button-permission";
export const GET_ACCOUNT_PERMISSION = "permission/get-account-permission";

//Application Search
export const GET_APPLICATION_SEARCH = "application/get-application-search";
// Notification Comment
export const GET_NOTIFICATION_COMMENT = "Comment/get-notification-comment";
export const URL_UPDATE_NOTIFICATION_COMMENT = "Comment/update-notification-comment";

// Notification app time line
export const NOTIFICATION_APP_TIME_LINE  = "Application/notifications-time-line";
//comment-discuss
export const GET_USER_DISCUSS_WITH_USERID="UserDiscuss/get-user-discusses-with-userId"