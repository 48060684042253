
import * as Types from "../../config/constaints";
 import { updateStatusDiscuss } from "../../Service/DiscussInbox/index"


const  initialState= {
  data: {
    list: [],
      total: 0
  },
  formDiscuss:  {
    data: null,
    form:null,
    model:null,
    isError: false,
    messageError: null,
    },
  isError: false,
  messageError: null,
  };
  
  export default function DiscussInbox (state =initialState,action ){
    switch (action.type) { 
      case Types.DISCUSS_INBOX_SUCCESS:{     // Danh sach thao luan
        if(action.payload.code !== 200)
          return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
        else {
          return  {...state
            ,data: {
              list: action.payload.data.list,
              total: action.payload.data.total
          },
             };
             break;
        }  
   }
   case Types.DISCUSS_INBOX_VIEW_FROM_SUCCESS:{   
      return  {...state
        ,formDiscuss:{
          data: action.payload.data,
          form: action.payload.form,
          model:  action.payload.model,
         }
    }
   }
   case Types.DISCUSS_INBOX_UPDATE_SUCCESS:{ 
        const { list }  = state.data;
        
        var mapList = updateStatusDiscuss(list, action.payload);
        return  {...state
          ,list:mapList
          }
        }

       default:
          return state;
    }
  }

