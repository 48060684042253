
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_KPIS_WITH_MONTH,
    INSERT_UPDATE_KPIS,
    DELETE_KPIS
   } from '../../config/constainUrl';
import { GetKPIsSuccess,GetKPIsRequest} from '../../Action/category';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';

function* GetKPIs(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_KPIS_WITH_MONTH}/${action.payload}` ); 
        if(response.code===200)
             yield put(GetKPIsSuccess(response));  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* InsertOfUpdateKPIs(action){
    try{ 
        
        const response = yield call(apiCall,"POST",INSERT_UPDATE_KPIS,action.payload); 
        if(response.code===200){
            yield put(GetKPIsRequest(action.payload.MonthId));  
            toast.success(response.message, { theme: "colored" })    
        }
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* DeleteKPIs(action){
    try{       
        const response = yield call(apiCall,"DELETE",`${DELETE_KPIS}/${action.payload.id}`); 
        if(response.code===200){
            yield put(GetKPIsRequest(action.payload.monthId));  
            toast.success(response.message, { theme: "colored" })    
        }    
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

export default function* Country(){
    yield takeEvery(Type.GET_KPIS_REQUEST, GetKPIs);
    yield takeEvery(Type.INSERT_UPDATE_KPIS, InsertOfUpdateKPIs);
    yield takeEvery(Type.DELETE_KPIS, DeleteKPIs);
   
}