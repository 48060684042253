import * as Types from "../../config/constaints";


const initialState = {
  userApprove: null,
  userCreate: null,
  application: null,
  applicationCancels: [],
  totalApplicationCancels: 0,
  messageError: null
};

const ApplicationCancelReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_APPLICATION_CANCEL_INFO_SUCCESS:
      return {
        ...state,
        userApprove: action.payload.userApprove,
          userCreate: action.payload.userCreate,
          application: action.payload.application
      };    
    case Types.GET_APPLICATION_CANCEL_BOX_SUCCESS:
      return {
        ...state,
        applicationCancels: action.payload.data,
          totalApplicationCancels: action.payload.total
      };
    case Types.UPDATE_APPLICATION_CANCEL_CREATE_COMMENT:
      return {
        ...state,
        application: {
          ...state.application,
          createComment: action.payload
        }
      };
    case Types.UPDATE_APPLICATION_CANCEL_APPROVE_COMMENT:
      return {
        ...state,
        application: {
          ...state.application,
          approveComment: action.payload
        }
      };
    case Types.UPDATE_APPLICATION_CANCEL_ACCEPT:
      return {
        ...state,
        application: {
          ...state.application,
          accept: action.payload
        }
      };
      case Types.RESET_APPLICATION_CANCEL_STATE:
        return {
          ...state,
          userApprove: null,
          userCreate: null,
          application: null,
        }
    default:
      return {
        ...state
      }
  }
}

export {
  ApplicationCancelReducer
};