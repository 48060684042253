  import axios from "axios";
  import {
    DOWNLOAD_FILE_DETAIL,
    GET_FILE_DETAIL,
    GET_FILE_REQUIRE,
    ADD_FILE_DETAIL,
    DELETE_FILE,
    DELETE_FILE_REQUIRE,
    SECRET_FILE,
    SAVE_COMMENT,
    CHECK_FILES_REQUIRED_EXISTED,
    UPLOAD_PROFILE_IMAGE
  } from '../../config/constainUrl';
  import {
    HOST_API
  } from '../../config/constaints';
  import {
    apiCall
  } from '../../API/index';
  import {
    uuid
  } from 'uuidv4';
  import PDF from '../../Layout/Img/pdf.png';
  import Image from '../../Layout/Img/image.png';
  import Excel from '../../Layout/Img/excel.png';
  import Rar from '../../Layout/Img/rar.png';
  import TXT from '../../Layout/Img/txt.png';
  import Word from '../../Layout/Img/word.png';
  import { getValueCrypt } from "../../utils/crypt";

  export const addFile = (data, props) => {

    var bodyFormData = new FormData();
    bodyFormData.append('File', data.file);
    bodyFormData.append('AppName', props.items.name);
    bodyFormData.append('AppId', props.items.id);
    bodyFormData.append('NameFileNew', data.inputFileName);
    bodyFormData.append('FileId', data.fileId == null ? uuid() : data.fileId);
    bodyFormData.append('StageId', props.items.stage_id);
    bodyFormData.append('LastLogId', props.items.last_log_id);
    bodyFormData.append('IsRequiredFile', false)
    return apiCall("POST", `${HOST_API}/${ADD_FILE_DETAIL}`, bodyFormData);
  }

  export const addFileRequire = (data, file, app) => {
    var bodyFormData = new FormData();


    bodyFormData.append('File', data.file);
    bodyFormData.append('AppName', app.name);
    bodyFormData.append('AppId', app.id);
    bodyFormData.append('NameFileNew', data.inputFileName);
    bodyFormData.append('FileId', file.id);
    bodyFormData.append('StageId', app.stage_id);
    bodyFormData.append('LastLogId', app.last_log_id);
    bodyFormData.append('IsRequiredFile', true)
    return apiCall("POST", `${HOST_API}/${ADD_FILE_DETAIL}`, bodyFormData);
  }

  export const addCommnet = (data) => {

    var bodyFormData = new FormData();

    data.files.forEach(element => {
      bodyFormData.append('Files', element);
    });

    // data.pings.forEach(element => {
    //   bodyFormData.append('Pings',  JSON.stringify(element));
    // });
    bodyFormData.append('Pings', JSON.stringify(data.pings));
    bodyFormData.append('ApplicationId', data.applicationId);
    bodyFormData.append('AppName', data.appName);
    bodyFormData.append('Attachments', data.attachments);
    bodyFormData.append('Content', data.content);
    bodyFormData.append('ContentRaw', data.contentRaw);
    bodyFormData.append('Parent', data.parent == null ? '00000000-0000-0000-0000-000000000000' : data.parent);
    bodyFormData.append('Id', data.id == null ? '00000000-0000-0000-0000-000000000000' : data.id);
    if (data.userIdDiscuss && data.userIdDiscuss) {
      bodyFormData.append('LastLogId', data.lastLogId);
      bodyFormData.append('UserIdDiscuss', data.userIdDiscuss);
    }
    return apiCall("POST", `${HOST_API}/${SAVE_COMMENT}`, bodyFormData);
  }


  export const downloadFile = (data) => {
    let {
      fileName,
      url
    } = data
    axios({
      url: `${HOST_API}/${DOWNLOAD_FILE_DETAIL}?url=${`${url}/${fileName}`}`, //your url
      method: 'GET',
      headers: {
          "Authorization":  'Bearer ' + getValueCrypt("JWT_TOKEN")
      },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  export const getFiles = (appId) => {
    return apiCall("GET", `${HOST_API}/${GET_FILE_DETAIL}/${appId}`, null);
  }

  export const getFileRequire = (fileId, appId) => {
    return apiCall("GET", `${HOST_API}/${GET_FILE_REQUIRE}/${fileId}/${appId}`, null);
  }

  export const deleteFile = (fileId) => {
    return apiCall("DELETE", `${HOST_API}/${DELETE_FILE}/${fileId}`, null);
  }

  export const deleteFileRequire = (fileId, appId) => {
    return apiCall("DELETE", `${HOST_API}/${DELETE_FILE_REQUIRE}/${fileId}/${appId}`, null);
  }

  export const secretFile = (fileId) => {
    return apiCall("PUT", `${HOST_API}/${SECRET_FILE}/${fileId}`, null);
  }
  // check danh sach file bat buoc da ton tai chua: neu da ton tai ko bat buoc up nua
  export const checkFileRequireExisted = async (param) => {
    let isValid = false;
    await apiCall("POST", `${CHECK_FILES_REQUIRED_EXISTED}`, param).then(e => {
      if (e.code === 200) {
        isValid = true;
      }
    })
    return isValid;
  }

  export const uploadProfileImage = (file, employeeId) => {
    const CODE = "I1";
    const PATH = "/Files/AnhCaNhan/";
    const FILENAME = "AnhCaNhan"

    var bodyFormData = new FormData();   
    bodyFormData.append('File', file);
    bodyFormData.append('EmployeeId', employeeId);
    bodyFormData.append('Path', PATH);
    bodyFormData.append('Code', CODE);
    bodyFormData.append('Filename', FILENAME);
    bodyFormData.append('Islog', true);
    bodyFormData.append('FromIndividualInfo', true);
    
    return apiCall("POST",`${HOST_API}/${UPLOAD_PROFILE_IMAGE}`, bodyFormData);
  }

  export const extention = (ex) => {
    switch (ex) {
      case '.doc':
      case '.docx':
        return Word;
        break;
      case '.jpg':
      case '.jpeg':
      case '.gif':
      case '.png':
        return Image;
        break;
      case '.xlsm':
      case '.xlsx':
      case '.xls':
        return Excel;
        break;
      case '.zip':
      case '.rar':
        return Rar;
        break;
      case '.txt':
        return TXT;
        break;

      default:
        return PDF;
        break;
    }
  }
