import * as Types from '../config/constaints'

export const LoginRequest = (data)=>{
    return {
        type: Types.LOGIN_REQUEST ,
        payload:data
    }
} //láy dữ liệu saga
export const LoginSuccess = (data)=>{;
    return {
        type: Types.LOGIN_SUCCESS ,
        payload:data
    }
}
export const LoginFail = (data)=>{;
    return {
        type: Types.LOGIN_FAIL ,
        payload:data
    }
}

export const LogOut = (data)=>{;
    return {
        type: Types.LOGOUT ,
        payload:data
    }
}

