
import * as Types from "../../config/constaints";

const  initialState= {
  isLoading: false
  };

  export default function Loading (state =initialState,action ){
    switch (action.type) { 
      case Types.UPDATE_LOADING_SUCCESS:{ 
          return  {...state,isLoading:  !action.payload};
       }
        default:
          break;
    }
    return state;
  }

