
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import {
    GET_EMPLOYEE_INDIVIDUAL,GET_EMPLOYEE_WORKPROGRESS,
    GET_EMPLOYEE_CONTRACT,GET_EMPLOYEE_PARTY_UNION,
    GET_EMPLOYEE_DIGITIZINGRECORDS,GET_EMPLOYEE_APPLICATION,
    GET_EMPLOYEE_FORM_DATAJSON} from '../../config/constainUrl';
import { apiCall } from '../../API/index'; 
import {  EmployeeProfileSuccess,EmployeeApplicationSuccess,EmployeeFormDataJsonSuccess} from '../../Action/employeeProfile';
import { toast } from 'react-toastify';
function* GetEmployeeIndividual(action){
    try{
        const response = yield call(apiCall,"GET",GET_EMPLOYEE_INDIVIDUAL); 
        if(response.code===200) yield put( EmployeeProfileSuccess(response));   
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });
    }
}

function* GetEmployeeWorkProgress(action){
    try{
        const response = yield call(apiCall,"GET",GET_EMPLOYEE_WORKPROGRESS); 
        if(response.code===200) yield put( EmployeeProfileSuccess(response));   
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });
    }
}
function* GetEmployeeContract(action){
    try{
        const response = yield call(apiCall,"GET",GET_EMPLOYEE_CONTRACT); 
        if(response.code===200) yield put( EmployeeProfileSuccess(response));   
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });
    }
}
function* GetEmployeePartyUnion(action){
    try{
        const response = yield call(apiCall,"GET",GET_EMPLOYEE_PARTY_UNION); 
        if(response.code===200) yield put( EmployeeProfileSuccess(response));   
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });
    }
}
function* GetEmployeeDigitizingRecords(action){
    try{
        
        const response = yield call(apiCall,"GET",GET_EMPLOYEE_DIGITIZINGRECORDS); 
        if(response.code===200) yield put( EmployeeProfileSuccess(response));   
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });
    }
}
function* GetEmployeeApplication(action){
    try{
        const response = yield call(apiCall,"GET",`${GET_EMPLOYEE_APPLICATION}/${action.payload}`); 
        if(response.code===200) yield put( EmployeeApplicationSuccess(response));   
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });
    }
}
function* GetEmployeeFormDataJson(action){
    try{
        const response = yield call(apiCall,"GET",`${GET_EMPLOYEE_FORM_DATAJSON}/${action.payload}`); 
        if(response.code===200) yield put( EmployeeFormDataJsonSuccess(response));   
    }
    catch(e){
        toast.error(`${e.statusText}`, { theme: "red" });
    }
}
export default function* EmployeeProfile(){
    yield takeEvery(Type.EMPLOYEE_INDIVIDUAL_REQUEST, GetEmployeeIndividual); 
    yield takeEvery(Type.EMPLOYEE_WORKPROGRESS_REQUEST, GetEmployeeWorkProgress);  
    yield takeEvery(Type.EMPLOYEE_CONTRACT_REQUEST, GetEmployeeContract);  
    yield takeEvery(Type.EMPLOYEE_PARTY_UNION_REQUEST, GetEmployeePartyUnion); 
    yield takeEvery(Type.EMPLOYEE_DIGITIZING_RECORDS_REQUEST, GetEmployeeDigitizingRecords); 
    yield takeEvery(Type.EMPLOYEE_APPLICATION_REQUEST, GetEmployeeApplication);  
    yield takeEvery(Type.EMPLOYEE_FROM_DATAJSON_REQUEST, GetEmployeeFormDataJson);                        
}