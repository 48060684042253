import * as Types from "../../config/constaints";

const initialState = {
    statusCode: 400,
    isSuccess: false,
    data: [],
    message: "",
    node:{}
}

const User = (state = initialState, action) => {
    switch (action.type) {
        case Types.USER_REQUEST:
            return { ...state }
        case Types.USER_SUCCESSFULLY:
            return {
                ...state,
                statusCode: 200,
                isSuccess: true,
                data: action.payload.data
            }
        case Types.USER_FAILURE:
            return {
                ...state,
                statusCode: 400,
                isSuccess: false,
                data: [],
                message: action.payload.message
            }
            case Types.USER_SELECTED_NODE_REQUEST:
                return {
                    ...state,
                    node:action.payload
                }
        default:
            break;
    }
    return state;
}

export default User;