
import * as Types from '../config/constaints'

export const GetCommentRequest = (data)=> {
    return { 
        type: Types.GET_COMMENT_REQUEST,
        payload:data
    }
}


export const GetCommentSuccess = (data) => {

    return { 
        type: Types.GET_COMMTENT_SUCCESS,
        payload:data
    }
}

export const SaveCommentRequest = (data)=> {
    return { 
        type: Types.SAVE_COMMENT_REQUEST,
        payload:data
    }
}

export const SaveCommentSuccess = (data)=> {
    return { 
        type: Types.SAVE_COMMENT_SUCCESS,
        payload:data
    }
}

export const UpdateCommentSuccess = (data)=> {
    return { 
        type: Types.UPDATE_COMMENT_SUCCESS,
        payload:data
    }
}

export const VoteCommentRequest = (data)=> {
    return { 
        type: Types.VOTE_COMMENT_REQUEST,
        payload:data
    }
}

export const VoteCommentSuccess = (data)=> {
    return { 
        type: Types.VOTE_COMMENT_SUCCESS,
        payload:data
    }
}

export const DeleteCommentRequest  = (data)=> {
    return { 
        type: Types.DELETE_COMMENT_REQUEST,
        payload:data
    }
}

export const DeleteCommentSuccess  = (data)=> {
    return { 
        type: Types.DELETE_COMMENT_SUCCESS,
        payload:data
    }
}


/// Notification Comment
export const GetNotificationCommentRequest = ()=> {
    return { 
        type: Types.GET_NOTIFICATION_COMMENT_REQUEST,
    }
}

export const GetNotificationCommentSuccess = (data)=> {
    return { 
        type: Types.GET_NOTIFICATION_COMMENT_SUCCESS,
        payload:data
    }
}

export const UpdateNotificationCommentSuccess = (data)=> {
    return { 
        type: Types.UPDATE_NOTIFICATION_COMMENT,
        payload:data
    }
}

export const UpdateNotificationCommentRedirect = (data)=> {
    return { 
        type: Types.UPDATE_NOTIFICATION_COMMENT_REDIRECT,
        payload:data
    }
}

export const GetPageNotificationCommentRequest = (data)=> {
    return { 
        type: Types.GET_PAGE_NOTIFICATION_COMMENT_REQUEST,
        payload:data
    }
}

export const GetPageNotificationCommentSuccess = (data)=> {
    return { 
        type: Types.GET_PAGE_NOTIFICATION_COMMENT_SUCCESS,
        payload:data
    }
}


