import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import classnames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import SingleComment from '../CommentDiscuss/SingleComment';
import avatar1 from "../../Layout/Img/avartar.gif";
import { GetCommentRequest,SaveCommentRequest,DeleteCommentRequest,VoteCommentRequest } from '../../Action/comment';
import moment from 'moment';
import { toast } from 'react-toastify';
import { TransferConfirmAlert } from '../ConfirmAlert';
import { extention} from '../../Service/File/file-service';
import { downloadFile } from "../../Service/File/file-service";
import {fomatText} from '../../Service/Hepper/hepper-service'
import { IMG_AVARTAR } from '../../config/constaints';
import { MentionsInput, Mention } from 'react-mentions'
import './mention-style.css'
import { getValueCrypt } from "../../utils/crypt"

export default function Comment({ appid,appName, 
    userLoginId,
    users,
    userAssginId,
    logId,
    handleSaveDiscuss,
    iSelected
    }) {
    
    const dispatch = useDispatch();
    const { commentData } = useSelector((state) => state.Comment);
    const [hideButton, sethideButton] = useState(true);
    const [toggleTab, setToggleTab] = useState("1");
    const [listFileUpload, setlistFileUpload] = useState([]);
    const [listSuggestions, setSuggestions] = useState([]);
    const [listAttachments, setattachments] = useState([]);
    const [tags, setTags] = useState([]);
    const auth = JSON.parse(getValueCrypt("USER_INFO"));

    useEffect(() => {
                if(users.length > 0){
                    let array =[];
                    for (let index = 0; index < users.length; index++) {
                        array.push({id:users[index].employeeId, display: users[index].employeeName  });
                    }
                    setSuggestions(array);
                }  
    },[users])

    useEffect(() => {
        dispatch(GetCommentRequest( {appid, logId,userIdDiscuss: userAssginId}));
    }, [appid])

    useEffect(() => {
      if(commentData.length > 0){

          var arrayFileComment=[];
                let filesComment =commentData
                    .sort((a, b) => moment(a.created) - moment(b.created))
                    .filter(p => p.attachments.length > 0);
                    filesComment.forEach(r => {
                            r.attachments.forEach (f => {
                                arrayFileComment.push({id: r.id,fullName:r.fullName,fileName:f.fileName,url:f.url,created:r.created}) 
                            });  
                    });
                    setattachments(arrayFileComment); 
                    
        }
       
    }, [commentData])

  
    function uploadFile(e) { 
        var listFile = [];
        for (var i = 0; i < e.length; i++) {
            listFile.push(e[i])
        }
        setlistFileUpload(listFile);
    }

    const removeFile = (e,i) =>{
       let list =  [...listFileUpload];
       let index=  list.indexOf(e);
       list.splice(index,1);
       setlistFileUpload(list);
    }
    const [commentDTO, setcommentDTO] = useState({
        parent: null,
        content: "",
        attachments: [],
        pings: [],
    })

    const [comment, setcomment] = useState({
        appId: appid,
        userLoginId: userLoginId,
        data: commentDTO
    })

   async function   saveComment() {

        if(!comment.data.content)
            return;
        dispatch(SaveCommentRequest({ ...comment.data, 
             id: null,
             applicationId :comment.appId,
             files: listFileUpload,
             appName:appName,
             pings: tags ,
             userIdDiscuss: userAssginId,
             lastLogId: logId
             }));
        setcommentDTO({
            parent: null,
            content: "",
            contentRaw:"",
            attachments: [],
            pings:  [],
        });
        setcomment({
            appId: appid,
            userLoginId: userLoginId,
            data: commentDTO
        });
        setTags([])
        setlistFileUpload([])
          handleSaveDiscuss()
    }

    useEffect(() => {
        setcomment({ ...comment, data: commentDTO });
    }, [commentDTO])

   async function DeleteComment(id, created) {
        if (moment(created).add(30, 'minutes') >= moment()) {
            const propsConfirmAlert = {
                title: "Bạn có muốn thu hồi góp ý hay không",
                fullName: null,
                positionName: null,
                departmentName:null,
                stageName: null,
                users: []
            }
            await TransferConfirmAlert(propsConfirmAlert)
            .then(async (response) => {
                if (response.isOk) {
                    dispatch(DeleteCommentRequest({commentId:  id , applicationId: appid } ));
                 }
            })
        }   
        else toast.error("Góp ý chỉ được thu hồi sau 30 phút");
    }

    function VoteComment(id, vote) {
        dispatch(VoteCommentRequest( { commentId:id , vote, applicationId:  appid}));
    }

    const handleChange = (event, newValue, newPlainTextValue, mentions) => {
        
        const value = event.target.value;
        const regex = /[^{}]+(?=})/g;
        value.match(regex);
        var content= fomatText(newValue);
        setcommentDTO({...commentDTO, contentRaw: value, content: content})
        let tags =[];
        mentions.forEach(e => {
            tags.push({UserTag:e.id,UserTagName: e.display.replace("@","") })
        });
        setTags(tags); 
    }

  

    return (
        <div className="comment-print">
            <Card>
            <CardHeader>GÓP Ý</CardHeader>
            <CardBody>
                {
                            
                                iSelected === '3' &&   userAssginId === auth.userId &&     <div className="chat-box-wrapper">
                           <div>
                               <div className="avatar-icon-wrapper mr-1">
                                   <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />
                                   <div className="avatar-icon avatar-icon-lg rounded">
                                   <img src={ auth.avartar ?  IMG_AVARTAR + auth.avartar : avatar1 } alt="" />
                                   </div>
                               </div>
                           </div>
                    <div style={{ width: "100%" }}>
                                                            <div style={{ position: "relative" }}>
                                                            <MentionsInput
                                                                            value={commentDTO.contentRaw ? commentDTO.contentRaw : ""}
                                                                            onChange={handleChange}
                                                                    
                                                                            placeholder="Nhập nội dung, sử dụng biểu tượng @ để gắn thẻ người dùng khác"
                                                                            className="mentions"
                                                                            >
                                                                            <Mention
                                                                                type="user"
                                                                                trigger="@"
                                                                                markup="[__display_____id__]"
                                                                                data={listSuggestions}
                                                                                displayTransform={(id, display) => {
                                                                                    return `@${display}`;
                                                                                }}
                                                                                className="mentions__mention"
                                                                            />
                                                                            </MentionsInput>
                                                                    
                                                            </div>
                                                            <div>
                                                                {
                                                                    commentDTO.attachments.map((item, i) => {
                                                                        return (
                                                                            <div key={item.id} className="badge badge-pill badge-secondary">
                                                                                <FontAwesomeIcon icon={faPaperclip} size="1x"></FontAwesomeIcon>
                                                                                {item.name}
                                                                                <link style={{ cursor: "pointer" }} >
                                                                                    <FontAwesomeIcon className="ml-1" icon={faTimes} size="1x"></FontAwesomeIcon>
                                                                                </link>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div style={{ float: "right" }}  >                                
                                                                <Button color="info" size="sm" className="btn" onClick={() => saveComment()}>Gửi</Button>
                                                            </div>
                                                        </div>
                        
                       </div>
                }
              
            </CardBody>
            </Card>
            <Card>
            <CardHeader>
                <Nav justified>
                    <NavItem>
                        <NavLink 
                            className={classnames({
                                active: toggleTab === "1",
                            })}
                            onClick={() => {
                                setToggleTab("1");
                            }}>
                            {/* Bình luận */}
                        </NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink 
                            className={classnames({
                                active: toggleTab === "2",
                            })}
                            onClick={ () => setToggleTab("2") }>
                            <FontAwesomeIcon className="mr-2" icon={faPaperclip} size="1x" />
                            File đính kèm
                        </NavLink>
                    </NavItem> */}
                </Nav>
            </CardHeader>
            <CardBody>
                <TabContent activeTab={toggleTab}>
                    <TabPane tabId="1">
                        <Row>
                            {commentData.length > 0 &&
                                commentData
                                    .sort((a, b) =>    moment(a.created) - moment(b.created) )
                                    .filter(p => p.parent == null)
                                    .map((item, i) => {
                                        return (

                                            <div className="col-12" key={item.id}>

                                                                      <SingleComment
                                                                                VoteComment={VoteComment}
                                                                                DeleteComment={DeleteComment}
                                                                                commentData={commentData}
                                                                                singleData={item}
                                                                                parent={item.id}
                                                                                commentDTO={commentDTO}
                                                                                setcommentDTO={setcommentDTO}
                                                                                saveComment={saveComment}
                                                                                hideButton={hideButton}
                                                                                sethideButton={sethideButton}
                                                                                downloadFile={downloadFile}
                                                                                appid={appid}
                                                                                appName= {appName}
                                                                                userLoginId={userLoginId}
                                                                                users={listSuggestions}
                                                                                userAssginId = {userAssginId}
                                                                                logId={logId}
                                                                                iSelected ={iSelected}
                                                                            ></SingleComment>
                                            </div>
                                        )
                                    })
                            }
                             {
                                commentData.length ===0 && <div className='col-12 text-center'>
                                    Không có góp ý
                                </div>
                            }
                        </Row>
                    </TabPane>
                    {/* <TabPane tabId="2">
                        <div className='col-12'>
                        {
                        listAttachments.length > 0 && <h5>Danh sách file đính kèm:</h5>
                        }    
                            {
                                
                                listAttachments.length > 0 &&
                                listAttachments.map((l, i) => { 
                                                    return (
                                                        <div className='col-12'>
                                                                    <div key={l.id}   onClick={() => downloadFile(l)}   className="badge color-file-comment cursor">
                                                                            <FontAwesomeIcon icon={faPaperclip} size="1x"></FontAwesomeIcon>
                                                                            {l.fileName}   
                                                                        </div><small  className="mt-1 opacity-6"> {l.fullName} - {moment(l.created).format("DD/MM/YYYY HH:mm:ss")}</small>
                                                        </div>
                                                    )
                                    })
       
                            }

                            {
                                    listAttachments.length === 0 && <div className='col-12 text-center'>
                                    Không có file đính  kèm
                                    </div>

                            }
                        </div>
                    </TabPane> */}
                </TabContent>
            </CardBody>
            </Card>
        </div>
    )
}
