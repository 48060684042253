import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { toast } from "react-toastify";
import {
  Modal, ModalBody, ModalFooter, Badge,
  Container,
  Row, Col,
  Card, CardBody, CardTitle,
  Input,
  ModalHeader
} from "reactstrap";
import { DatePicker, Progress, Radio } from 'antd';
import Moment from 'moment';
import {
  GetApplicationCancelInfoRequest,
  UpdateApplicationCancelCreateComment,
  UpdateApplicationCancelAccept
} from '../../../Action/detail';
import {
  MESSAGE_ERROR_VALIDTAION_FORM,
  MESSAGE_SUCCESS_UPDATE,
  MESSAGE_ERROR
} from '../../../config/constaintNotify';
import { saveApplicationCancel } from '../../../Service/ApplicationDetail/index';
import 'antd/dist/antd.css'
import { Link } from "react-router-dom";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Title = styled.span`
    font-weight: bold;
    font-size: 1.15rem;
    color: #6c757d;    
  `;

const Span = styled.span`
    margin-left: 1rem;
    font-size: 1rem;
    color: ${props => {
    switch (props.color) {
      case 'primary':
        return '#3ac47d';
      default:
        return '#000';
    }
  }};

    font-weight: ${props => {
    switch (props.fontWeight) {
      case 'bold':
        return 700;
      default:
        return 500;
    }
  }}    
`;




export default function CancelRequest(props) {
  const dispatch = useDispatch();
  const userApprove = useSelector(state => state.ApplicationCancel.userApprove);
  const userCreate = useSelector(state => state.ApplicationCancel.userCreate);
  const application = useSelector(state => state.ApplicationCancel.application);
  const { isUserApprove } = props;
  const handleCloseModal = () => {
    props.handleCloseModal();
  }


  useEffect(() => {
    // dispatch(GetUserApproveCancelApplicationRequest({
    //   applicationId: app.id,
    //   applicationLogId: isUserApprove ? app.applicationLogId : app.last_log_id
    // }))
    dispatch(GetApplicationCancelInfoRequest({
      applicationId: props.id,
      applicationLogId: props.applicationLogId
    }))
  }, [])

  const acceptChangeHandler = (event) => {
    dispatch(UpdateApplicationCancelAccept(event.target.value));
  }

  const sendRequestHandler = async () => {
    const applicationCancelEntity = {
      ApplicationLogId: application.applicationLogId,
      ApplicationId: application.applicationId,
      ApproveBy: userApprove.userId,
      ApproveComment: application.approveComment,
      Await: !props.isUserApprove,
      Accept: props.isUserApprove ? application.accept : null,
      CreateBy: userCreate.userId,
      CreateComment: application.createComment,
      CreateDate: application.createDate
    }
    const item = {
      applicationCancelEntity: applicationCancelEntity,
      isSendMail: !props.isUserApprove,
      applicationName: application.applicationName,
      applicationDescription: application.applicationDescription,
      cancelReason: application.createComment,
      personRequest: userCreate.fullName,
      departmentRequest: userCreate.departmentName,
      approver: userApprove.fullName
    }
    await SaveApplicationCancel(item);
  }

  const SaveApplicationCancel = async (data) => {
    await saveApplicationCancel(data).then(async res => {
      if (res.code === 200) {
        toast.success(MESSAGE_SUCCESS_UPDATE, { theme: "colored" });
        handleCloseModal();
      }
      else {
        toast.error(MESSAGE_ERROR, { theme: "red" });
      }
    })
  }


  return (
    <Fragment>
      <Modal size="lg"
        backdrop="static" isOpen={props.isOpen} fade={true} >        
        <ModalHeader>Xin hủy hồ sơ</ModalHeader>
        {userApprove && <ModalBody >
          <Container fluid={true}>
            <Row>
              <Col md={6}>
                <label><b>Tên hồ sơ: </b></label><br />  
                <Span>
                  <Link className="cursor" to={`/detail/${props.id}`}> {application.applicationName} </Link>                  
                </Span><br />
                <label className="mr-top-10"><b> Cán bộ xin hủy: </b></label><br />
                <span>  Người xin: </span>   <span color="primary" className="bold">  {userCreate.fullName}  </span> <br />
                <span>  Chức danh: {userCreate.positionName} </span> <br />
                <span>   Phòng ban: {userCreate.departmentName}   </span>  <br />
                <span>   Khối: {userCreate.gradelLevelName}   </span> 
                <br />
                <label className="mr-top-10"><b> Lý do hủy: </b></label> <br />
                {
                  !isUserApprove && !userApprove.await && <Input rows={5} type='textarea'
                    value={application.createComment}
                    onChange={(e) => dispatch(UpdateApplicationCancelCreateComment(`${e.target.value}`))}
                  />
                }
                {
                  isUserApprove && <Span>
                    {application.createComment}
                  </Span>
                }
              </Col>
              <Col md={6}>
                <label><b> Tên sản phẩm: </b></label><br />  <Span>{application.productName}</Span><br />
                <label className="mr-top-10"><b>Lãnh đạo phê duyệt:</b></label>
                <br />
                
                {userApprove && <>
                <div className="fill user-dealine-approve mr-top-10">
                  <span className="color-pe-7s-check" fontWeight='bold'>
                    {userApprove.fullName}
                  </span>
                  <br />
                  <span>
                  Chức danh: {userApprove.positionName}
                  </span>
                  <br />
                  <span>
                  Phòng ban: {userApprove.departmentName}
                  </span>
                  <br />
                  <span>
                  Khối: {userApprove.gradelLevelName}
                  </span>
                  </div>
                  <br />
                  <label><b>Ý kiến:</b> </label>                         
                  <br />
                  {
                    !isUserApprove && application.await && <Badge color="warning">Đang chờ ý kiến</Badge>
                  }
                  {
                    !isUserApprove && !application.await && application.accept !== null &&
                    <Badge color={application.accept ? 'success' : 'danger'}>
                      {application.accept ? 'Đồng ý' : 'Không đồng ý'}
                    </Badge>
                  }   
                  {isUserApprove && application.await && <>
                    <Span></Span>
                    <Radio.Group onChange={acceptChangeHandler} value={application.accept}>
                      <Radio value={true}>Đồng ý</Radio> <br />
                      <Radio value={false}>Không đồng ý</Radio>
                    </Radio.Group>
                  </>
                  }
                  {isUserApprove && !application.await && <>
                    <Span>
                      <FontAwesomeIcon icon={application.accept ? faCheckSquare : faSquare} />
                    </Span> Đồng ý <br />
                    <Span>
                      <FontAwesomeIcon icon={!application.accept ? faCheckSquare : faSquare} />
                    </Span> Không đồng ý <br />
                    </>
                  }                  
                </>
                }
              </Col>
            </Row>
            <Row>
              <Col md={6}>
               
              </Col>
              <Col md={6} >
               
              </Col>
            </Row>            
          </Container>
        </ModalBody>}
        {application && <ModalFooter>         
          {
            ((!isUserApprove && !application.await && application.accept === null) || (isUserApprove && application.await)) &&
            <button className="mr-2 btn-hover-shine btn btn-primary pull-right"
              onClick={() => sendRequestHandler()}
            >
              <i className="lnr-enter-down"></i>Lưu</button>
          }                   
          <button onClick={() => handleCloseModal()} className="mr-2 btn-hover-shine btn btn-secondary pull-right "><i className="lnr-cross-circle"></i> Thoát</button>
        </ModalFooter>}
      </Modal>
    </Fragment>

  );
}

