import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_APPLICATION_SEARCH} from '../../config/constainUrl'
import { GET_APPLICATION_SEARCH_REQUEST } from '../../config/constaints'
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';
import { GetApplicationSearchSuccess } from '../../Action/search';

function* getApplicationSearch(action){
    try{
        const response = yield call(apiCall,"POST",GET_APPLICATION_SEARCH,action.payload); 
        if(response.code===200){
            yield put(GetApplicationSearchSuccess(response))
        }
        else  toast.error(`${response.message}`, { theme: "red" });
    }
    catch(e){
       
    }
}



export default function* ApplicationSearch(){
    yield takeEvery(GET_APPLICATION_SEARCH_REQUEST,getApplicationSearch);
}