
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import {APPLICATION_USER_DISCUSS,DATA_JSON_LOG,FORM_DISCUSS} from '../../config/constainUrl';
import { apiCall } from '../../API/index'; 
import {  DiscussInboxSuccess,DiscussInboxViewFormSuccess } from '../../Action/discuss-inbox';
import { UrlApplicationDetail } from '../../Service/ApplicationDetail/index';
import { TotalHandlingUpdate } from '../../Action/total-handling';


function* DataDiscussInbox(action){
    try{
        const response = yield call(apiCall,"POST",APPLICATION_USER_DISCUSS,action.payload); // Goi API
        if(response.code===200){
            yield put( DiscussInboxSuccess(response));
            yield put(TotalHandlingUpdate({  type : "DISCUSS" , value: response.data.unProcessed } ));
        }
           
    }
    catch(e){
     
    }
}

function* DataDiscussInboxViewForm(action){
    try{
        //Lấy thông tin hs
        const responseDetail = yield call(UrlApplicationDetail,action.payload.appId); 
         // Lấy form xin ý kiến
         const responseFormDiscuss = yield call(apiCall,"GET",`${FORM_DISCUSS}/${action.payload.formId}/${action.payload.logId}`); // Goi API
        // Lấy data json log
        const responseDataJson = yield call(apiCall,"GET",`${DATA_JSON_LOG}/${action.payload.appId}/${action.payload.logId}`); // Goi API
       
        if(responseDetail.code===200 && responseDetail.code===200 && responseFormDiscuss.code===200)
        {
            let param ={
                data: responseDataJson.data,
                form: {...responseFormDiscuss.data[0], currentLogId: action.payload.logId},
                model:responseDetail.data
            }
            yield put( DiscussInboxViewFormSuccess(param));
        }
            
    }
    catch(e){
      
    }
}

export default function* DiscussInbox(){
    yield takeEvery(Type.DISCUSS_INBOX_REQUEST, DataDiscussInbox);   
    yield takeEvery(Type.DISCUSS_INBOX_VIEW_FROM_REQUEST, DataDiscussInboxViewForm);   
    
}