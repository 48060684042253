import * as Types from '../config/constaints'

export const EmployeeIndividualRequest = (data)=>{
 
    return { 
        type: Types.EMPLOYEE_INDIVIDUAL_REQUEST,
        payload:data
    }
}

export const EmployeeContractRequest = (data)=>{
    return { 
        type: Types.EMPLOYEE_CONTRACT_REQUEST,
        payload:data
    }
}

export const EmployeePartyUnionRequest = (data)=>{
 
    return { 
        type: Types.EMPLOYEE_PARTY_UNION_REQUEST,
        payload:data
    }
}

export const EmployeeDigitizingRecordsRequest = (data)=>{
 
    return { 
        type: Types.EMPLOYEE_DIGITIZING_RECORDS_REQUEST,
        payload:data
    }
}

export const EmployeeProfileSuccess = (data)=>{
    return {
        type: Types.EMPLOYEE_PROFILE_SUCCESS,
        payload:data
    }
}
export const EmployeeWorkProgressRequest = (data)=>{
    return { 
        type: Types.EMPLOYEE_WORKPROGRESS_REQUEST,
        payload:data
    }
}


export const EmployeeApplicationSuccess = (data)=>{
    return {
        type: Types.EMPLOYEE_PROFILE_SUCCESS,
        payload:data
    }
}
export const EmployeeApplicationRequest = (data)=>{
    return { 
        type: Types.EMPLOYEE_APPLICATION_REQUEST,
        payload:data
    }
}


export const EmployeeFormDataJsonRequest = (data)=>{
    return { 
        type: Types.EMPLOYEE_FROM_DATAJSON_REQUEST,
        payload:data
    }
}

export const EmployeeFormDataJsonSuccess = (data)=>{
    return { 
        type: Types.EMPLOYEE_FROM_DATAJSON_SUCCESS,
        payload:data
    }
}

export const EmployeeUpdateDataJsonRequest = (data)=>{
    return { 
        type: Types.EMPLOYEE_UPDATE_FROM_DATAJSON_SUCCESS,
        payload:data
    }
}