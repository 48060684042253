import { call, put, takeEvery } from 'redux-saga/effects';
import {  GetEmloyeeCVSuccess } from '../../Action/employee-cv';
import * as Type from '../../config/constaints';
import {GET_EMPLOYEE_CV} from '../../config/constainUrl';
import { apiCall } from '../../API/index';

function* GetdataEmployeeCV(action){

    try{ 
        const response = yield call(apiCall,"POST",GET_EMPLOYEE_CV,action.payload); 
        if(response.code===200)
             yield put(GetEmloyeeCVSuccess(response));  
    }
    catch(e){
      
    }
}



export default function* EmployeeCV(){
    yield takeEvery(Type.EMPLOYEE_CV_REQUEST, GetdataEmployeeCV);
  
}