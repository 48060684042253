
import {
    GET_DETAIL_PROCCESSED_TIME_BY_STAGES,
    GET_REPORT_APP_STATUS,
    GET_REPORT_APP_STATUS_WITH_USER,
    GET_REPORT_APP_AGGREGATE,
    GET_REPORT_APP_AGGREGATE_WITH_USER,
    URL_REPORT_KPI_APP
} from "../../config/constainUrl"
import {
    apiCall
  } from '../../API/index';

export const GetDetailProccessedTimeByStages = async (appId) => {
    return await apiCall("GET", `${GET_DETAIL_PROCCESSED_TIME_BY_STAGES}/${appId}`);
  }
  
export const GetReportAppStatus = async (data) => {
    return await apiCall("POST", `${GET_REPORT_APP_STATUS}`,data);
}

export const GetReportAppStatusWithUser = async (data) => {
    return await apiCall("POST", `${GET_REPORT_APP_STATUS_WITH_USER}`,data);
}

export const GetReportAppAggregate = async (data) => {
    return await apiCall("POST", `${GET_REPORT_APP_AGGREGATE}`,data);
}

export const GetReportAppAggregateWithUser = async (data) => {
    return await apiCall("POST", `${GET_REPORT_APP_AGGREGATE_WITH_USER}`,data);
}

export const GetReportKPIsApp = async (data) => {
    return await apiCall("POST", `${URL_REPORT_KPI_APP}`,data);
}

 export function Refit_keys(o,keys_short,keys_long){
    var build, key, destKey, ix, value;
    // Only handle non-null objects
    if (o === null || typeof o !== "object") {
        return o;
    }
    // Handle array just by handling their contents
    if (Array.isArray(o)) {
        return o.map((v,i)=> Refit_keys(v,keys_short,keys_long));
    }
    // We have a non-array object
    build = {};
    for (key in o) {
        // Get the destination key
        ix = keys_short.indexOf(key);
        destKey = ix === -1 ? key : keys_long[ix];
        // Get the value
        value = o[key];
        // If this is an object, recurse
        if (typeof value === "object") {
            value = Refit_keys(value,keys_short,keys_long);
        }
        // Set it on the result using the destination key
        build[destKey] = value;
    }
    return build;
}

export const GenerateDensityReportChart = (data) => {
    const categories = data.map(x => x.name);
    
    const series = [{
        name: 'HT Đúng hạn',
        data: data.map(x => x.htdhPercent)
    }, {
        name: 'HT Đúng gia hạn',
        data: data.map(x => x.htdgh)
    }, {
        name: 'HT Quá gia hạn',
        data: data.map(x => x.htqgh)
    }, {
        name: 'HT Quá hạn',
        data: data.map(x => x.htqh)
    }, {
        name: 'Chưa HT',
        data: data.map(x => x.chtPercent)
    }]
    return {
        series,
        categories        
    };
}