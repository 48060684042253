export const getIndexArrayWithId =(key, arrays)=>{
    let _index =null;
    for (let index = 0; index < arrays.length; index++) {
        if(arrays[index].id ===key)
            return _index=index;
    }
    return _index;
}


export const PathInbox =(key)=>{
    let _Path =null;
  switch (key) {
      case "inbox": // HS đế
        _Path="INBOX"
          break;
      case "inbox-follow": // HS theo  dõi
        _Path="FOLLOW"
          break;
      case "inbox-cc": // HS CC
        _Path="CC"
          break;
  
      default:
        _Path="INBOX"
          break;
  }
    return _Path;
}


export const fomatText =(text) =>{
  // var lstText =text.split("]");
  var lstText =splitMulti(text,"]")
  for (let index = 0; index < lstText.length; index++) {
      const indexOfElement = lstText[index].includes("[");
      if(indexOfElement) {
          let item_split =   lstText[index].split("_");
          lstText[index] = item_split[0];
          lstText[index] = lstText[index].replace("[", "<span class='bule'>@");
          lstText[index]=lstText[index] + "</span>"
      } 
  }
  return lstText.join(" ");
}

export const  splitMulti =(str, tokens)=>{
  var tempChar = tokens[0]; // We can use the first token as a temporary join character
  for(var i = 1; i < tokens.length; i++){
      str = str.split(tokens[i]).join(tempChar);
  }
  str = str.split(tempChar);
  return str;
}