
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import { 
    GET_TIMEKEEPRE_DATA,
    GET_TIMEKEEPRE,SAVE_LATCH
     ,GET_BOOL_LATCH,
    GET_DAY_LATCH,GET_PUBLIC_DAY,
    SAVE_TIMESHEET_SALARY,SAVE_DATE_TIMEKEEPER_WITH_ADMIN,CHECK_PERMISSION_MANIPULATE} from '.././../config/constainUrl';
import { TimeKeeperUserSuccess,GetTimeKeeperSuccess,UpdateIsLatch,UpdateDayLatch,UpdatePublicDay} from "../../Action/timeKeeper";
import { apiCall } from '../../API/index';
import { downFile } from '../../Service/Timekeeper/index';
import { toast } from 'react-toastify';
import { UpdateLoading } from "../../Action/loading";

function* getData(action) {
    try {
        const response = yield call(apiCall, "POST",GET_TIMEKEEPRE_DATA ,action.payload);
        const getIsLatch = yield call(apiCall, "GET",`${GET_BOOL_LATCH}/${action.payload.month}/${action.payload.year}`,null);
        const getDay= yield call(apiCall, "GET",`${GET_DAY_LATCH}/${action.payload.month}/${action.payload.year}`,null); // chốt chống công
        const getPublicDay =yield call(apiCall, "GET",`${GET_PUBLIC_DAY}/${action.payload.month}/${action.payload.year}`,null); 
   
        if (response.code === 200)  yield put(TimeKeeperUserSuccess(response)); 
        if (getIsLatch.code === 200) yield put(UpdateIsLatch(getIsLatch));
        if (getDay.code === 200) yield put(UpdateDayLatch(getDay));
        if (getPublicDay.code === 200) yield put(UpdatePublicDay(getPublicDay));
      
    }
    catch (e) { 
        toast.error(e.message, { theme: "red" });
    }
}

function* getTimekeeper(action) {
    try {
        const response = yield call(apiCall, "GET",  GET_TIMEKEEPRE ,action.payload); 
        if (response.code === 200) {
            yield put(GetTimeKeeperSuccess(response));  
        }
    }
    catch (e) {
        toast.error(e.message, { theme: "red" });
    }
}

function* ExportTimekeeper(action) {
    try {  
      yield call(downFile,action.payload); 
    }
    catch (e) {
        toast.error(e.message, { theme: "red" });
    }
}

function* SaveLatch(action) {
    try {
        const response =yield call(apiCall,"POST",SAVE_LATCH,action.payload); 
        if (response.code === 200) {
            toast.success(response.message, { theme: "colored" }) 
            yield put(UpdateIsLatch(true)); 
            yield put(UpdateLoading(true));   
        }
    }
    catch (e) {
        toast.error(e.message, { theme: "red" });
    }
}

function* SaveTimeSheetSalary(action) {
    try {
        const response =yield call(apiCall,"POST",SAVE_TIMESHEET_SALARY,action.payload); 
        if (response.code === 200) {
            toast.success(response.message, { theme: "colored" }) 
            const getDay= yield call(apiCall, "GET",`${GET_DAY_LATCH}/${action.payload.month}/${action.payload.year}`,null); // chốt chống công
            if (getDay.code === 200) yield put(UpdateDayLatch(getDay));
        }
    }
    catch (e) {
        toast.error(e.message, { theme: "red" });
    }
}
/// Chấm công lại với Admin
function* SaveDataTimekeeperWithAdmin(action) {
    try {
        const response =yield call(apiCall,"POST",SAVE_DATE_TIMEKEEPER_WITH_ADMIN,action.payload); 
        if (response.code === 200) {
            toast.success(response.message, { theme: "colored" }) 
        }
    }
    catch (e) {
        toast.error(e.message, { theme: "red" });
    }
}

function* CheckPermissionManipulate(action) {
    try {
        const checkPermissionManipulate =yield call(apiCall, "GET",`${CHECK_PERMISSION_MANIPULATE}/${action.payload.departmentId}/${action.payload.gradelLevelId}`,null); 
        if (checkPermissionManipulate.code === 200) yield put(checkPermissionManipulate(checkPermissionManipulate));
    }
    catch (e) {
        toast.error(e.message, { theme: "red" });
    }
}

export default function* Timekepper() {
    yield takeEvery(Type.TIMEKEEPER_USER_REQUEST, getData);
    yield takeEvery(Type.GET_TIMEKEEPER_REQUEST, getTimekeeper);
    yield takeEvery(Type.EXPORT_TIMEKEEPER, ExportTimekeeper);
    yield takeEvery(Type.SAVE_LATCH, SaveLatch);
    yield takeEvery(Type.SAVE_TIMESHEET_SALARY, SaveTimeSheetSalary);
    yield takeEvery(Type.SAVE_DATA_TIMEKEEPER_WITH_ADMIN, SaveDataTimekeeperWithAdmin);
    yield takeEvery(Type.UPDATE_PERMISSION_MANIPULATE_REQUEST, CheckPermissionManipulate);
    
}