import * as Types from "../../config/constaints";

const  initialState= {
  dataGroupSalaryRank: [], //Nhóm gạch lương,
  dataSalaryRank:[],
  dataSalaryleve:[],
  isError: false,
  messageError: null
  };

  export default function Salary (state =initialState,action ){
    
    // eslint-disable-next-line default-case
    switch (action.type) { 
          case Types.GET_GROUP_SALARY_RANK_All_SUCCESS:{ 
                if(action.payload.code !== 200)
                  return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                else {
                  return  {...state,dataGroupSalaryRank:  action.payload.data };
                }
          }
          case Types.GET_BY_ID_GROUP_SALARY_RANK_SUCCESS:{ 
            if(action.payload.code !== 200)
              return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
            else {
              return  {...state,dataSalaryRank:  action.payload.data };
            }
      }
      case Types.GET_BY_SALARY_RANK_SUCCESS:{ 
        if(action.payload.code !== 200)
          return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
        else {
          return  {...state,dataSalaryleve:  action.payload.data };
        }
  }
          default:
            return state;
  }
}

