import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import {
  apiCall
} from '../../API/index';
import {
  GET_ACCOUNT_PERMISSION,
  GET_PAGE_BUTTON_PERMISSION
} from '../../config/constainUrl';
import {
  SetAccountPermission,
  SetPageButtonPermission
} from '../../Action/permission';


function* GetAccountPermission() {
  try {
    const response = yield call(apiCall, "GET", `${GET_ACCOUNT_PERMISSION}`); // Goi API    
    if (response.code === 200) {
      yield put(SetAccountPermission(response.data));
    }
  } catch (e) {
   
  }
}

function* GetPageButtonPermission(action) {
  try {
    debugger
    const response = yield call(apiCall, "POST", `${GET_PAGE_BUTTON_PERMISSION}?pageRoute=${action.payload}`); // Goi API    
    if (response.code === 200) {
      yield put(SetPageButtonPermission(response.data));
    }
  } catch (e) {
   
  }
}

export default function* Inbox(){
  yield takeEvery(Type.GET_ACCOUNT_PERMISSION_REQUEST, GetAccountPermission);
  yield takeEvery(Type.GET_PAGE_BUTTON_PERMISSION_REQUEST, GetPageButtonPermission);
}