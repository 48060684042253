
import * as Types from "../../config/constaints";

const  initialState= {
  data: {
    list: [],
    total: 0
  },
  isError: false,
  messageError: null,
  };

  export default function DeadlineInbox (state =initialState,action ){
    switch (action.type) { 
      case Types.INBOX_DEADLINE_SUCCESS:{  
        return  {...state
          ,data: {
            list: action.payload.data.list ?  action.payload.data.list : [],
            total: action.payload.data.total ? action.payload.data.total : 0
        },
           };
   }
       default:
          return state;
    }
  
  }

