
import { call, put, takeEvery } from 'redux-saga/effects';
import { INSERT_ASSIGNMENT_URL ,GET_INBOX_ASSIGNMENT_URL} from '../../config/constainUrl'
import { GET_INBOX_ASSIGN_REQUEST, INSERT_ASSIGNMENT } from '../../config/constaints'
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';
import { InboxRequest } from '../../Action/inbox'
import { GetInboxAssignmentSuccess } from '../../Action/appAssignment'


function* insertAssignment(action){
    try{
        const response = yield call(apiCall,"POST",INSERT_ASSIGNMENT_URL,action.payload.dataAction); 
        if(response.code===200){
            toast.success(`${response.message}`, { theme: "colored" });
            yield put(InboxRequest(action.payload.resetData))
        }
        else    toast.error(`${response.message}`, { theme: "red" });
    }
    catch(e){
      
    }
}

function* getInboxAssign(action){
    try{
        const response = yield call(apiCall,"POST",GET_INBOX_ASSIGNMENT_URL,action.payload); 
        if(response.code===200){
            yield put(GetInboxAssignmentSuccess(response))
        }
        else  toast.error(`${response.message}`, { theme: "red" });
    }
    catch(e){
       
    }
}

export default function* ApplciationAssignment(){
    yield takeEvery(INSERT_ASSIGNMENT,insertAssignment);
    yield takeEvery(GET_INBOX_ASSIGN_REQUEST,getInboxAssign);
   
}