import {
  BTN_VIEW_PERMISSION,
  BTN_ADD_PERMISSION,
  BTN_EDIT_PERMISSION,
  BTN_RESET_PASSWORD_PERMISSION,
  PER_ADMIN,
  PER_RESET_PASS,
  PER_ADMIN_QLNS,
  PER_LATCH_TIME_KEEPER,
  BTN_VIEW_DETAIL_PERMISSION
} from '../../config/constaints';

export const ComputePageButtonPermission = (data) => {
  const viewPermission = data.filter(button => button.id === BTN_VIEW_PERMISSION).length > 0;
  const addPermission = data.filter(button => button.id === BTN_ADD_PERMISSION).length > 0;
  const editPermission = data.filter(button => button.id === BTN_EDIT_PERMISSION).length > 0;
  const viewDetailPermission = data.filter(button => button.id === BTN_VIEW_DETAIL_PERMISSION).length > 0;
  return {
    view: viewPermission,
    edit: editPermission,
    add: addPermission,
    viewDetail: viewDetailPermission
  }
}

export const ComputeAccountPermission = (data) => {
  const adminPermission = data.filter(per => per.id === PER_ADMIN).length > 0;
  const resetPasswordPermission = data.filter(per => per.id === PER_RESET_PASS).length > 0;
  const adminQLNSPermission = data.filter(per => per.id === PER_ADMIN_QLNS).length > 0;
  const latchTimeKeeperPermission = data.filter(per => per.id === PER_LATCH_TIME_KEEPER).length > 0;
  return {
    admin: adminPermission,
    adminQLNS: adminQLNSPermission,
    resetPassword: resetPasswordPermission,
    latchTimeKeeper: latchTimeKeeperPermission
  } 
}