import React from "react";
// import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { Spinner } from 'reactstrap'
import Rating from "react-rating";
import { IoIosStar } from "react-icons/io";
import { confirmAlert } from 'react-confirm-alert';
import TextareaAutosize from "react-textarea-autosize";
import Select from '../Select'
import './style.css';
import { Input } from "reactstrap";
import Moment from 'moment';


export const TransferConfirmAlert = async (props) => {
  const { title, fullName, positionName, departmentName, stageName } = props;
  const result = {
    isOk: false,
    data: {}
  }
  return new Promise((resolve, reject) => {
    return (
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <div className="text-center text-dark">
                <div className="font-weight-bold h5">{title}</div>
                {
                  fullName && <div className="font-weight-bold h6">{fullName}</div>
                }{
                  positionName && <div className="font-weight-normal">{positionName} - {departmentName}</div>
                }
                {
                  stageName && <div className="font-italic">  &lt;{stageName}&gt;</div>
                }
              </div>
              <div className="react-confirm-alert-button-group" style={{ "justifyContent": "center" }}>
                <button className="btn-hover-shine bg-success"
                  onClick={() => {
                    result.isOk = true;
                    onClose();
                  }}
                >
                  {/* {isLoading && <Spinner as="span" animation="border" variant="light" size="sm" />} */}
                  &nbsp;&nbsp;Đồng ý
                </button>
                <button className="btn-hover-shine bg-secondary"
                  onClick={onClose}>
                  Không đồng ý
                </button>
              </div>
            </div>
          );
        },
        afterClose: () => {
          resolve(result);
        },
        overlayClassName: "overlay-custom-confirm-alert"
      })
    );
  });
};

export const RollbackConfirmAlert = async (props) => {
  const { title, fullName, positionName, departmentName, stageName, users } = props;
  const result = {
    isOk: false,
    data: {}
  }
  let userCCs = [];
  let comment = "";
  const onChangeSelectUserCC = (newValues) => {
    userCCs = newValues
      .map(user => (user.value));
  }
  const onChangeComment = (event) => {
    const newValue = event.target.value;
    comment = newValue;
  }
  return new Promise((resolve, reject) => {
    return (
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <div className="text-center text-dark">
                <div className="font-weight-bold h5">{title}</div>
                <div className="font-weight-bold h6">{fullName}</div>
                <div className="font-weight-normal">{positionName} - {departmentName}</div>
                <div className="font-italic">  &lt;{stageName}&gt;</div>
                <div className="mt-3 mb-3">
                  <Select
                    // defaultValue={userCCs.filter(user => app.userCCs.includes(user.EmployeeId))}
                    options={users}
                    isMulti={true}
                    onChangeSelect={onChangeSelectUserCC.bind(this)}
                  />
                </div>
                <TextareaAutosize
                  placeholder="Nhập lý do trả lại..."
                  className="form-control"
                  style={{ fontSize: "13px" }}
                  minRows={2}
                  maxRows={6}
                  defaultValue={comment}
                  onChange={onChangeComment.bind(this)}
                />
              </div>
              <div className="react-confirm-alert-button-group" style={{ "justifyContent": "center" }}>
                <button className="btn-hover-shine bg-success"
                  onClick={() => {
                    if (!comment) {
                      toast.error(`VUI LÒNG NHẬP LÝ DO TRẢ LẠI`, { theme: "red" });
                    }
                    else {
                      result.isOk = true;
                      result.data.comment = comment;
                      result.data.userCCs = userCCs.length
                        ? JSON.stringify(users
                          .filter(p => userCCs.includes(p.value))
                          .map(p => ({ UserId: p.employeeId, UserName: p.userName, FullName: p.employeeName })))
                        : null;
                      onClose();
                    }
                  }}
                >
                  {/* {isLoading && <Spinner as="span" animation="border" variant="light" size="sm" />} */}
                  &nbsp;&nbsp;Đồng ý
                </button>
                <button className="btn-hover-shine bg-secondary"
                  onClick={onClose}>
                  Không đồng ý
                </button>
              </div>
            </div>
          );
        },
        afterClose: () => {
          resolve(result);
        },
        overlayClassName: "overlay-custom-confirm-alert"
      })
    );
  });
};

export const ApproveConfirmAlert = (props) => {
  const { title, fullName, positionName, departmentName, stageName } = props;
  const result = {
    isOk: false,
    data: {}
  }
  let rating = 0;
  return new Promise((resolve, reject) => {
    return (
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <div className="text-center text-dark">
                <div className="font-weight-bold h5">{title}</div>
                <div className="font-weight-bold h6">{fullName}</div>
                <div className="font-weight-normal">{positionName} - {departmentName}</div>
                <div className="font-italic">  &lt;{stageName}&gt;</div>
                <div>
                  <Rating
                    initialRating={rating}
                    stop={5}
                    onChange={(rate) => { rating = rate }}
                    emptySymbol={
                      <span className="opacity-2">
                        <IoIosStar color="#eca909" fontSize="2rem" />
                      </span>
                    }
                    fullSymbol={
                      <span>
                        <IoIosStar color="#eca909" fontSize="2rem" />
                      </span>
                    } />
                </div>
              </div>
              <div className="react-confirm-alert-button-group" style={{ "justifyContent": "center" }}>
                <button className="btn-hover-shine bg-success"
                  onClick={() => {
                    if (rating === 0) {
                      toast.error(`VUI LÒNG CHO DÁNH GIÁ`, { theme: "red" });
                    }
                    else {
                      result.isOk = true;
                      result.data.rate = rating;
                      onClose();
                    }
                  }}
                >
                  {/* {isLoading && <Spinner as="span" animation="border" variant="light" size="sm" />} */}
                  &nbsp;&nbsp;Đồng ý
                </button>
                <button className="btn-hover-shine bg-secondary"
                  onClick={onClose}>
                  Không đồng ý
                </button>
              </div>
            </div>
          );
        },
        afterClose: () => {
          resolve(result);
        },
        overlayClassName: "overlay-custom-confirm-alert"
      })
    );
  });
};

export const UpdateProfileConfirmAlert = ({ title, applicationName }) => {
  const result = {
    isOk: false,
    data: {}
  }
  return new Promise((resolve, reject) => {
    return (
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <div className="text-center text-dark">
                <div className="font-weight-bold h5">{title}</div>
                <div className="font-weight-bold h6">{applicationName}</div>
              </div>
              <div className="react-confirm-alert-button-group" style={{ "justifyContent": "center" }}>
                <button className="btn-hover-shine bg-success"
                  onClick={() => {
                    result.isOk = true;
                    onClose();
                  }}
                >
                  {/* {isLoading && <Spinner as="span" animation="border" variant="light" size="sm" />} */}
                  &nbsp;&nbsp;Đồng ý
                </button>
                <button className="btn-hover-shine bg-secondary"
                  onClick={onClose}>
                  Không đồng ý
                </button>
              </div>
            </div>
          );
        },
        afterClose: () => {
          resolve(result);
        },
        overlayClassName: "overlay-custom-confirm-alert"
      })
    );
  })
}

export const CompletedConfirmAlert = (props) => {
  const { title, applicationName } = props;
  const result = {
    isOk: false,
    data: {}
  }
  return new Promise((resolve, reject) => {
    return (
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <div className="text-center text-dark">
                <div className="font-weight-bold h5">{title}</div>
                <div className="font-weight-bold h6">{applicationName}</div>
              </div>
              <div className="react-confirm-alert-button-group" style={{ "justifyContent": "center" }}>
                <button className="btn-hover-shine bg-success"
                  onClick={() => {
                    result.isOk = true;
                    onClose();
                  }}
                >
                  {/* {isLoading && <Spinner as="span" animation="border" variant="light" size="sm" />} */}
                  &nbsp;&nbsp;Đồng ý
                </button>
                <button className="btn-hover-shine bg-secondary"
                  onClick={onClose}>
                  Không đồng ý
                </button>
              </div>
            </div>
          );
        },
        afterClose: () => {
          resolve(result);
        },
        overlayClassName: "overlay-custom-confirm-alert"
      })
    );
  })
};


export const CreateApplicationChildrenConfirmAlert = (props) => {
  const { title } = props;
  const result = {
    isOk: false,
    data: {}
  }
  return new Promise((resolve, reject) => {
    return (
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <div className="text-center text-dark">
                <div className="font-weight-bold h5">{title}</div>
                {/* <div className="font-weight-bold h6">{applicationName}</div> */}
              </div>
              <div className="react-confirm-alert-button-group" style={{ "justifyContent": "center" }}>
                <button className="btn-hover-shine bg-success"
                  onClick={() => {
                    result.isOk = true;
                    onClose();
                  }}
                >
                  {/* {isLoading && <Spinner as="span" animation="border" variant="light" size="sm" />} */}
                  &nbsp;&nbsp;Đồng ý
                </button>
                <button className="btn-hover-shine bg-secondary"
                  onClick={onClose}>
                  Không đồng ý
                </button>
              </div>
            </div>
          );
        },
        afterClose: () => {
          resolve(result);
        },
        overlayClassName: "overlay-custom-confirm-alert"
      })
    );
  })
};

export const LatchSalaryConfirmAlert = ({title, month}) => {
  let result = {
    isOk: false,
    data: 0,
    isValid: true
  }

  const handleChangeValue = (e) => {
    const value = e.target.value;
    result = {
      ...result,
      data: value
    }
  }

  const daysInMonth = Moment(`2022-${month}`, 'YYYY-MM').daysInMonth();
  
  return new Promise((resolve, reject) => {
    return (
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <div className="text-center text-dark">
                <div className="font-weight-bold h5">{title}</div>
                {/* <div className="font-weight-bold h6">{applicationName}</div> */}
              </div>
              <div>
                <Input type="number" onChange={(e) => handleChangeValue(e)} />
              </div>
              <div className="react-confirm-alert-button-group" style={{ "justifyContent": "center" }}>
                <button className="btn-hover-shine bg-success"
                  onClick={() => {
                    if (result.data <= 0 || result.data > daysInMonth) 
                    {
                      toast.error(`VUI LÒNG NHẬP NGÀY CHỐT BCC LƯƠNG LÀ NGÀY TRONG THÁNG ${month}`, { theme: "red" });
                      return;
                    }
                    result.isOk = true;
                    onClose();
                  }}
                >
                  {/* {isLoading && <Spinner as="span" animation="border" variant="light" size="sm" />} */}
                  &nbsp;&nbsp;Chốt BCC lương
                </button>
                <button className="btn-hover-shine bg-secondary"
                  onClick={onClose}>
                  Hủy
                </button>
              </div>
            </div>
          );
        },
        afterClose: () => {
          resolve(result);
        },
        overlayClassName: "overlay-custom-confirm-alert"
      })
    );
  })
}

export const ConfirmAlertTextarea = async (props) => {
  const { title, content } = props;
  const result = {
    isOk: false,
    data: {}
  }

  let comment = "";
  const onChangeComment = (event) => {
    const newValue = event.target.value;
    comment = newValue;
  }
  return new Promise((resolve, reject) => {
    return (
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <div className="text-center text-dark">
                <div className="font-weight-bold h5">{title}</div>
                <div className="font-italic">  {content}</div>
                <TextareaAutosize
                  placeholder="Nhập lý do trả lại..."
                  className="form-control"
                  style={{ fontSize: "13px" }}
                  minRows={2}
                  maxRows={6}
                  defaultValue={comment}
                  onChange={onChangeComment.bind(this)}
                />
              </div>
              <div className="react-confirm-alert-button-group" style={{ "justifyContent": "center" }}>
                <button className="btn-hover-shine bg-success"
                  onClick={() => {
                    if (!comment) {
                      toast.error(`VUI LÒNG NHẬP LÝ DO TRẢ LẠI`, { theme: "red" });
                    }
                    else {
                      result.isOk = true;
                      result.data.comment = comment;
                      onClose();
                    }
                  }}
                >
                  Đồng ý
                </button>
                <button className="btn-hover-shine bg-secondary"
                  onClick={onClose}>
                  Không đồng ý
                </button>
              </div>
            </div>
          );
        },
        afterClose: () => {
          resolve(result);
        },
        overlayClassName: "overlay-custom-confirm-alert"
      })
    );
  });
};
