import * as Types from '../config/constaints'

export const InboxDeadlineRequest = (data)=>{
 
    return { 
        type: Types.INBOX_DEADLINE_REQUEST,
        payload:data
    }
}

export const InboxDeadlineSuccess = (data)=>{
    return {
        type: Types.INBOX_DEADLINE_SUCCESS,
        payload:data
    }
}