import * as Types from '../config/constaints'

export const TotalHandlingRequest = (data)=>{
    return { 
        type: Types.TOTAL_HANDLING_REQUEST,
        payload:data
    }
}

export const TotalHandlingSuccess = (data)=>{
    return { 
        type: Types.TOTAL_HANDLING_SUCCESS,
        payload:data
    }
}

export const TotalHandlingUpdate = (data)=>{
    return {
        type: Types.TOTAL_HANDLING_UPDATE,
        payload:data
    }
}






