
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_SCHOOL,INSERT_UPDATE_SCHOOL,DELETE_SCHOOL
   } from '../../config/constainUrl';
import { 
     GetSchoolSuccess,GetSchoolRequest} from '../../Action/category';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';

function* GetSchoolWithPage(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_SCHOOL}/${action.payload.pageIndex}/${action.payload.pageSize}`); 
        if(response.code===200)
             yield put(GetSchoolSuccess(response));  
    }
    catch(e){
     
    }
}

function* InsertOrUpdateSchool(action){
    try{ 
        const response = yield call(apiCall,"POST",`${INSERT_UPDATE_SCHOOL}`,action.payload.data); 
        if(response.code===200){
            yield put(GetSchoolRequest({pageIndex:action.payload.page,pageSize:20}));  
            toast.success(response.message, { theme: "colored" })    
        }   
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}
function* DeleteSchool(action){
    try{ 
        const response = yield call(apiCall,"DELETE",`${DELETE_SCHOOL}/${action.payload.id}`); 
        
        if(response.code===200){
            yield put(GetSchoolRequest({pageIndex:action.payload.pageIndex,pageSize:20}));  
            toast.success(response.message, { theme: "colored" })    
        }    
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

export default function* School(){
    yield takeEvery(Type.GET_SCHOOL_REQUEST, GetSchoolWithPage);
    yield takeEvery(Type.INSERT_UPDATE_SCHOOL, InsertOrUpdateSchool);
    yield takeEvery(Type.DELETE_SCHOOL, DeleteSchool);
   
}