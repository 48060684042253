import * as Types from '../config/constaints'


export const GetGroupSalaryRankAllRequest = ()=>{
    return { 
        type: Types.GET_GROUP_SALARY_RANK_All_REQUEST
    }
}
export const GetGroupSalaryRankAllSuccess = (data)=>{
    return {
        type: Types.GET_GROUP_SALARY_RANK_All_SUCCESS,
        payload:data
    }
}

export const GetByIdGroupSalaryRankRequest = (data)=>{
    return { 
        type: Types.GET_BY_ID_GROUP_SALARY_RANK_REQUEST,
        payload:data
    }
}
export const GetByIdGroupSalaryRankSuccess= (data)=>{
    return {
        type: Types.GET_BY_ID_GROUP_SALARY_RANK_SUCCESS,
        payload:data
    }
}

export const GetBySalaryRankRequest = (data)=>{
    return { 
        type: Types.GET_BY_SALARY_RANK_REQUEST,
        payload:data
    }
}
export const GetBySalaryRankSuccess= (data)=>{
    return {
        type: Types.GET_BY_SALARY_RANK_SUCCESS,
        payload:data
    }
}

export const InsertOrUpdateGroupSalaryRank =(data)=>{
    return { 
        type: Types.INSERT_UPDATE_GROUP_SALARY_RANK,
        payload:data
    }
}

export const DeleteGroupSalaryRank =(data)=>{
    return { 
        type: Types.DELETE_GROUP_SALARY_RANK,
        payload:data
    }
}


export const InsertOrUpdateSalaryRank =(data)=>{
    return { 
        type: Types.INSERT_UPDATE_SALARY_RANK,
        payload:data
    }
}

export const DeleteSalaryRank =(data)=>{
    return { 
        type: Types.DELETE_SALARY_RANK,
        payload:data
    }
}

export const InsertOrUpdateSalaryLeve =(data)=>{
    return { 
        type: Types.INSERT_UPDATE_SALARY_LEVE,
        payload:data
    }
}

export const DeleteSalaryLeve =(data)=>{
    return { 
        type: Types.DELETE_SALARY_LEVE,
        payload:data
    }
}

export const GetprovinceAllRequest =(data)=>{
    return { 
        type: Types.GET_PROVINCE_All_REQUEST,
        payload:data
    }
}

export const GetprovinceAllSuccess =(data)=>{
    return { 
        type: Types.GET_PROVINCE_All_SUCCESS,
        payload:data
    }
}

export const GetDistrictWithProvinceRequest =(data)=>{
    return { 
        type: Types.GET_DISTRICT_WITH_PROVINCE_REQUEST,
        payload:data
    }
}

export const GetDistrictWithProvinceSuccess =(data)=>{
    return { 
        type: Types.GET_DISTRICT_WITH_PROVINCE_SUCCESS,
        payload:data
    }
}

export const GetWardsWithDistrictRequest =(data)=>{
    return { 
        type: Types.GET_WARDS_WITH_DISTRICT_REQUEST,
        payload:data
    }
}

export const GetWardsWithDistrictSuccess =(data)=>{
    return { 
        type: Types.GET_WARDS_WITH_DISTRICT_SUCCESS,
        payload:data
    }
}


export const GetCountryRequest =(data)=>{
    return { 
        type: Types.GET_COUNTRY_REQUEST,
        payload:data
    }
}

export const GetCountrySuccess =(data)=>{
    return { 
        type: Types.GET_COUNTRY_SUCCESS,
        payload:data
    }
}


export const InsertProvince =(data)=>{
    return { 
        type: Types.INSERT_UPDATE_PROVINCE,
        payload:data
    }
}

export const DeleteProvince =(data)=>{
    return { 
        type: Types.DELETE_PROVINCE,
        payload:data
    }
}


export const InsertDistrict =(data)=>{
    return { 
        type: Types.INSERT_UPDATE_DISTRICT,
        payload:data
    }
}

export const DeleteDistrict =(data)=>{
    return { 
        type: Types.DELETE_DISTRICT,
        payload:data
    }
}

export const InsertWards =(data)=>{
    return { 
        type: Types.INSERT_UPDATE_WARDS,
        payload:data
    }
}

export const DeleteWards =(data)=>{
    return { 
        type: Types.DELETE_WARDS,
        payload:data
    }
}


export const GetReligionRequest = (data)=>{
 
    return { 
        type: Types.GET_RELIGION_REQUEST,
    }
}

export const GetReligionSuccess = (data)=>{
    return {
        type: Types.GET_RELIGION_SUCCESS,
        payload:data
    }
}

export const InsertOrUpdateReligion = (data)=>{
 
    return { 
        type: Types.INSERT_UPDATE_RELIGION,
        payload:data
    }
}

export const DeleteReligion = (data)=>{
 
    return { 
        type: Types.DELETE_RELIGION,
        payload:data
    }
}



export const GetKPIsRequest = (data)=>{
    return { 
        type: Types.GET_KPIS_REQUEST,
        payload:data
    }
}

export const GetKPIsSuccess = (data)=>{
    return {
        type: Types.GET_KPIS_SUCCESS,
        payload:data
    }
}


export const InsertOrUpdateKPIs = (data)=>{
 
    return { 
        type: Types.INSERT_UPDATE_KPIS,
        payload:data
    }
}

export const DeleteKPIs = (data)=>{
 
    return { 
        type: Types.DELETE_KPIS,
        payload:data
    }
}



export const GetSpecializedRequest = (data)=>{
    return { 
        type: Types.GET_SPECIALIZED_REQUEST,
        payload:data
    }
}

export const GetSpecializedSuccess = (data)=>{
    return {
        type: Types.GET_SPECIALIZED_SUCCESS,
        payload:data
    }
}


export const InsertOrUpdateSpecialized = (data)=>{
 
    return { 
        type: Types.INSERT_UPDATE_SPECIALIZED,
        payload:data
    }
}

export const DeleteSpecialized = (data)=>{
 
    return { 
        type: Types.DELETE_SPECIALIZED,
        payload:data
    }
}



export const GetGradelLevelRequest = (data)=>{
    return { 
        type: Types.GET_GRADELLEVEL_REQUEST,
        payload:data
    }
}

export const GetGradelLevelSuccess = (data)=>{
    return {
        type: Types.GET_GRADELLEVEL_SUCCESS,
        payload:data
    }
}


export const InsertOrUpdateGradelLevel = (data)=>{
 
    return { 
        type: Types.INSERT_UPDATE_GRADELLEVEL,
        payload:data
    }
}


export const GetDepartmentGradelLevelBranchRequest = (data)=>{
    return { 
        type: Types.GET_DEPARTMENT_GRADELLEVEL_BRANCH_REQUEST,
        payload:data
    }
}

export const GetDepartmentGradelLevelBranchSuccess = (data)=>{
    return {
        type: Types.GET_DEPARTMENT_GRADELLEVEL_BRANCH_SUCCESS,
        payload:data
    }
}

export const InsertOrUpdateDepartmentGradelLevelBranch = (data)=>{
 
    return { 
        type: Types.INSERT_UPDATE_DEPARTMENT_GRADELLEVEL,
        payload:data
    }
} // Not



export const GetPositionDepartmentRequest = (data)=>{
    return { 
        type: Types.GET_POSITION_DEPARTMENT_REQUEST,
        payload:data
    }
}

export const GetPositionWithGroupDesDesRequest = (data)=>{
    return { 
        type: Types.GET_POSITION_GROUP_DEPARTMENT_REQUEST,
        payload:data
    }
}

export const GetPositionDepartmentSuccess = (data)=>{
    return {
        type: Types.GET_POSITION_DEPARTMENT_SUCCESS,
        payload:data
    }
}
export const GetPositionGroupDepartmentSuccess = (data)=>{
    return {
        type: Types.GET_POSITION_GROUP_DEPARTMENT_SUCCESS,
        payload:data
    }
}

export const InsertOrUpdatePositionDepartment = (data)=>{
 
    return { 
        type: Types.INSERT_UPDATE_POSITION_DEPARTMENT,
        payload:data
    }
}




export const GetDepartmentBranchGroupDepartmentRequest = (data)=>{
    return { 
        type: Types.GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT_REQUEST,
        payload:data
    }
}

export const GetDepartmentBranchGroupDepartmentSuccess = (data)=>{
    return {
        type: Types.GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT_SUCCESS,
        payload:data
    }
}

export const InsertOrUpdateGroupDepartment = (data)=>{
 
    return { 
        type: Types.INSERT_UPDATE_GROUP_DEPARTMENT,
        payload:data
    }
}
export const InsertOrUpdateBranchGroupDepartment = (data)=>{
 
    return { 
        type: Types.INSERT_UPDATE_BRANCH_GROUP_DEPARTMENT,
        payload:data
    }
}





export const GetSchoolRequest = (data)=>{
    return { 
        type: Types.GET_SCHOOL_REQUEST,
        payload:data
    }
}

export const GetSchoolSuccess = (data)=>{
    return {
        type: Types.GET_SCHOOL_SUCCESS,
        payload:data
    }
}

export const InsertOrUpdateSchool =(data)=>{
    return {
        type: Types.INSERT_UPDATE_SCHOOL,
        payload:data
    }
}

export const DeleteSchool =(data)=>{
    return {
        type: Types.DELETE_SCHOOL,
        payload:data
    }
}

//Holiday
export const GetHolidayRequest = (data)=>{
    return { 
        type: Types.GET_HOLIDAY_REQUEST,
        payload:data
    }
}

export const GetHolidaySuccess = (data)=>{
    return {
        type: Types.GET_HOLIDAY_SUCCESS,
        payload:data
    }
}

export const InsertOrUpdateHoliday =(data)=>{
    return {
        type: Types.INSERT_UPDATE_HOLIDAY,
        payload:data
    }
}

export const DeleteHoliday =(data)=>{
    return {
        type: Types.DELETE_HOLIDAY,
        payload:data
    }
}

export const GetAchievementByOrg= (data) => {
    return {
        type: Types.GET_ACHIEVEMENT_BY_ORG,
        payload: data
    }
}
export const GetAchievementByOrgSuccess = (data) => {
    return {
        type: Types.GET_ACHIEVEMENT_BY_ORG_SUCCESS,
        payload: data
    }
}






