import { faCheckCircle, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import Select, { components } from 'react-select';

const styles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: 0,
    boxShadow: 'none'
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      padding: "4px 8px",
      backgroundColor: undefined,
      color: isSelected ? 'white' : 'black',
      opacity: .8
    }
  },
  multiValue: (styles) => {
    return {
      ...styles,
      borderRadius: '50px',
    };
  },
};

const control = ({ children, ...props }) => {
  return <components.Control {...props}>
    <FontAwesomeIcon icon={props.isMulti ? faUsers : faUser} />
    {children}
  </components.Control>
};

const filterOption = ({ value, data }, inputValue) => {
  const { userName, employeeName, positionName, departmentName, branchName, gradeLevelName, stageName, phaseName } = data;
  if (data.stageName && !inputValue) { // single
    return true;
  }
  if (inputValue.length < 3) {
    return false;
  }
  if (userName && userName.toLowerCase().includes(inputValue.toLowerCase()))
    return true;
  if (employeeName && employeeName.toLowerCase().includes(inputValue.toLowerCase()))
    return true;
  if (positionName && positionName.toLowerCase().includes(inputValue.toLowerCase()))
    return true;
  if (departmentName && departmentName.toLowerCase().includes(inputValue.toLowerCase()))
    return true;
  if (branchName && branchName.toLowerCase().includes(inputValue.toLowerCase()))
    return true;
  if (gradeLevelName && gradeLevelName.toLowerCase().includes(inputValue.toLowerCase()))
    return true;
  if (stageName && stageName.toLowerCase().includes(inputValue.toLowerCase()))
    return true;
  if (phaseName && phaseName.toLowerCase().includes(inputValue.toLowerCase()))
    return true;
  return false

}


// Single
const singleOption = (props) => {
  const { data, isSelected } = props;
  return (
    <components.Option {...props}>
      {/* {RenderOption(data)} */}
      <div className="list-group-item" style={{ backgroundColor: isSelected ? "rgba(102, 102, 102)" : undefined }}>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left flex2">
              <div className="widget-heading">{data.employeeName} &nbsp; {data.isMainPosition ?
                <FontAwesomeIcon icon={faCheckCircle} className="text-success" title="Chức danh chính" />
                :
                <FontAwesomeIcon icon={faCheckCircle} className="text-warning" title="Chức danh kiêm nhiệm" />
              }</div>
              <div className="widget-subheading opacity-10" style={{}}>
                <div className="small">
                  <div>
                    &lt;{data.userName}@cbbank.vn&gt;
                  </div>
                  <div className={data.isMainPosition ? "pr-2 text-success" : "pr-2 text-warning"} style={{ fontWeight: 'bold' }}>
                    {data.positionName} - {data.departmentName}
                  </div>
                  <div>
                    {data.gradelLevelName} - {data.branchName}
                  </div>
                  <div>
                    {data.stageName} - {data.phaseName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </components.Option>
  )
}

const singleValue = ({ children, ...props }) => {
  const { data } = props;
  return <components.SingleValue {...props}>
    <div className="pl-2">
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left flex2">
            <div className="widget-heading">{data.employeeName} &nbsp; {data.isMainPosition ?
              <FontAwesomeIcon icon={faCheckCircle} className="text-success" title="Chức danh chính" />
              :
              <FontAwesomeIcon icon={faCheckCircle} className="text-warning" title="Chức danh kiêm nhiệm" />
            }</div>
            <div className="widget-subheading opacity-10" style={{}}>
              <div className="small">
                <div>
                  &lt;{data.stageName} - {data.phaseName}&gt;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </components.SingleValue>
}

// Multiple
const multipleOption = (props) => {
  const { data, isSelected } = props;
  return (
    <components.Option {...props}>
      <div className="list-group-item" style={{ backgroundColor: isSelected ? "rgba(102, 102, 102)" : undefined }}>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left flex2">
              <div className="widget-heading">{data.employeeName} &lt;{data.userName}@cbbank.vn&gt;</div>
              <div className="widget-subheading opacity-10" style={{}}>
                <div className="small">
                  <div>
                    {data.positionName} - {data.departmentName}
                  </div>
                  <div>
                    {data.gradelLevelName} - {data.branchName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </components.Option>
  )
}


const multiValueLabel = (props) => {
  const { data } = props;
  return <components.MultiValueLabel {...props}>
    <div className="pl-2">
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left flex2">
            <div className="widget-heading">{data.employeeName} &lt;{data.userName}@cbbank.vn&gt;</div>
            {/* <div className="widget-subheading opacity-10" style={{}}>
              <div className="small">
                <div>
                  &lt;{data.UserName}@cbbank.vn&gt;
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </components.MultiValueLabel >
}

const noOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props} >
      <span className="">Vui lòng nhập ít nhất 3 ký tự để tìm kiếm...</span>
    </ components.NoOptionsMessage>
  );
}

export default (props) => {
  const { defaultValue, value, options, onChangeSelect, isMulti } = props;
  const singleComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    Control: control,
    Option: singleOption,
    SingleValue: singleValue,
    NoOptionsMessage: noOptionsMessage
  }
  const multipleComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    Control: control,
    Option: multipleOption,
    MultiValueLabel: multiValueLabel,
    NoOptionsMessage: noOptionsMessage
  }

  return (
    <Select
      {...props}
      components={isMulti ? multipleComponents : singleComponents}
      closeMenuOnSelect={true}
      isClearable={isMulti ? true : false}
      defaultValue={defaultValue}
      value={value}
      isMulti={isMulti}
      options={options}
      styles={styles}
      onChange={onChangeSelect}
      filterOption={filterOption}
      placeholder={'Chọn cán bộ ...'}
    />
  )
}