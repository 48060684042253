
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_CATEGORY_GRADELLEVEL,
    INSERT_UPDATE_GRADELLEVEL,
    GET_DEPARTMENT_GRADELLEVEL_BRANCH,
    GET_POSITION_DEPARTMENT,
    INSERT_UPDATE_DEPARTMENT_GRADELLEVEL,
    INSERT_UPDATE_POSITION_DEPARTMENT,
    GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT,
    INSERT_UPDATE_GROUP_DEPARTMENT,
    GET_BRANCH,
    INSERT_UPDATE_BRANCH_GROUP_DEPARTMENT,
    GET_POSITION_GROUP_DEPARTMENT,
    INSERT_UPDATE_POSITION_BRANCH_GROUP_DEPARTMENT
   
   } from '../../config/constainUrl';
import { 
     GetDepartmentGradelLevelBranchRequest,
     GetGradelLevelRequest,
     GetGradelLevelSuccess,
     GetDepartmentGradelLevelBranchSuccess,
     GetPositionDepartmentSuccess,
     GetPositionDepartmentRequest,
     GetDepartmentBranchGroupDepartmentSuccess,
     GetDepartmentBranchGroupDepartmentRequest,
     GetPositionGroupDepartmentSuccess,
     GetPositionWithGroupDesDesRequest } from '../../Action/category';
import { MESSAGE_SUCCESS_DELETE } from '../../config/constaintNotify';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';

function* GetgradelLevelAll(action){
    try{ 
        const data = yield call(apiCall,"GET",GET_CATEGORY_GRADELLEVEL); 
        const branch = yield call(apiCall,"GET",GET_BRANCH); 
        if(data.code===200)   yield put(GetGradelLevelSuccess({data,branch})); 
    
        else toast.error(data.message, { theme: "red" })  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* InsertOfUpdateGradelLevel(action){
    try{   
        const response = yield call(apiCall,"POST",INSERT_UPDATE_GRADELLEVEL,action.payload); 
        if(response.code===200){
            yield put(GetGradelLevelRequest());  
            toast.success(response.message, { theme: "colored" })    
        }
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* GetDepartmentWithGradelLevel(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_DEPARTMENT_GRADELLEVEL_BRANCH}?branchId=${action.payload.branchId}&gradelLevelId=${action.payload.gradelLevelId}` ); 
        if(response.code===200)   yield put(GetDepartmentGradelLevelBranchSuccess(response)); 
        else toast.error(response.message, { theme: "red" })  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* InsertDepartmentWithGradelLevel(action){
    try{ 
        const response = yield call(apiCall,"POST",INSERT_UPDATE_DEPARTMENT_GRADELLEVEL,action.payload ); 
        if(response.code===200) {
            yield put(GetDepartmentGradelLevelBranchRequest({branchId: '00000000-0000-0000-0000-000000000000',gradelLevelId:action.payload.gradelLevelId })); 
            toast.success(response.message, { theme: "colored" })    
        }  
        else toast.error(response.message, { theme: "red" })  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* GetPositionWithDepartment(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_POSITION_DEPARTMENT}?departmentId=${action.payload.departmentId}&gradelLevelId=${action.payload.gradelLevelId}` ); 
        if(response.code===200)   yield put(GetPositionDepartmentSuccess(response)); 
        else toast.error(response.message, { theme: "red" })  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* GetPositionGroupDepartment(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_POSITION_GROUP_DEPARTMENT}?departmentId=${action.payload.departmentId}&branchId=${action.payload.branchId}` ); 
        if(response.code===200)   yield put(GetPositionGroupDepartmentSuccess(response)); 
        else toast.error(response.message, { theme: "red" })  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* InsertPositionWithDepartment(action){
    try{ 
        const response = yield call(apiCall,"POST",INSERT_UPDATE_POSITION_DEPARTMENT,action.payload.isBranch ? action.payload.param : action.payload );
         
        if(response.code===200)
            {
                if(action.payload.isBranch)
                    yield put(GetPositionWithGroupDesDesRequest( {departmentId:action.payload.param.departmentOldId, branchId: action.payload.param.branchId }))
               else yield put(GetPositionDepartmentRequest({departmentId: action.payload.departmentId,gradelLevelId:action.payload.gradelLevelId })); 
                toast.success(response.message, { theme: "colored" })    
            }
        else toast.error(response.message, { theme: "red" })  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

function* GetDepartmentBranchGroupDepartment(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT}/${action.payload.gradelLevelId}` ); 
        if(response.code===200)   yield put(GetDepartmentBranchGroupDepartmentSuccess(response)); 
        else toast.error(response.message, { theme: "red" })  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}


function* InsertGroupDepartment(action){
    try{ 
        
        const response = yield call(apiCall,"POST",INSERT_UPDATE_GROUP_DEPARTMENT,action.payload.param ); 
        if(response.code===200)
            {
                yield put(GetDepartmentBranchGroupDepartmentRequest( {gradelLevelId: action.payload.gradelLevel} )); 
                toast.success(response.message, { theme: "colored" })    
            }
        else toast.error(response.message, { theme: "red" })  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}


function* InsertBranchGroupDepartment(action){
    try{ 
        
        const response = yield call(apiCall,"POST",INSERT_UPDATE_POSITION_BRANCH_GROUP_DEPARTMENT,action.payload ); 
        if(response.code===200)
            {
                
                yield put(GetDepartmentBranchGroupDepartmentRequest( {gradelLevelId: action.payload.gradelLevelId[0]  } )); 
                toast.success(response.message, { theme: "colored" })    
            }
        else toast.error(response.message, { theme: "red" })  
    }
    catch(e){
        toast.error(e.message, { theme: "red" })    
    }
}

export default function* Organization(){
    yield takeEvery(Type.GET_GRADELLEVEL_REQUEST, GetgradelLevelAll);
    yield takeEvery(Type.INSERT_UPDATE_GRADELLEVEL, InsertOfUpdateGradelLevel);
    yield takeEvery(Type.GET_DEPARTMENT_GRADELLEVEL_BRANCH_REQUEST, GetDepartmentWithGradelLevel);
    yield takeEvery(Type.INSERT_UPDATE_DEPARTMENT_GRADELLEVEL, InsertDepartmentWithGradelLevel);
    yield takeEvery(Type.GET_POSITION_DEPARTMENT_REQUEST, GetPositionWithDepartment);
    yield takeEvery(Type.GET_POSITION_GROUP_DEPARTMENT_REQUEST, GetPositionGroupDepartment);
    
    yield takeEvery(Type.INSERT_UPDATE_POSITION_DEPARTMENT, InsertPositionWithDepartment);
    yield takeEvery(Type.GET_DEPARTMENT_BRANCH_GROUP_DEPARTMENT_REQUEST, GetDepartmentBranchGroupDepartment);
    yield takeEvery(Type.INSERT_UPDATE_GROUP_DEPARTMENT, InsertGroupDepartment);
    yield takeEvery(Type.INSERT_UPDATE_BRANCH_GROUP_DEPARTMENT, InsertBranchGroupDepartment);
    
    

    
}