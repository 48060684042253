import React, { useEffect, useState } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Table } from "reactstrap";
import { GetApplicationCancelBoxRequest, ResetApplicationCancelState } from "../../../Action/detail";
import { TotalHandlingUpdate } from '../../../Action/total-handling';
import Fitter from "../../../Components/Fitter";
import Paginate from "../../../Components/Paginate";
import { pathInbox } from "../../../Service/Inbox/inbox-service";

import Item from "./Item"
import CancelRequest from "../Detail/cancel-request";
//import './style.css';

export default function ApplicationCancelBox(props) {
  // const path = props.match.path.split("/");
  const dispatch = useDispatch();
  const applicationCancels = useSelector(state => state.ApplicationCancel.applicationCancels);
  const TotalHandling = useSelector(state => state.ApplicationCancel.totalApplicationCancels);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [applicationSelected, setApplicationSelected] = useState(null);
  const [isFilter, setIsFilter] = useState(
    {
      itemPerPage: 10,
      pageIndex: 1,
      applicationName: ''
    });

  
  function pagination(pageIndex) {
    setIsFilter({ ...isFilter, pageIndex: pageIndex })
  }
  function setFilter(e) {
    const applicationName = e.applicationName;    
    setIsFilter({ ...isFilter, applicationName: applicationName })
  }  

  const closeModalHandler = () => {
    setShowDetail(false);
    dispatch(GetApplicationCancelBoxRequest(isFilter));
    dispatch(ResetApplicationCancelState());
  }
  const openModalHandler = (application) => {
    setApplicationSelected(application);
    setShowDetail(true);
  }

  useEffect(() => {
    dispatch(GetApplicationCancelBoxRequest(isFilter));
  }, [isFilter]);



  return (
    <>
    <Col md="12">
      <Card className="main-card mb-3 inbox-main">
        <CardBody>
        <form>
          <Table bordered hover className="table-application" >
            <thead>
              <tr>
                <th className="text-center"><i onClick={() => setIsOpenSearch(() => !isOpenSearch)} className={isOpenSearch ? "pe-7s-angle-down-circle pe-7s-rotate" : "pe-7s-angle-down-circle"}> </i></th>
                <th className="text-center">HỒ SƠ
                </th>
                <th className="text-center">NỘI DUNG</th>
                <th className="text-center"> LÝ DO XIN HUỶ</th>
                <th  className="text-center"> TRẠNG THÁI</th>
              </tr>
              {isOpenSearch ? <Fitter setFitter ={setFilter}  />  : null}
            </thead>
            <tbody>
              {
                applicationCancels.map((application, index) => (
                  <Item key={application.applicationLogId} 
                    item={application} 
                    index={index}                                         
                    openModalHandler={openModalHandler}
                    />
                ))
              }
            </tbody>
          </Table>
          </form>
         
          {TotalHandling == 0 ? null : <Paginate pagination={pagination} total={TotalHandling}
            pageSize={isFilter.itemsPerPage}
            pageIndex={isFilter.pageIndex} />}
        </CardBody>
      </Card>
    </Col>
    {
      showDetail && applicationSelected && (
        <CancelRequest isOpen={showDetail}       
        id={applicationSelected.id}  
        applicationLogId={applicationSelected.applicationLogId}
        isUserApprove={true}
        handleCloseModal={closeModalHandler}
        />
      )
    }
    </>
  );

}
