
import { HOST_API } from '../config/constaints';
import axios from 'axios'
import { getValueCrypt } from '../utils/crypt'
const client = axios.create({
    baseURL: HOST_API,
     headers: {
      "Accept": 'application/json;charset=UTF-8'
  }
});

client.interceptors.request.use(function (config) {
    const token = getValueCrypt('JWT_TOKEN');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

export const apiCall = function (method, route, body = null) {
    const onSuccess = function (response) {
        if(response.status===401)
            window.location ='/login';
        else  if (response.status===200)
                    if(response.data.code === 401)
                        window.location ='/login';
                    if(response.data.code === 101)
                        window.location ='/notFound';
        return response.data;
    }
    const onError = function (error) {
        if(error.response != undefined )
            if (error.response.status ==401) 
               window.location ='/login';
            
            else if( error.response.data.Error !== undefined){
                     if( error.response.data.Error.Message === "Unauthorized")
                         window.location ='/login';
            }
        return Promise.reject(error.response || error.message);
    }
    return client({
        method,
        url: route,
        data: body
    }).then(onSuccess)
        .catch(onError);
}


