
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_GROUP_SALARY_RANK_All,
    GET_BY_ID_GROUP_SALARY_RANK,
    GET_BY_SALARY_RANK,
    INSERT_UPDATE_GROUP_SALARY_RANK,
    DELETE_GROUP_SALARY_RANK,
    INSERT_UPDATE_SALARY_RANK,
    DELETE_SALARY_RANK,
    INSERT_UPDATE_SALARY_LEVE,
    DELETE_SALARY_LEVE
   } from '../../config/constainUrl';
import { 
    GetGroupSalaryRankAllRequest,
     GetGroupSalaryRankAllSuccess,
     GetByIdGroupSalaryRankSuccess,
     GetBySalaryRankSuccess,
     GetByIdGroupSalaryRankRequest,
     GetBySalaryRankRequest  } from '../../Action/category';
import { MESSAGE_SUCCESS_DELETE } from '../../config/constaintNotify';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index';
import { toast } from 'react-toastify';

function* GetGroupSalaryRankAll(action){
    try{ 
        const response = yield call(apiCall,"GET",GET_GROUP_SALARY_RANK_All); 
        if(response.code===200)
             yield put(GetGroupSalaryRankAllSuccess(response));  
    }
    catch(e){
     
    }
}

function* GetByIdGroupSalaryRankAll(action){
    
    try{ 
        const response = yield call(apiCall,"GET",`${GET_BY_ID_GROUP_SALARY_RANK}/${action.payload}`); 
        if(response.code===200)
        {
            yield put(GetByIdGroupSalaryRankSuccess(response));  
            if(response.data.length === 0){ 
                yield put(GetBySalaryRankSuccess (response))
            }
        }    
    }   
    catch(e){
      
    }
}

function* InsertOfUpdateGroupSalaryRank(action){
    try{ 
        const response = yield call(apiCall,"POST",INSERT_UPDATE_GROUP_SALARY_RANK,action.payload); 
        if(response.code===200)
        {
            toast.success(response.message, { theme: "colored" })
            yield put(GetGroupSalaryRankAllRequest());  
        }
        else  toast.error(response.message, { theme: "red" })    
    }
    catch(e){
       
    }
}

function* DeleteGroupSalaryRank(action){
    
    try{ 
        const response = yield call(apiCall,"DELETE",`${DELETE_GROUP_SALARY_RANK}/${action.payload}`); 
        if(response.code===200){
            toast.success(MESSAGE_SUCCESS_DELETE, { theme: "colored" })
            yield put(GetGroupSalaryRankAllRequest());  
        }
        else  toast.error(response.message, { theme: "red" })   
    }
    catch(e){
      
    }
}

function* GetBySalaryRank(action){
    try{ 
        const response = yield call(apiCall,"GET",`${GET_BY_SALARY_RANK}/${action.payload}`); 
        if(response.code===200)
             yield put(GetBySalaryRankSuccess (response));  
    }
    catch(e){
       
    }
}

function* InsertOfUpdateSalaryRank(action){
    try{ 
        const response = yield call(apiCall,"POST",INSERT_UPDATE_SALARY_RANK,action.payload); 
        if(response.code===200)
        {
            toast.success(response.message, { theme: "colored" })
            yield put(GetByIdGroupSalaryRankRequest(action.payload.IdGroupSalaryRank));  
        }
        else  toast.error(response.message, { theme: "red" }) 
    }
    catch(e){
       
    }
}

function* DeleteSalaryRank(action){
    try{ 
        const response = yield call(apiCall,"DELETE",`${DELETE_SALARY_RANK}/${action.payload.Id}`); 
        if(response.code===200){

            toast.success(response.message, { theme: "colored" })
            yield put(GetByIdGroupSalaryRankRequest(action.payload.IdGroupSalaryRank));  
        }
        else  toast.error(response.message, { theme: "red" })
    }
    catch(e){
      
    }
}


function* InsertOfUpdateSalaryLeve(action){
    try{ 
        const response = yield call(apiCall,"POST",INSERT_UPDATE_SALARY_LEVE,action.payload); 
        if(response.code===200)
        {
            toast.success(response.message, { theme: "colored" })
            yield put(GetBySalaryRankRequest(action.payload.IdSalaryRank));  
        }
        else  toast.error(response.message, { theme: "red" })    
    }
    catch(e){
      
    }
}

function* DeleteSalaryLeve(action){
    
    try{ 
        const response = yield call(apiCall,"DELETE",`${DELETE_SALARY_LEVE}/${action.payload.Id}`); 
        if(response.code===200){

            toast.success(response.message, { theme: "colored" })
            yield put(GetBySalaryRankRequest(action.payload.IdSalaryRank));  
        }
        else  toast.error(response.message, { theme: "red" })
           
    }
    catch(e){
     
    }
}

export default function* Salary(){
    yield takeEvery(Type.GET_GROUP_SALARY_RANK_All_REQUEST, GetGroupSalaryRankAll);
    yield takeEvery(Type.GET_BY_ID_GROUP_SALARY_RANK_REQUEST, GetByIdGroupSalaryRankAll);
    yield takeEvery(Type.GET_BY_SALARY_RANK_REQUEST, GetBySalaryRank);
    yield takeEvery(Type.INSERT_UPDATE_GROUP_SALARY_RANK, InsertOfUpdateGroupSalaryRank);
    yield takeEvery(Type.DELETE_GROUP_SALARY_RANK, DeleteGroupSalaryRank);
    yield takeEvery(Type.INSERT_UPDATE_SALARY_RANK, InsertOfUpdateSalaryRank);
    yield takeEvery(Type.DELETE_SALARY_RANK, DeleteSalaryRank);
    yield takeEvery(Type.INSERT_UPDATE_SALARY_LEVE, InsertOfUpdateSalaryLeve);
    yield takeEvery(Type.DELETE_SALARY_LEVE, DeleteSalaryLeve);
}