

const  getDate = (data) =>{
   const result =[];
   const root =[...new Set(data.map(item => item.parentName))]
   for (let i = 0; i < root.length; i++) {
    var items = {
        label:  root[i],
        options: []
      }
      var child =data.filter(x => x.parentName === root[i]);
      // eslint-disable-next-line no-loop-func
      child.forEach( e=> {
        var  childOption = {
            label: e.productName  + " - " + e.stageName + " - " + e.positionName + " - " + e.departmentName,
            value: e.productId + "_" + e.stageId +  "_" + e.positionId 
        };
        items.options.push(childOption);
      });
      result.push(items);
  }
    return result;
}

export  const CreateService = {
  getDate
};
