import React, { Fragment, useEffect, useState } from "react";
import Iframe from 'react-iframe';
import { HOST_IFRAME } from '../../config/constaints';
import Skeleton from 'react-loading-skeleton';
import { GetDataJsonLog, GetFormsInLog } from "../../Service/ApplicationDetail";
import { useDispatch, useSelector } from 'react-redux';
import { printFormAddContent } from "../../utils/print-form";
import { FormWithDaJsonRequest} from "../../Action/detail"
import { getValueCrypt } from "../../utils/crypt";
import Comment from "../../Components/CommentDiscuss/Comment";

export default function PrintFormBBH(props) {

  const params = props.match.params;
  const [dataLog, setDataLog] = useState(null);
  const [forms, setForms] = useState([]);
  const token = getValueCrypt("JWT_TOKEN");
  const valueFormWithDataJson= useSelector(state =>state.FormWithDataJson);
  const dispatch = useDispatch();
  const urlIframe = `${HOST_IFRAME}#/iframe`;

  useEffect(() => {
    const runEffect = async () => {
      const data = await getFormData(params.appId, params.logId);
      const listForm = await getFormInLog(params.appId, params.logId);
      setDataLog(data);
      setForms(listForm);
    }
    runEffect();
  }, [])

  const handleMapData = async (e) => {
    let _form = forms.find(o => o.id === params.formId)
    if (_form.parentId)
      _form = forms.find(o => o.id === _form.parentId)
    _form.isNew = false
    let appDetail = {
      productId: params.productId,
      id: params.appId,
      lastLogId: params.logId,
      formId: _form.id,
      isUserDiscuss: "1",
      userId: params.userId,
      allowEdit: false
    }

    let auth = {
      userId: params.userId
    }
    e.target.contentWindow.postMessage(
      {
        appDetail: appDetail,
        formData: _form,
        dataLog: dataLog,
        auth,
        token,
        key: "HandleSelect_PXYK"
      }, HOST_IFRAME);
  }
  const getFormData = async (appId, logId) => {
    let data = '';
    await GetDataJsonLog(appId, logId).then(res => {
      if (res.code === 200) {
        data = res.data;
      }
    })
    return data;
  }
  const getFormInLog = async (appId, logId) => {
    let forms = [];
    await GetFormsInLog(appId, logId).then(res => {
      if (res.code === 200) {
        forms = res.data;
      }
    })
    return forms;
  }
  //Print
  const handlePrint = () => {
    var html = document.getElementsByClassName('comment-print')[0].innerHTML;
    printFormAddContent("View_Form", html);
  }

  return (
    <Fragment>
      <div size="xl">
        <h6 >
          <div className="col-12 header-view">
            <span className="col-md-6 pull-left"><label><b>CHI TIẾT BIỄU MẪU</b> </label></span>
            <div className="col-md-6 pull-right">
              <button onClick={() => handlePrint()} className="mr-2 btn-hover-shine btn btn-info pull-right"><i className="lnr-printer"></i>  Print</button>
            </div>
            <Comment 
                userAssginId={params.userId}
                logId ={params.logId} 
                users={[]} appid={params.appId}
                appName={"Name"} 
                userAppId={''}
                bactiveTab={"1"}
                userLoginId={params.userId}
                iSelected={""}
               
                  />
          </div>
        </h6>
        <div >
          {dataLog ?
            <Iframe
              src={urlIframe}
              width="100%"
              height="900"
              id="View_Form"
              key='View_Form'
              onLoad={async (e) => {
                await handleMapData(e);
              }}
            /> : <Skeleton count={10} />
          }
        </div>
        <Comment
          userAssginId={params.userId}
          logId={params.logId}
          users={[]} appid={params.appId}
          appName={"Name"}
          userAppId={''}
          bactiveTab={"1"}
          userLoginId={params.userId}
          iSelected={""}
        />
      </div>
    </Fragment>

  );
}
