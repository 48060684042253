import * as Types from '../config/constaints'


export const TimeKeeperUserRequest = (data)=>{
    return { 
        type: Types.TIMEKEEPER_USER_REQUEST,
        payload:data
    }
}

export const TimeKeeperUserSuccess = (data)=>{
    return { 
        type: Types.TIMEKEEPER_USER_SUCCESS,
        payload:data
    }
}

export const GetTimeKeeperRequest = (data)=>{
    return { 
        type: Types.GET_TIMEKEEPER_REQUEST,
        payload:data
    }
}


export const GetTimeKeeperSuccess = (data)=>{
    return { 
        type: Types.GET_TIMEKEEPER_SUCCESS,
        payload:data
    }
}

export const UpdateTimeKeeperPageIndex = (data)=>{
    return { 
        type: Types.UPDATE_TIMEKEEPER_PAGE_INDEX,
        payload:data
    }
}

export const UpdateTimeKeeperYear = (data)=>{
    return { 
        type: Types.UPDATE_TIMEKEEPER_YEAR,
        payload:data
    }
}

export const UpdateTimeKeeperMonth = (data)=>{
    return { 
        type: Types.UPDATE_TIMEKEEPER_MONTH,
        payload:data
    }
}

export const ExportTimeKeeperMonth = (data)=>{
    return { 
        type: Types.EXPORT_TIMEKEEPER,
        payload:data
    }
}

export const SaveLatch = (data)=>{
    return { 
        type: Types.SAVE_LATCH,
        payload:data
    }
}

export const UpdateIsLatch = (data)=>{
    return { 
        type: Types.UPDATE_ISLATCH_TIMEKEEPER_SUCCESS,
        payload:data
    }
}

export const UpdateDayLatch = (data)=>{
    return { 
        type: Types.UPDATE_DAY_LATCH,
        payload:data
    }
}

export const UpdatePublicDay = (data)=>{
    return { 
        type: Types.UPDATE_PUBLIC_DAY,
        payload:data
    }
}

export const SaveTimeSheetSalary = (data)=>{
    return { 
        type: Types.SAVE_TIMESHEET_SALARY,
        payload:data
    }
}

export const GetHolidayWithMonthYearRequest=(data)=>{

    return {
        type: Types.GET_HOLIDAY_MONTH_YEAR_REQUEST,
        payload:data
    }
}

export const GetHolidayWithMonthYearSuccess=(data)=>{

    return {
        type: Types.GET_HOLIDAY_MONTH_YEAR_SUCCESS,
        payload:data
    }
}

export const SaveTimeKeeperWithAdmin=(data)=>{

    return {
        type: Types.SAVE_DATA_TIMEKEEPER_WITH_ADMIN,
        payload:data
    }
}

export const checkPermissionManipulateRequest=(data)=>{

    return {
        type: Types.UPDATE_PERMISSION_MANIPULATE_REQUEST,
        payload:data
    }
}

export const checkPermissionManipulateSuccess=(data)=>{

    return {
        type: Types.UPDATE_PERMISSION_MANIPULATE_SUCCESS,
        payload:data
    }
}




