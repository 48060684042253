export const MESSAGE = "Thông báo";
export const MESSAGE_ERROR = "Có lỗi xãy ra";
export const MESSAGE_SUCCESS_UPDATE= "Bạn đã cập nhật thông tin thành công";
export const MESSAGE_ERROR_UPDATE= "Bạn đã cập nhật thông tin không thành công";
export const MESSAGE_CONFIRM_DELETE = "Bạn có muốn xóa thông tin này hay không";
export const MESSAGE_SUCCESS_DELETE = "Bạn đã xóa thông tin này thành công";
// FILE
export const MESSAGE_ERROR_DELETE_FILE = "Bạn không thể xóa file";
export const MESSAGE_SUCCESS_ADD_FILE = "Bạn đã thêm file thành công";
export const MESSAGE_SUCCESS_DELETE_FILE = "Bạn đã xóa file thành công";
export const MESSAGE_ERROR_FILE_REQUIRE = "Vui lòng update đầy đủ file bắt buộc";
// Form
export const MESSAGE_ERROR_VALIDTAION_FORM = "Bạn vui lòng nhập đầy đủ thông tin";
// Discuss
export const MESSAGE_DISCUSS_SAVE = "Bạn có muốn gửi thảo luận hay không";
export const MESSAGE_ERROR_VALIDTAION_FORM_DISCUSS = "Thảo luận chưa được gửi, Anh/Chị vui lòng nhập đầy đủ thông tin";
export const MESSAGE_ERROR_VALIDTAION_FORM_DISCUSS_NOT_USERDISCUSS = "Thảo luận chưa được gửi, Anh/Chị vui lòng chọn người thảo luận";
export const MESSAGE_ERROR_USER = "Bạn chưa chọn người chuyển";
export const MESSAGE_RESET_PASSWORD = "Bạn có muốn resset password hay không";
export const MESSAGE_PRODUCT_RECRUIT = "Chưa xác định được bước khởi tạo";
export const  MESSAGE_TEXT_BLOCK="Anh chị vui lòng trình trong các quy trình sản phẩm như sau:\n 1. Tuyển dụng \n 2.Quy hoạch \n 3.Bổ nhiệm \n 4.Điều động/Luân chuyển \n 5.Thi đua khen thưởng \n 6.Xử lý kỷ luật \n 7.Quản lý cán bộ \n Nhằm mục đích để cập nhật profile cán bộ được đồng bộ";
export const MESSAGE_FORM_01 ="Vui lòng lưu biểu mẫu"
export const MESSAGE_FORM_02 ="Vui lòng cập nhật profile trước khi chuyển tiếp"
export const MESSAGE_SUPPORT_IT ="KHÔNG TÌM THẤY BƯỚC TRẢ LẠI! VUI LÒNG LIÊN HỆ VỚI IT";


