
import React, { useState } from 'react';
export default function Fitter( props ) {

  const [isFiiter, setFiiter] = useState({applicationName: null,sendUserName:null,description:null})
  const handleSubmit = () => {
     props.setFitter(isFiiter);
  };

  const handleChange =(e ) =>{
    setFiiter( {...isFiiter,[e.target.name] : e.target.value })
  }
    
    return (
      <tr>
            <td className="text-center vertical-align">
               <a  onClick={ handleSubmit }  ><i className="pe-7s-search font-weight"></i></a>
            </td>
          <td  >
            <input placeholder='Nhập số hồ sơ' autoComplete='off'  onChange={(event) => handleChange(event) } name='applicationName'  value={isFiiter.applicationName}  type="text" className="mb-2 form-control-sm form-control" />      
          </td>
          <td>
              <input   placeholder='Nhập UserName'  autoComplete='off'  onChange={(event) => handleChange(event) } name='sendUserName' value={isFiiter.sendUserName}   type="text" className="mb-2 form-control-sm form-control" /> 
          </td>
          <td colspan={ props.conspanDes ? props.conspanDes : 1}>
              <input   placeholder='Nhập trích yếu'   autoComplete='off'  onChange={(event) => handleChange(event) } name='description' value={isFiiter.description}   type="text" className="mb-2 form-control-sm form-control" />    
          </td>
       </tr>
     
    )

}