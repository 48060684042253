import axios from "axios";
import { HOST_API,HOST_HRM_V1 } from '../../config/constaints';
import {
    FLOW_GET_USERS, GET_EMPLOYEE_DEPARTMENT
} from '../../config/constainUrl';
import { getValueCrypt } from "../../utils/crypt"


// flowGetUsers: Lấy tất cả user đang hoạt động.
export const flowGetUsers = async () => {
    return await axios({
        method: 'get',
        url: `${HOST_API}/${FLOW_GET_USERS}`,
        headers: {
            "Authorization": 'Bearer ' + getValueCrypt("JWT_TOKEN"),
            'Content-Type': 'application/json'
        }
    })
}

// Lấy danh sách user theo phòng ban
export const getUserWithDepartment = async (desId) => {
    return await axios({
        method: 'get',
        url: `${HOST_HRM_V1}/${GET_EMPLOYEE_DEPARTMENT}`,
        headers: {
            "Authorization": 'Bearer ' + getValueCrypt("JWT_TOKEN"),
            'Content-Type': 'application/json'
        }
    })
}