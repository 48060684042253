/* eslint-disable default-case */

import * as Types from "../../config/constaints";

const  initialState= {
  data:null,
  isError: false,
  messageError: null
  };

  export default function EmployeeFormDataJson (state =initialState,action ){
    
    switch (action.type) { 
          case Types.EMPLOYEE_FROM_DATAJSON_SUCCESS:{ 
                if(action.payload.code !== 200)
                  return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
                else {
                  return  {...state,data:  action.payload.data };
                }
          }
          case Types.EMPLOYEE_UPDATE_FROM_DATAJSON_SUCCESS:{ 
              let mapData = {...state};
              mapData.data.dataJson =action.payload
              return  {...state,data: mapData.data };
      }
  }
  return state;
}

