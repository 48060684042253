import * as Types from '../config/constaints'


// thông tin hs
export const InforRequest = (data)=>{
    return { 
        type: Types.DETAIL_REQUEST,
        payload:data
    }
}

export const InforSuccess= (data)=>{
    return { 
        type: Types.DETAIL_SUCCESS,
        payload:data
    }
} //-------------------------

// Form với data log
export const FormWithDaJsonRequest= (data)=>{
    return { 
        type: Types.FORM_DATAJSON_REQUEST,
        payload:data
    }
}

export const FormWithDaJsonSuccess= (data)=>{
    return { 
        type: Types.FORM_DATAJSON_SUCCESS,
        payload:data
    }
}

export const FormWithDaJsonPrintSuccess= (data)=>{
    return { 
        type: Types.FORM_DATAJSON_PRINT_SUCCESS,
        payload:data
    }
}

export const UpdateFormDaJsonSuccess= (data)=>{
        return { 
            type: Types.DETAIL_UPDATE_DATALOG,
            payload:data
        }
} 

export const UpdateFormJsonSuccess= (data)=>{
    return { 
        type: Types.DETAIL_UPDATE_FORM,
        payload:data
    }
} 

export const DeleteApplicationRequest= (data)=>{
    return { 
        type: Types.DELETE_APPLICATION_REQUEST,
        payload:data
    }
}

export const GetTreeApplicationRequest= (data)=>{
    return { 
        type: Types.TREE_APPLICATION_REQUEST,
        payload:data
    }

}

export const GetTreeApplicationSuccess= (data)=>{
    return { 
        type: Types.TREE_APPLICATION_SUCCESS,
        payload:data
    }

}

export const GetListEmployeeChangeDeadlineRequest = (data)=>{
    return { 
        type: Types.GET_LIST_EMPLOYEE_CHANGE_DEADLINE_REQUEST,
        payload:data
    }
}

export const GetListEmployeeChangeDeadlineSuccess= (data)=>{
    return { 
        type: Types.GET_LIST_EMPLOYEE_CHANGE_DEADLINE_SUCCESS,
        payload:data
    }

}

export const InsertListEmployeeChangeDeadline= (data)=>{
    return { 
        type: Types.INSERT_LIST_EMPLOYEE_CHANGE_DEADLINE,
        payload:data
    }

}

export const GetListApproveChangeDeadlineRequest = (data)=>{
    return { 
        type: Types.GET_LIST_APPROVE_CHANGE_DEADLINE_REQUEST,
        payload:data
    }
}

export const GetListApproveChangeDeadlineSuccess= (data)=>{
    return { 
        type: Types.GET_LIST_APPROVE_CHANGE_DEADLINE_SUCCESS,
        payload:data
    }

}

// CANCEL APPLICATION
export const GetApplicationCancelInfoRequest = data => {
    return {
        type: Types.GET_APPLICATION_CANCEL_INFO_REQUEST,
        payload: data
    }
}
export const GetApplicationCancelInfoSuccess = data => {
    return {
        type: Types.GET_APPLICATION_CANCEL_INFO_SUCCESS,
        payload: data
    }
}

export const GetApplicationCancelBoxRequest = data => {
    return {
        type: Types.GET_APPLICATION_CANCEL_BOX_REQUEST,
        payload: data        
    }
}
export const GetApplicationCancelBoxSuccess = data => {
    return {
        type: Types.GET_APPLICATION_CANCEL_BOX_SUCCESS,
        payload: data
    }
}
export const UpdateApplicationCancelCreateComment = data => {
    return {
        type: Types.UPDATE_APPLICATION_CANCEL_CREATE_COMMENT,
        payload: data
    }
}
export const UpdateApplicationCancelApproveComment = data => {
    return {
        type: Types.UPDATE_APPLICATION_CANCEL_APPROVE_COMMENT,
        payload: data
    }
}
export const UpdateApplicationCancelAccept = data => {
    return {
        type: Types.UPDATE_APPLICATION_CANCEL_ACCEPT,
        payload: data
    }
}
export const ResetApplicationCancelState = data => {
    return {
        type: Types.RESET_APPLICATION_CANCEL_STATE
    }
}