import { HOST_IFRAME } from '../config/constaints';


const parseSelect = (container) => {
  const selects = container.querySelectorAll("input[type='radio']");
  selects.forEach(select => {
    const newElement = document.createElement('span');
    newElement.innerHTML = select.checked ? '&#9745;' : '&#9744;';
    select.parentNode.insertBefore(newElement, select.nextSibling);
    select.remove();
  });
}
const parseInput = (container) => {
  const inputs = container.querySelectorAll("input, textarea");
  inputs.forEach(input => {
    const inputValue = input.value;
    const newElement = document.createElement('span');    
    newElement.textContent = inputValue;
    input.parentNode.insertBefore(newElement, input.nextSibling);
    input.remove();
  });
}

const parseFormContent = (rawData) => {
  let tempDiv = document.createElement('div'); 
  
  tempDiv.innerHTML = rawData;
  const content = tempDiv.querySelector('section').innerHTML;
  var parentWindow = window.open('');
  parentWindow.document.open();
  parentWindow.document.write(`<head> <link href="${HOST_IFRAME}/css/codepen.css" rel="stylesheet"/> <link rel="stylesheet" href="${HOST_IFRAME}/lib/architect/main.styles.css" /> <link rel="stylesheet" href="${HOST_IFRAME}/css/styleCustom.css" /></head>`);
  parentWindow.document.write('<html><body onload="window.print()">' + content + '</body></html>');
  parentWindow.document.close();
}

const parseFormAddContent = (rawData,html) => {
  rawData = rawData +  html;
  var parentWindow = window.open('');
  parentWindow.document.open();
  parentWindow.document.write(`<head> <link href="${HOST_IFRAME}/css/codepen.css" rel="stylesheet"/> <link rel="stylesheet" href="${HOST_IFRAME}/lib/architect/main.styles.css" /> <link rel="stylesheet" href="${HOST_IFRAME}/css/styleCustom.css" /></head>`);
  parentWindow.document.write('<html><body onload="window.print()">' + rawData + '</body></html>');
  parentWindow.document.close();
}

export const printForm = (selector) => {  
  let content = '';
  document.getElementById(selector).contentWindow.postMessage("GetFormContent", HOST_IFRAME);
  window.addEventListener('message', (event) => {
    if (event.data.name === 'form-content') {
      content = event.data.formContent;
      parseFormContent(content);
    }
  }, {once: true})
}

export const printFormAddContent = (selector,html) => {  
  let content = '';
  document.getElementById(selector).contentWindow.postMessage("GetFormContent", HOST_IFRAME);
  window.addEventListener('message', (event) => {
    if (event.data.name === 'form-content') {
      content = event.data.formContent;
      parseFormAddContent(content,html);
    }
  }, {once: true})
}

