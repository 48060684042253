
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import { flowGetUsers } from '../../Service/User';
import { userRequest, userSuccessfully, userFailure } from "../../Action/user";

function* getUsers(action) {
    try {
        const response = yield call(flowGetUsers, action.payload); // Goi API
        if (response.data.code === 200) {
            yield put(userSuccessfully(response.data));  // thanh cong tra ra data va dispatch Reducers
        }
        else yield put(userFailure(response.data));

    }
    catch (e) {
        // yield put(userFailure(response));
        console.error(e);
    }
}

export default function* User() {
    yield takeEvery(Type.USER_REQUEST, getUsers);
}