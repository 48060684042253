import * as Types from '../config/constaints'


export const GetProductRequest = ()=>{
    return { 
        type: Types.GET_PRODUCT_REQUEST
    }
}
export const GetProductSuccess = (data)=>{
    return {
        type: Types.GET_PRODUCT_SUCCESS,
        payload:data
    }
}


export const InserOrUpdateUserCCdefault = (data)=>{
    return {
        type: Types.INSERT_UPDATE_USERCCDEFAULT,
        payload:data
    }
}

export const GetUserCCdefaultRequest = (data)=>{
    return {
        type: Types.GET_USERCC_DEFAULT_REQUEST,
        payload:data
    }
}

export const GetUserCCdefaultSuccess = (data)=>{
    return {
        type: Types.GET_USERCC_DEFAULT_SUCCESS,
        payload:data
    }
}



