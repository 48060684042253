import * as Types from "../../config/constaints";
import { AuthenticationService } from  '../../Service/Account/authenticationService'

const initialState = {
  loggedIn: false,
  isError: false,
  messageError: null
  };

  export default function Account (state =initialState,action ){
    
    switch (action.type) { 
        case Types.LOGIN_REQUEST:{
            return  {...state}  
        }
        case Types.LOGIN_SUCCESS:{
            AuthenticationService.storeInfo(action.payload);
            return  {...state, loggedIn: true};
      }
      case Types.LOGIN_FAIL:{
          return {...state, isError: true ,messageError: ""}
    }
      case Types.LOGOUT:{
        AuthenticationService.removeStorage();
        return  {...state}  
    }
        default:
          break;
    }
    return state;
  }

