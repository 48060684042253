
import * as Types from "../../config/constaints";
import { getIndexArrayWithId } from "../../Service/Hepper/hepper-service"
const  initialState= {
  data: {
    list: [],
    total: 0
  },
  isError: false,
  messageError: null,
  };

  export default function Inbox (state =initialState,action ){
    switch (action.type) { 
      case Types.INBOX_SUCCESS:{    
        if(action.payload.code !== 200)
          return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
        else {
            
          return  {...state
            ,data: {
              list: action.payload.data.list,
              total: action.payload.data.total
          },
             };
        }
       
   }

   case Types.INBOX_ISFOLLOW_SUCCESS:{ 
         // Cập nhật HS theo dõi 
    if(action.payload.response.code !== 200)
      return {...state, isError: action.payload.message.length > 0 ? true : false,messageError: action.payload.message}
    else {
        
        let index = getIndexArrayWithId(action.payload.id,state.data.list);
        var arrayNew =  [...state.data.list];
        arrayNew[index].isFollow =action.payload.response.data
        return  {...state,data: { list: arrayNew }
      
      };
    }
  }
       default:
          return state;
    }
    return state;
  }

