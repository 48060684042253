
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Type from '../../config/constaints';
import { apiCall } from '../../API/index'; 
import { TOTAL_HANDLING_REQUEST, TotalHandlingSuccess  } from '../../Action/total-handling';
import { GetTotalHandling } from '../../Service/TotalHandling/total-handling'


function* LoadTotalHandling(action){
    try{
        const response = yield call(GetTotalHandling); // Goi API
        if(response.code===200)
             yield put(TotalHandlingSuccess(response));
    }
    catch(e){
      
    }
}

export default function* TotalHandling(){
    yield takeEvery(Type.TOTAL_HANDLING_REQUEST, LoadTotalHandling);
}