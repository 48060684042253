import * as Types from "../../config/constaints";

const initialState = {
    data: null,
    products:[],
    isSuccess: false,
    message: ""
}

const UserCcDefault = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_PRODUCT_SUCCESS:{
             return { ...state,products: action.payload.data }
        } 
        case Types.GET_USERCC_DEFAULT_SUCCESS:{
             return { ...state,data: action.payload.data }
        } 
        default:
            break;
    }
    return state;
}

export default UserCcDefault;