import * as Types from '../config/constaints'


export const InboxRequest = (data)=>{
 
    return { 
        type: Types.INBOX_REQUEST,
        payload:data
    }
}

export const InboxSuccess = (data)=>{
    return {
        type: Types.INBOX_SUCCESS,
        payload:data
    }
}

export const InboxIsFollowRequest = (data)=>{
    return {
        type: Types.INBOX_ISFOLLOW_REQUEST,
        payload:data,
        func: data.func
    }
}
export const InboxIsFollowSuccess = (data)=>{
    return {
        type: Types.INBOX_ISFOLLOW_SUCCESS,
        payload:data
    }
}




