import * as Types from '../config/constaints'


export const AddSelectRequest = ()=>{
    return { 
        type: Types.ADD_SELECT_REQUEST
    }
}
export const DataSelectAdd = (data)=>{
    return {
        type: Types.DATA_SELECT_ADD,
        payload:data
    }
}

export const AddRequest = (data)=>{
    return { 
        type: Types.ADD_REQUEST,
        payload:data
    }
}

export const AddSuccess = (data)=>{
    return {
        type: Types.ADD_SUCCESS,
        payload:data
    }
}
export const AddCVOnlineRequest = (data)=>{
    return { 
        type: Types.ADD_CV_ONLINE_REQUEST,
        payload:data
    }
}
export const AddCVOnlineSuccess = (data)=>{
    return {
        type: Types.ADD_CV_ONLINE_SUCCESS,
        payload:data
    }
}

export const AddAdppDMSRequest  = (data)=>{
    return {
        type: Types.ADD_APP_DMS_SUCCESS,
        payload:data
    }
}


