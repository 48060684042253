import "./polyfills";
import React  from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router,Switch,Route  } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Pages/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './style-app.css';
import Login from "./Pages/Login/index";
import ChangePassword from "./Pages/ChangePassword/index";
import PrintFormBBH  from"./Pages/PrintForm/discuss";

const store = configureStore();
const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store} >
    <Router history={createBrowserHistory} >
      <Switch>
          <Route  path="/login" component={Login} />
          <Route  path="/change-password" component={ChangePassword} />
          <Route path="/print-formBBH/:userId/:appId/:logId/:formId/:productId" component={PrintFormBBH}></Route>
          <Route  path="/" component={ Main} />
         
      </Switch>
    </Router>
  </Provider>,
  rootElement
);

serviceWorker.unregister();
